import React from 'react'
import { Skeleton } from 'antd'
import styled from 'styled-components'

const DashboardLoader = () => {
  return (
    <>
      <CenterLoader>
        <div>
        <Skeleton
          paragraph={{
            rows: 0,
          }}
          style={{ width: '6000px', height: '100px', padding: '10px' }}
          active={true}
          size="default"
        />
        <Skeleton
          paragraph={{
            rows: 0,
          }}
          style={{ width: '6000px' }}
          active={true}
          size="default"
        />
        <Skeleton
          paragraph={{
            rows: 0,
          }}
          style={{ width: '6000px' }}
          active={true}
          size="default"
        />
        </div>
        
      </CenterLoader>
      <LastLoader>
        <div>
            
      <Skeleton
          paragraph={{
            rows: 0,
          }}
          style={{ width: '1000px' }}
          active={true}
          size="default"
        />
        </div>
              

      </LastLoader>
    </>
  )
}
export default DashboardLoader

export const CenterLoader = styled.div`
  margin: 0 auto !important;
  display: flex;
  flex-direction: column;
//   margin: 10px 0 34px;
  width: 100%;
  & > div:nth-child(1) {
    display: flex;
    align-items: center;
    width: 100%;
    .ant-skeleton-title {
        width: 220px !important;
        height: 100px !important;
    }
  }
`
export const LastLoader = styled.div`
    .ant-skeleton-title {
        margin-left: 10px;
        margin-top: -17px;
        width: 980px !important;
        min-width: 800px !important;
        height: 500px !important;
    }
`
