import React, { useState, useEffect } from 'react'
import {
  AddTeamForm,
  AddTeamStyle,
  AddTeamTop,
} from '../../../styles/adminStyles/AdminAddTeam.Styled'
import { useDispatch, useSelector } from 'react-redux'
import { createAdmin } from '../../../api/admin'
import Loading from '../../../assets/icons/loading.gif'
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import ArrowIcon from '../../../assets/icons/arrow-icon.svg'
import { AddAdminStyle } from '../../../styles/adminStyles/AdminAdd.Styled'
import { Link } from 'react-router-dom'
import {
  CustomButton,
  InputField,
  SelectField,
} from '../../../styles/styledComponent'

const AddAdmin = () => {
  const { error, loading } = useSelector((state) => state.admin)

  const dispatch = useDispatch()

  const navigate = useNavigate()

  useEffect(() => {
    if (error !== null && error !== 'null') {
      toast.error(error, {
        position: toast.POSITION.TOP_CENTER,
      })
    }
  }, [error])

  useEffect(() => {
    if (error === 'null') {
      navigate('/admin')
    }
  }, [error, navigate])

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [role, setRole] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()
    dispatch(createAdmin({firstName, lastName, email, password, role}))

  }

  const handleFirstName = (e) => {
    setFirstName(e.target.value)
  }
  const handleLastName = (e) => {
    setLastName(e.target.value)
  }
  const handleEmail = (e) => {
    setEmail(e.target.value)
  }
  const handlePassword = (e) => {
    setPassword(e.target.value)
  }
  const handleRole = (e) => {
    setRole(e.target.value)
  }
  return (
    <AddAdminStyle>
      <AddTeamStyle>
        <Link to="/admin">
          <AddTeamTop>
            <img src={ArrowIcon} alt="Arrow Icon" /> <h5>Add New Admin</h5>
          </AddTeamTop>
        </Link>

        <AddTeamForm>
          <form onSubmit={handleSubmit}>
            <div className="inputs">
              <div>
                <label>First Name </label>
                <InputField
                  type="text"
                  value={firstName}
                  onChange={handleFirstName}
                  placeholder="Oyinkansola"
                />
              </div>

              <div>
                <label>Last Name </label>
                <InputField
                  type="text"
                  value={lastName}
                  onChange={handleLastName}
                  placeholder="Adebayo"
                />
              </div>

              <div>
                <label>Email</label>
                <InputField
                  type="text"
                  value={email}
                  onChange={handleEmail}
                  placeholder="tosin@niyo.co"
                />
              </div>

              <div>
                <label>Password</label>
                <InputField
                  type="text"
                  value={password}
                  onChange={handlePassword}
                  placeholder="MyPassword123"
                />
              </div>
              <div>
                <label>Role</label>
                <SelectField name="" value={role} onChange={handleRole}>
                  <option value="">---Select Role---</option>
                  <option value="super-admin">Super Admin</option>
                  <option value="admin">Admin</option>
                  <option value="manager">Manager</option>
                </SelectField>
              </div>

              <div className="flex">
                <div className="subtitle2">Notifications</div>
                <div className="selections">
                  <div className="check">
                    <div>
                      <input type="checkbox" name="" checked={true} />
                      <p>Send the new user an email about their account</p>
                    </div>
                  </div>
                  <div className="check1">
                    <div>
                      <input type="checkbox" name="" checked={true} />
                      <p>Send Super Admin activity notification</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <CustomButton
              padding="12px 16px"
              className={`${
                firstName === '' ||
                lastName === '' ||
                email === '' ||
                password === '' ||
                role === '' 
                // selectedFile === null
                  ? 'disabled'
                  : ''
              }`}
            >
              {loading ? (
                <img src={Loading} alt="loader" width="30%" height="30%" />
              ) : (
                'Add New User'
              )}
            </CustomButton>
          </form>
        </AddTeamForm>
        <ToastContainer />
      </AddTeamStyle>
    </AddAdminStyle>
  )
}

export default AddAdmin
