import styled from 'styled-components'

export const CreateEventStyle = styled.div`
  form {
    input{
      width: 283px !important;
    }
    padding-bottom: 80px;
    .inputs {
      gap: 54px;
    }
    .flex {
      display: flex;
      margin-bottom: 20px;
      input {
      // border: 3px soplid red !important;
    }
    > div:nth-child(2) {
      position: relative;
    }
    }
    .colorB {
      color: black!important ;
    }
  }
`

export const DropDownHeader = styled.div`

  background: #f4f5f5 !important;
  border: 1px solid #d5d7d7 !important;
  border-radius: 5px !important;
  color: #b5baba !important ;
  margin-top: 10px;
  width: 190px;
  display: flex;
  align-items: center;
  padding: 13px 0px;
  padding-left: 20px;
  cursor: pointer;
`
export const DropDownListContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(25, 26, 26, 0.17);
  border-radius: 5px;
  height: 223px;
  // z-index: 200;
  z-index: 0;
  position: absolute;
  top: 80px;
  width: 190px;
  
`
export const DropDownList = styled.div`
  height: 223px;
  overflow-y: auto;
  text-align: center;
`
export const ListItem = styled.div`
font-weight: 400;
font-size: 12px;
line-height: 16px;
color: #454A4A;
padding: 12.5px 0px;
cursor: pointer;
&:hover {
  background: #EAEBEB;
}
`
