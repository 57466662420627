import React, { useState } from 'react'
import styled from 'styled-components'
import UpcomingEvents from './UpcomingEvents'
import PastEvents from './PastEvents'

const EventNav = () => {
  const [state, setState] = useState('upcomingEvents')
  const setTab = ({ target: { id } }) => setState(id)

  return (
    <Wrapper>
      <div className="container">
        <div className="row header">
          <div className="grading-process">
            <a
              href
              id="upcomingEvents"
              onClick={setTab}
              className={`${state === 'upcomingEvents' ? 'active' : 'default'}`}
            >
              Upcoming
            </a>
            <span className={`${state === 'upcomingEvents' ? 'active' : ''}`} />
          </div>
          <div className="grading-process">
            <a
              href
              id="PastEvents"
              onClick={setTab}
              className={`${state === 'PastEvents' ? 'active' : 'default'}`}
            >
              Past
            </a>
            <span className={`${state === 'PastEvents' ? 'active' : ''}`} />
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12 p-0">
              {state === 'upcomingEvents' ? <UpcomingEvents /> : <PastEvents />}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .header {
    margin: 22px 0 42px;
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #070909;
    font-weight: 400;
    > div:nth-child(1) {
      margin-right: 16px;
    }
  }

  .grading-process a {
    font-size: 18px;
    color: #070909;
    font-weight: 700;
    padding: 14px 23px;
    background: #eaebeb;
    outline: none;
    border: none;
    text-decoration: none;
    background: none;
    border: none;
    cursor: pointer;
    &.active {
      font-size: 18px;
      color: #070909;
      font-weight: 700;
      padding: 14px 23px;
      background: #eaebeb;
      outline: none;
      border: none;
    }
    &.default {
      font-size: 18px;
      color: #070909;
      font-weight: 700;
    }
  }
`

export default EventNav
