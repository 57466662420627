import React, { useState, useEffect } from 'react'
import { DashboardTop } from '../../../styles/adminStyles/AdminDashBoard.Styled'
import { EventStyle } from '../../../styles/adminStyles/AdminEvent.Styled'
import {
  TeamStyle,
} from '../../../styles/adminStyles/AdminTeam.Styled'

import { search } from '../../../constants'
import { Link } from 'react-router-dom'
import EventNav from '../component/EventNav'
import { CustomButton, InputField } from '../../../styles/styledComponent'
import { sortBySearch } from '../../../globalState/reducers/eventSlice'
import { useDispatch } from 'react-redux'


const Event = () => {
  const dispatch = useDispatch()
  const [searchEvent, setSearchEvent] = useState('')

  const handleSearchEvent = (e) => {
    setSearchEvent(e.target.value)
    dispatch(sortBySearch(e.target.value))
  }

  
  useEffect(() => {
    dispatch(sortBySearch(''))
  }, [dispatch])

  return (
    <EventStyle>
      <TeamStyle>
        <DashboardTop>
          <h5>Events</h5>
          <div className="team">
          <div>
            <div>
              <img src={search} alt="search-icon" />
            </div>
            <InputField
              type="text"
              id="first-name"
              width="70%"
              value={searchEvent}
              style={{ color: 'black' }}
              background="white"
              onChange={handleSearchEvent}
              placeholder="Search for Events"
            />
          </div>
            <Link to="/admin/create-event">
              <div className="btn">
                <CustomButton width="142px" padding="12px 16px">Create New Events</CustomButton>
              </div>
            </Link>
          </div>
        </DashboardTop>
        <EventNav />
        
      </TeamStyle>
    </EventStyle>
  )
}

export default Event
