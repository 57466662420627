import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Loading from "../../../assets/icons/loading.gif";
import {
  AddTeamForm,
  AddTeamStyle,
  AddTeamTop,
} from "../../../styles/adminStyles/AdminAddTeam.Styled";
import Dropzone from "react-dropzone";
import "react-quill/dist/quill.snow.css";

import { getBlog, updateBlog } from "../../../api/blog";

import { Link, useNavigate } from "react-router-dom";
import ArrowIcon from "../../../assets/icons/arrow-icon.svg";
import AttachIcon from "../../../assets/icons/attach-icon.svg";
import DropFileIcon from "../../../assets/icons/dropFile-icon.svg";
import { AddAdminStyle } from "../../../styles/adminStyles/AdminAdd.Styled";
import { CustomButton, InputField } from "../../../styles/styledComponent";
import JobLoader from "../loaders/jobLoader";

const UpdateBlog = () => {
  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
    ],
  };
  const navigate = useNavigate();
  const elementRef = useRef();
  const { error, loading, data } = useSelector((state) => state.blog);

  const dispatch = useDispatch();

  let { id } = useParams();

  const [details, setDetails] = useState(data && data.description);
  const [detailsChange, setDetailsChange] = useState(false);
  const [title, setTitle] = useState(data?.title);
  const [titleChange, setTitleChange] = useState(false);
  const [category, setCategory] = useState(data?.category);
  const [categoryChange, setCategoryChange] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [dropFile, setDropFile] = useState(null);
  const [dropFile2, setDropFile2] = useState(null);

  useEffect(() => {
    function fetchData() {
      dispatch(getBlog(id));
    }
    fetchData();
  }, [dispatch, id]);

  useEffect(() => {
    if (error === "updateBlog") {
      navigate("/admin/blog");
    }
  }, [error, navigate]);

  useEffect(() => {
    if (
      error !== null &&
      error !== "null" &&
      error !== "" &&
      error !== "updateBlog" &&
      error !== "getBlog" &&
      error !== "deleteBlog" &&
      error !== "BadNetwork" &&
      error !== "publishBlog"
    ) {
      if (typeof error === "string") {
        toast.error(error, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        error?.map((err) => {
          toast.error(err.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
      }
    }
  }, [error]);

  const handleDataState = (file) => {
    setSelectedFile(file);
  };
  const handleFileUpload = (event) => {
    handleDataState(event.target.files[0]);
  };

  const handleFileDrop = (file) => {
    handleDataState(file);
    setDropFile(file);
  };
  const handleFileUpload2 = (event) => {
    setSelectedFile2(event.target.files[0]);
  };

  const handleFileDrop2 = (file) => {
    setSelectedFile2(file);
    setDropFile2(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const coverPhoto = selectedFile || dropFile;
    const coverPhoto2 = selectedFile2 || dropFile2;
    let form_data = new FormData();

    if (coverPhoto) {
      form_data.append("photo", coverPhoto, coverPhoto?.path);
      if (title && data?.title !== title) {
        form_data.append("title", title);
        dispatch(updateBlog({ id, form_data }));
      }
      if (details && data?.description !== details) {
        form_data.append("description", details);
        dispatch(updateBlog({ id, form_data }));
      }
      if (category && data?.category !== category) {
        form_data.append("category", category);
        dispatch(updateBlog({ id, form_data }));
      }
      if (coverPhoto) {
        dispatch(updateBlog({ id, form_data }));
      }
    } else if (coverPhoto2) {
      form_data.append("previewPhoto", coverPhoto2, coverPhoto2?.path);
      if (title && data?.title !== title) {
        form_data.append("title", title);
        dispatch(updateBlog({ id, form_data }));
      }
      if (details && data?.description !== details) {
        form_data.append("description", details);
        dispatch(updateBlog({ id, form_data }));
      }
      if (category && data?.category !== category) {
        form_data.append("category", category);
        dispatch(updateBlog({ id, form_data }));
      }
      if (coverPhoto2) {
        dispatch(updateBlog({ id, form_data }));
      }
    } else {
      if (title && data?.title !== title) {
        dispatch(updateBlog({ id, title }));
      }
      if (details && data?.description !== details) {
        const description = details;
        dispatch(updateBlog({ id, description }));
      }
      if (category && data?.category !== category) {
        dispatch(updateBlog({ id, category }));
      }
    }
  };

  const handleTitle = (e) => {
    setTitleChange(true);
    setTitle(e.target.value);
  };
  const handleCategory = (e) => {
    setCategoryChange(true);
    setCategory(e.target.value);
  };

  if (loading) return <JobLoader />;
  return (
    <>
      {data && (
        <AddAdminStyle>
          <AddTeamStyle>
            <Link to="/admin/blog">
              <AddTeamTop>
                <img src={ArrowIcon} alt="Arrow Icon" /> <h5>Update a blog</h5>
              </AddTeamTop>
            </Link>

            <AddTeamForm>
              <form onSubmit={handleSubmit}>
                <div className="blogInput">
                  <div className="uploads">
                    <label>Update preview blog image</label>
                    <Dropzone onDrop={(files) => handleFileDrop2(files[0])}>
                      {({ getRootProps, getInputProps }) => (
                        <div className="btn" {...getRootProps()}>
                          <input
                            {...getInputProps()}
                            onChange={handleFileUpload2}
                          />
                          <div>
                            {selectedFile2 !== null ? (
                              <p>Image Uploaded</p>
                            ) : (
                              <>
                                <img src={AttachIcon} alt="attach icon" />
                                <i>Attach file</i>
                              </>
                            )}
                          </div>
                          <p>or</p>
                          <div>
                            {dropFile2 !== null ? (
                              <p>Image Uploaded</p>
                            ) : (
                              <>
                                <img src={DropFileIcon} alt="Drop File Icon" />
                                <i>Drop file here</i>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </div>
                  <div className="uploads">
                    <label>Update blog image</label>
                    <Dropzone onDrop={(files) => handleFileDrop(files[0])}>
                      {({ getRootProps, getInputProps }) => (
                        <div className="btn" {...getRootProps()}>
                          <input
                            {...getInputProps()}
                            onChange={handleFileUpload}
                          />
                          <div>
                            {selectedFile !== null ? (
                              <p>Image Uploaded</p>
                            ) : (
                              <>
                                <img src={AttachIcon} alt="attach icon" />
                                <i>Attach file</i>
                              </>
                            )}
                          </div>
                          <p>or</p>
                          <div>
                            {dropFile !== null ? (
                              <p>Image Uploaded</p>
                            ) : (
                              <>
                                <img src={DropFileIcon} alt="Drop File Icon" />
                                <i>Drop file here</i>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </div>
                  <div>
                    <label>Blog Title </label>
                    <InputField
                      type="text"
                      ref={elementRef}
                      value={titleChange ? title : data?.title}
                      onChange={handleTitle}
                      placeholder="Growth Marketer"
                    />
                  </div>
                  <div>
                    <label>Blog Category </label>
                    <InputField
                      type="text"
                      ref={elementRef}
                      value={categoryChange ? category : data?.category}
                      onChange={handleCategory}
                      placeholder="Growth Marketer"
                    />
                  </div>

                  <label>Blog description</label>
                  <ReactQuill
                    theme="snow"
                    value={detailsChange ? details : data.description}
                    placeholder={data?.description}
                    onChange={(e) => {
                      setDetailsChange(true);
                      setDetails(e);
                    }}
                    className="editor-input"
                    modules={modules}
                  />
                </div>
                <CustomButton
                  padding="12px 16px"
                  margin="0px"
                  marginL="auto"
                  display="block"
                  //   className={`${
                  //     title === "" || category === "" || details === null
                  //       ? "disabled"
                  //       : ""
                  //   }`}
                >
                  {loading === "loading" ? (
                    <img src={Loading} alt="loader" width="30%" height="30%" />
                  ) : (
                    "Update Changes"
                  )}
                </CustomButton>
              </form>
            </AddTeamForm>
            <ToastContainer />
          </AddTeamStyle>
        </AddAdminStyle>
      )}
    </>
  );
};

export default UpdateBlog;
