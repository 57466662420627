import React, { useState, useEffect } from "react";
import {
  AddTeamForm,
  AddTeamStyle,
  AddTeamTop,
} from "../../../styles/adminStyles/AdminAddTeam.Styled";
import { useDispatch, useSelector } from "react-redux";
import Dropzone from "react-dropzone";
import { useParams } from "react-router-dom";
import AttachIcon from "../../../assets/icons/attach-icon.svg";
import DropFileIcon from "../../../assets/icons/dropFile-icon.svg";
import { getAdmin, updateAdmin } from "../../../api/admin";
import Loading from "../../../assets/icons/loading.gif";
import NiyoNotificationModal from "../../../components/modal";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ArrowIcon from "../../../assets/icons/arrow-icon.svg";
import { AddAdminStyle } from "../../../styles/adminStyles/AdminAdd.Styled";
import { Link } from "react-router-dom";
import {
  CustomButton,
  InputField,
  SelectField,
} from "../../../styles/styledComponent";
import { EmailContent, EmailTitle } from "../../../constants/updateAdminEmail";
import JobLoader from "../loaders/jobLoader";
import {
  PasswordContent,
  PasswordTitle,
} from "../../../constants/updateAdminPassword";

const UpdateAdmin = () => {
  const { error, data, loading, emailError, passwordError } = useSelector(
    (state) => state.admin
  );
  const dispatch = useDispatch();

  const navigate = useNavigate();

  let { id } = useParams();

  useEffect(() => {
    async function fetchData() {
      await dispatch(getAdmin(id));
    }
    fetchData();
  }, [dispatch, id]);

  useEffect(() => {
    if (
      error !== null &&
      error !== "updateAdmin" &&
      error !== "getAdmin" &&
      error !== "deleteAdmin" &&
      error !== "BadNetwork" &&
      emailError !== "failedAdminEmail" &&
      emailError !== "updateAdminEmail" &&
      passwordError !== "updateAdminPassword" &&
      passwordError !== "failedAdminPassword"
    ) {
      toast.error(error, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }, [emailError, passwordError, error]);

  useEffect(() => {
    if (emailError === "failedAdminEmail") {
      setTimeout(() => {
        toast.error(error, {
          position: toast.POSITION.TOP_CENTER,
        });
      }, 1000);
    }
  }, [emailError, error]);

  useEffect(() => {
    if (emailError === "updateAdminEmail") {
      setTimeout(() => {
        toast.success(error, {
          position: toast.POSITION.TOP_CENTER,
        });
      }, 1000);
    }
  }, [emailError, error]);

  useEffect(() => {
    if (
      passwordError === "updateAdminPassword" &&
      emailError !== "failedAdminEmail" &&
      emailError !== "updateAdminEmail"
    ) {
      setTimeout(() => {
        toast.success(error, {
          position: toast.POSITION.TOP_CENTER,
        });
      }, 1000);
    }
  }, [passwordError, emailError, error]);

  useEffect(() => {
    if (error === "updateAdmin") {
      navigate("/admin");
    }
  }, [error, navigate]);

  const [selectedFile, setSelectedFile] = useState(null);
  const [dropFile, setDropFile] = useState(null);

  const [firstName, setFirstName] = useState(data?.firstName);
  const [firstNameChange, setFirstNameChange] = useState(false);

  const [lastName, setLastName] = useState(data?.lastName);
  const [lastNameChange, setLastNameChange] = useState(false);

  const [role, setRole] = useState(data?.role);
  const [roleChange, setRoleChange] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const photo = selectedFile || dropFile;
    let form_data = new FormData();

    if (photo) {
      form_data.append("photo", photo, photo?.path);
      if (firstName && data?.firstName !== firstName) {
        form_data.append("firstName", firstName);
        dispatch(updateAdmin({ id, form_data }));
      }
      if (lastName && data?.lastName !== lastName) {
        form_data.append("lastName", lastName);
        dispatch(updateAdmin({ id, form_data }));
      }
      if (role && data?.role !== role) {
        form_data.append("role", role);
        dispatch(updateAdmin({ id, form_data }));
      }
      if (photo) {
        dispatch(updateAdmin({ id, form_data }));
      }
    } else {
      if (firstName && data?.firstName !== firstName) {
        form_data.append("firstName", firstName);
        dispatch(updateAdmin({ id, firstName }));
      }
      if (lastName && data?.lastName !== lastName) {
        form_data.append("lastName", lastName);
        dispatch(updateAdmin({ id, lastName }));
      }
      if (role && data?.role !== role) {
        form_data.append("role", role);
        dispatch(updateAdmin({ id, role }));
      }
    }
  };
  const handleFirstName = (e) => {
    setFirstNameChange(true);
    setFirstName(e.target.value);
  };
  const handleLastName = (e) => {
    setLastNameChange(true);
    setLastName(e.target.value);
  };

  const handleDataState = (file) => {
    setSelectedFile(file);
  };
  const handleFileUpload = (event) => {
    handleDataState(event.target.files[0]);
  };

  const handleFileDrop = (file) => {
    handleDataState(file);
    setDropFile(file);
  };

  const handleRole = (e) => {
    setRoleChange(true);
    setRole(e.target.value);
  };
  if (loading) return <JobLoader />;
  return (
    <AddAdminStyle>
      <AddTeamStyle>
        <Link to="/admin">
          <AddTeamTop>
            <img src={ArrowIcon} alt="Arrow Icon" /> <h5>Update Admin</h5>
          </AddTeamTop>
        </Link>

        <AddTeamForm>
          <form onSubmit={handleSubmit}>
            <div className="inputs">
              <div>
                <label>First Name </label>
                <InputField
                  type="text"
                  value={firstNameChange ? firstName : data?.firstName}
                  onChange={handleFirstName}
                  placeholder="Oyinkansola"
                />
              </div>

              <div>
                <label>Last Name </label>
                <InputField
                  type="text"
                  value={lastNameChange ? lastName : data?.lastName}
                  onChange={handleLastName}
                  placeholder="Adebayo"
                />
              </div>

              <div>
                <label>Email</label>
                <NiyoNotificationModal
                  Content={EmailContent}
                  Title={EmailTitle}
                  email={data?.email}
                  id={id}
                >
                  <InputField
                    type="text"
                    style={{ pointer: "cursor" }}
                    value="Change Email"
                    // onChange={({ target }) => {
                    //   handleEmail(target.value)
                    // }}
                  />
                </NiyoNotificationModal>
              </div>

              <div>
                <label>Password</label>
                <NiyoNotificationModal
                  Content={PasswordContent}
                  Title={PasswordTitle}
                  id={id}
                >
                  <InputField
                    type="text"
                    value="Change password"
                    placeholder="MyPassword123"
                  />
                </NiyoNotificationModal>
              </div>
              <div>
                <label>Role</label>
                <SelectField
                  name=""
                  value={roleChange ? role : data?.role}
                  onChange={handleRole}
                >
                  <option value="" disabled>
                    Select Role
                  </option>
                  <option value="super-admin">Super Admin</option>
                  <option value="admin">Admin</option>
                  <option value="manager">Manager</option>
                </SelectField>
              </div>

              <div>
                <label>Update Profile image</label>
                <Dropzone onDrop={(files) => handleFileDrop(files[0])}>
                  {({ getRootProps, getInputProps }) => (
                    <div className="btn" {...getRootProps()}>
                      <input {...getInputProps()} onChange={handleFileUpload} />
                      <div>
                        {selectedFile !== null ? (
                          <p>Image Uploaded</p>
                        ) : (
                          <>
                            <img src={AttachIcon} alt="attach icon" />
                            <i>Attach file</i>
                          </>
                        )}
                      </div>
                      <p>or</p>
                      <div>
                        {dropFile !== null ? (
                          <p>Image Uploaded</p>
                        ) : (
                          <>
                            <img src={DropFileIcon} alt="Drop File Icon" />
                            <i>Drop file here</i>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </Dropzone>
              </div>

              <div className="flex">
                <div className="subtitle2">Notifications</div>
                <div className="selections">
                  <div className="check">
                    <div>
                      <input type="checkbox" name="" checked={true} />
                      <p>Send the new user an email about their account</p>
                    </div>
                  </div>
                  <div className="check1">
                    <div>
                      <input type="checkbox" name="" checked={true} />
                      <p>Send Super Admin activity notification</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <CustomButton padding="12px 16px">
              {loading ? (
                <img src={Loading} alt="loader" width="30%" height="30%" />
              ) : (
                "Update User"
              )}
            </CustomButton>
          </form>
        </AddTeamForm>
        <ToastContainer />
      </AddTeamStyle>
    </AddAdminStyle>
  );
};

export default UpdateAdmin;
