import client from '../utils/client'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL } from './ config'
     

export const admin = createAsyncThunk(
  'admin/fetchadmin',
  async (obj,{ rejectWithValue }) => {
    try {
     
      const { data: {data} } = await client.get(
        `${BASE_URL}/api/v1/admin`
      )
      return data
    } catch (error) {
        throw rejectWithValue(error.message)
    }
  }
)


export const createAdmin = createAsyncThunk(
  'admin/createAdmin',
  async ( {firstName, lastName, email, password, role}  , { rejectWithValue }) => {
    try {
      const { data:{data} } = await client.post(
        `${BASE_URL}/api/v1/admin`,
        {firstName, lastName, email, password, role}
    )
    return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getAdmin = createAsyncThunk(
  'admin/getAdmin',
  async (id,{ rejectWithValue }) => {
    try {
     
      const { data: {data} } = await client.get(
        `${BASE_URL}/api/v1/admin/${id}`
      )
      return data
    } catch (error) {
      // return custom error message from API if any
      throw rejectWithValue(error.message)
    }
  }
)


export const updateAdmin = createAsyncThunk(
  'admin/updateAdmin',
  async ( { id, firstName, lastName, role, form_data } , { rejectWithValue }) => {
    try {
      if (firstName) {
        const { data:{ data } } = await client.put(
          `${BASE_URL}/api/v1/admin/${id}/update-admin`,
          { firstName }
      )
      return data
      }
      if (lastName) {
        const { data:{ data } } = await client.put(
          `${BASE_URL}/api/v1/admin/${id}/update-admin`,
          { lastName }
      )
      return data
      }
      if (role) {
        const { data:{ data } } = await client.put(
          `${BASE_URL}/api/v1/admin/${id}/update-admin`,
          { role }
      )
      return data
      }
      if (form_data) {
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
        const { data:{ data } } = await client.put(
          `${BASE_URL}/api/v1/admin/${id}/update-admin`,
          form_data,
           config
      )
      return data
      }
        
    }catch (error) {
      // return custom error message from API if any
      if (error && error.data.message) {
        return rejectWithValue(error.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const updateAdminEmail = createAsyncThunk(
  'admin/updateAdminEmail',
  async ( { id, email } , { rejectWithValue }) => {
    try {
        const { data:{ data } } = await client.put(
          `${BASE_URL}/api/v1/admin/${id}/email`,
          { email }
      )
      return data
        
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.data[0].message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const updateAdminPassword = createAsyncThunk(
  'admin/updateAdminPassword',
  async ( { id, password } , { rejectWithValue }) => {
    try {
        const { data:{ data } } = await client.put(
          `${BASE_URL}/api/v1/admin/${id}/password`,
          { password}
      )
      return data
        
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.data[0].message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const deleteAdmin = createAsyncThunk(
  'admin/deleteAdmin',
  async (id,{ rejectWithValue }) => {
    try {
     
      const { data: {data} } = await client.delete(
        `${BASE_URL}/api/v1/admin/${id}`
      )
      return data
    } catch (error) {
      // return custom error message from API if any
      throw rejectWithValue(error.message)
    }
  }
)
