import { configureStore } from '@reduxjs/toolkit'
import authReducer from './reducers/authSlice'
import dashboardReducer from './reducers/dashboardSlice'
import teamReducer from './reducers/teamSlice'
import careerReducer from './reducers/careerSlice'
import eventsReducer from './reducers/eventSlice'
import jobReducer from './reducers/jobSlice'
import BlogReducer from './reducers/blogSlice'
import adminReducer from './reducers/adminSlice'
import notificationReducer from './reducers/notificationSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    dashboard: dashboardReducer,
    team: teamReducer,
    events: eventsReducer,
    job: jobReducer,
    blog: BlogReducer,
    admin: adminReducer,
    career: careerReducer,
    notification: notificationReducer,
  }
})