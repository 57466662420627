import styled from 'styled-components'

export const AddTeamStyle = styled.div`
  padding: 34px 44px;
  h5 {
    margin: 0;
  }
  a {
    text-decoration: none;
    color: #070909;
  }
`

export const AddTeamTop = styled.div`
  display: flex;
  gap: 22px;
  align-items: center;
`

export const AddTeamForm = styled.div`
.update {
  color: black !important;
}
#inputID::placeholder {
  color: black !important;
}

  .disabled{
    pointer-events: none;
  }
  form {
    background-color: white;
    border-radius: 10px;
    margin: 35px 0 0 0;
    width: 90%;
    padding: 29px 35px;
    .editor-input {
      margin-top:-10px;
      width: 100%;
      height: 100%;
    }
    
    button {
      font-size: 12px;
      line-height: 16px;
    }
    .ck ck-sticky-panel__content ck-toolbar_grouping ck ck-toolbar ck-toolbar__items{
      background: #d5d7d7 !important;
      padding: 10px, 8px, 10px, 8px !important;
    }
    .inputsField {
      display: flex;
      flex-direction: column;
      gap: 21px;
      width: 100%;
      margin-bottom: 46px;
      select {
        outline: none;
        width: 100%;
        margin: 10px 0;
        background: #f4f5f5;
        font-size: 16px;
          line-height: 25px;
          cursor: pointer;
        border: 1px solid #d5d7d7;
        border-radius: 5px;
        padding: 13px 16px;
       
        border-radius: 5px;
        &::placeholder {
          color: #b5baba;
          font-size: 16px;
        }
      }
      select {
        background-color: transparent;
        // border: 3px solid red;
      }
    }
    .blogInput {
      display: flex;
      flex-direction: column;
      gap: 21px;
      width: 100%;
      margin-bottom: 46px;
      div {
        label {
          color: #070909;
          display: block;
          font-weight: 400;
        }
        input,
        textarea,
        select {
          outline: none;
          width: 100%;
          margin: 10px 0;
          background: #f4f5f5;
          font-size: 16px;
            line-height: 25px;
          border: 1px solid #d5d7d7;
          border-radius: 5px;
          padding: 13px 16px;
         color: black;
          border-radius: 5px;
          &::placeholder {
            color: #b5baba;
            font-size: 16px;
          }
        }
        select {
          background-color: transparent;
          // border: 3px solid red;
        }
        button {
          cursor: pointer;
          outline: none;
          background: #f4f5f5;
          border: none;
          margin-top: 10px;
        }
        
        .btn {
          display: flex;
          align-items: center;
          gap: 15px;
          p {
            font-size: 16px;
          }
          div {
            text-align: center;
            display: flex;
            align-items: center;
            gap: 10px;
            background: #F4F5F5;
            font-size: 16px;
            cursor: pointer;
            padding: 10px 14px;
            color: #636969;
            img {
              width: 30px;
            }
            i {
              font-style: normal;
            }
          }
        }
      }
    }
    .inputs {
      display: flex;
      flex-direction: column;
      gap: 21px;
      width: 60%;
      margin-bottom: 46px;
      div {
        label {
          color: #070909;
          display: block;
          font-weight: 400;
        }
        input,
        textarea,
        select {
          outline: none;
          width: 100%;
          margin: 10px 0;
          background: #f4f5f5;
          font-size: 16px;
            line-height: 25px;
          border: 1px solid #d5d7d7;
          border-radius: 5px;
          padding: 13px 16px;
         color: black;
          border-radius: 5px;
          &::placeholder {
            color: #b5baba;
            font-size: 16px;
          }
        }
        select {
          background-color: transparent;
          // border: 3px solid red;
        }
        button {
          cursor: pointer;
          outline: none;
          background: #f4f5f5;
          border: none;
          margin-top: 10px;
        }
        
        .btn {
          display: flex;
          align-items: center;
          gap: 15px;
          p {
            font-size: 16px;
          }
          div {
            text-align: center;
            display: flex;
            align-items: center;
            gap: 10px;
            background: #F4F5F5;
            font-size: 16px;
            cursor: pointer;
            padding: 10px 14px;
            color: #636969;
            img {
              width: 30px;
            }
            i {
              font-style: normal;
            }
          }
        }
      }
    }
  }
`
