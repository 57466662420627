import React, { useState } from 'react'
import styled from 'styled-components'
import { Popover } from 'antd'

const buttonWidth = 70
const NiyoNotificationModal = ({
  Title,
  url,
  Content,
  id,
  nav,
  email,
  disable,
  notifications,
  children,
}) => {
  const [visible, setVisible] = useState(false)
  const handleVisibleChange = (visible) => {
    setVisible(visible)
  }

  const hide = () => {
    setVisible(false)
  }
  return (
    <div
      style={{
        marginLeft: buttonWidth,
        clear: 'both',
        whiteSpace: 'nowrap',
        pointerEvents: disable,
      }}
    >
      <Popover
        placement="bottom"
        title={Title}
        content={
          <Content hide={hide} id={id} nav={nav} oldEmail={email} url={url} notifications={notifications} />
        }
        trigger="click"
        onVisibleChange={handleVisibleChange}
        visible={visible}
      >
        {children}
      </Popover>
    </div>
  )
}
export default NiyoNotificationModal

export const Wrapper = styled.div``
