import notification from '../assets/icons/notification-icon.svg'
import profile from '../assets/icons/dashboardProfile-icon.svg'
import edit from '../assets/icons/edit-icon.svg'
import lock from '../assets/icons/lock.svg'
import search from '../assets/icons/search-icon.svg'
import deleteIcon from '../assets/icons/delete-icon.svg'
import arrowIcon from '../assets/icons/arrow-right.svg'

const teamList = [
  {
    id: 1,
    img: profile,
    name: 'Oyin Adebayo',
    role: 'CEO & Founder',
    funfact: 'I have met the founder of etherum.......',
    social: 'linkedln.com/in/oyinkansola ..........',
  },
  {
    id: 2,
    img: profile,
    name: 'Oyin Adebayo',
    role: 'CEO & Founder',
    funfact: 'I have met the founder of etherum.......',
    social: 'linkedln.com/in/oyinkansola ..........',
  },
  {
    id: 3,
    img: profile,
    name: 'Oyin Adebayo',
    role: 'CEO & Founder',
    funfact: 'I have met the founder of etherum.......',
    social: 'linkedln.com/in/oyinkansola ..........',
  },
  {
    id: 4,
    img: profile,
    name: 'Oyin Adebayo',
    role: 'CEO & Founder',
    funfact: 'I have met the founder of etherum.......',
    social: 'linkedln.com/in/oyinkansola ..........',
  },
  {
    id: 5,
    img: profile,
    name: 'Oyin Adebayo',
    role: 'CEO & Founder',
    funfact: 'I have met the founder of etherum.......',
    social: 'linkedln.com/in/oyinkansola ..........',
  },
  {
    id: 6,
    img: profile,
    name: 'Oyin Adebayo',
    role: 'CEO & Founder',
    funfact: 'I have met the founder of etherum.......',
    social: 'linkedln.com/in/oyinkansola ..........',
  },
  {
    id: 7,
    img: profile,
    name: 'Oyin Adebayo',
    role: 'CEO & Founder',
    funfact: 'I have met the founder of etherum.......',
    social: 'linkedln.com/in/oyinkansola ..........',
  },
  {
    id: 8,
    img: profile,
    name: 'Oyin Adebayo',
    role: 'CEO & Founder',
    funfact: 'I have met the founder of etherum.......',
    social: 'linkedln.com/in/oyinkansola ..........',
  },
  {
    id: 9,
    img: profile,
    name: 'Oyin Adebayo',
    role: 'CEO & Founder',
    funfact: 'I have met the founder of etherum.......',
    social: 'linkedln.com/in/oyinkansola ..........',
  },
  {
    id: 10,
    img: profile,
    name: 'Oyin Adebayo',
    role: 'CEO & Founder',
    funfact: 'I have met the founder of etherum.......',
    social: 'linkedln.com/in/oyinkansola ..........',
  },
]

const teamListEvent = [
  {
    img: profile,
    name: 'Empowherment Evening: with Daniella & Tiwalola ',
    date: 'Friday, Aug 12, 2022',
    time: '6:00PM GMT',
  },
  {
    img: profile,
    name: 'Empowherment Evening: with Daniella & Tiwalola ',
    date: 'Friday, Aug 12, 2022',
    time: '6:00PM GMT',
  },
  {
    img: profile,
    name: 'Empowherment Evening: with Daniella & Tiwalola ',
    date: 'Friday, Aug 12, 2022',
    time: '6:00PM GMT',
  },
  {
    img: profile,
    name: 'Empowherment Evening: with Daniella & Tiwalola ',
    date: 'Friday, Aug 12, 2022',
    time: '6:00PM GMT',
  },
  {
    img: profile,
    name: 'Empowherment Evening: with Daniella & Tiwalola ',
    date: 'Friday, Aug 12, 2022',
    time: '6:00PM GMT',
  },
  {
    img: profile,
    name: 'Empowherment Evening: with Daniella & Tiwalola ',
    date: 'Friday, Aug 12, 2022',
    time: '6:00PM GMT',
  },
  {
    img: profile,
    name: 'Empowherment Evening: with Daniella & Tiwalola ',
    date: 'Friday, Aug 12, 2022',
    time: '6:00PM GMT',
  },
  {
    img: profile,
    name: 'Empowherment Evening: with Daniella & Tiwalola ',
    date: 'Friday, Aug 12, 2022',
    time: '6:00PM GMT',
  },
  {
    img: profile,
    name: 'Empowherment Evening: with Daniella & Tiwalola ',
    date: 'Friday, Aug 12, 2022',
    time: '6:00PM GMT',
  },
  {
    img: profile,
    name: 'Empowherment Evening: with Daniella',
    date: 'Friday, Aug 12, 2022',
    time: '6:00PM GMT',
  },
]

const teamListJobs = [
  {
    title: 'Growth Marketer',
    purpose:
      'Are you data-driven? Do you like running tests and finding the highest-impact yet lowest .......',
  },
  {
    title: 'Growth Marketer',
    purpose:
      'Are you data-driven? Do you like running tests and finding the highest-impact yet lowest .......',
  },
  {
    title: 'Growth Marketer',
    purpose:
      'Are you data-driven? Do you like running tests and finding the highest-impact yet lowest .......',
  },
  {
    title: 'Growth Marketer',
    purpose:
      'Are you data-driven? Do you like running tests and finding the highest-impact yet lowest .......',
  },
  {
    title: 'Growth Marketer',
    purpose:
      'Are you data-driven? Do you like running tests and finding the highest-impact yet lowest .......',
  },
  {
    title: 'Growth Marketer',
    purpose:
      'Are you data-driven? Do you like running tests and finding the highest-impact yet lowest .......',
  },
  {
    title: 'Growth Marketer',
    purpose:
      'Are you data-driven? Do you like running tests and finding the highest-impact yet lowest .......',
  },
  {
    title: 'Growth Marketer',
    purpose:
      'Are you data-driven? Do you like running tests and finding the highest-impact yet lowest .......',
  },
  {
    title: 'Growth Marketer',
    purpose:
      'Are you data-driven? Do you like running tests and finding the highest-impact yet lowest .......',
  },
]

const teamListAdmin = [
  {
    id: 1,
    name: 'Oyin Adebayo',
    email: 'Oyin@niyo.co',
    role: 'Super Admin',
  },
  {
    id: 2,
    name: 'Oyin Adebayo',
    email: 'Oyin@niyo.co',
    role: 'Super Admin',
  },
  {
    id: 3,
    name: 'Oyin Adebayo',
    email: 'Oyin@niyo.co',
    role: 'Super Admin',
  },
  {
    id: 4,
    name: 'Oyin Adebayo',
    email: 'Oyin@niyo.co',
    role: 'Super Admin',
  },
  {
    id: 5,
    name: 'Oyin Adebayo',
    email: 'Oyin@niyo.co',
    role: 'Super Admin',
  },
  {
    id: 6,
    name: 'Oyin Adebayo',
    email: 'Oyin@niyo.co',
    role: 'Super Admin',
  },
  {
    id: 7,
    name: 'Oyin Adebayo',
    email: 'Oyin@niyo.co',
    role: 'Super Admin',
  },
  {
    id: 8,
    name: 'Oyin Adebayo',
    email: 'Oyin@niyo.co',
    role: 'Super Admin',
  },
  {
    id: 9,
    name: 'Oyin Adebayo',
    email: 'Oyin@niyo.co',
    role: 'Super Admin',
  },
]

const jobLevelContent = [
  'Junior',
  'Mid-Level',
  'Senior',
  'Apprenticeship',
  'Graduate Programme',
]

const jobTypeContent = [
  'AR/VR',
  'Cyber',
  'Data Analysis',
  'Digital Marketing',
  'Product Management',
  'Project Management',
  'Software Engineer',
  'Software Testing',
  'UX and UI',
  'Website Development',
]

const jobLocationContent = ['Remote', 'Birmingham, UK', 'London']

const customTime = [
  '12:00 AM',
  '12:15 AM',
  '12:30 AM',
  '12:45 AM',
  '1:00 AM',
  '1:15 AM',
  '1:30 AM',
  '1:45 AM',
  '2:00 AM',
  '2:15 AM',
  '2:30 AM',
  '2:45 AM',
  '3:00 AM',
  '3:15 AM',
  '3:30 AM',
  '3:45 AM',
  '4:00 AM',
  '4:15 AM',
  '4:30 AM',
  '4:45 AM',
  '5:00 AM',
  '5:15 AM',
  '5:30 AM',
  '5:45 AM',
  '6:00 AM',
  '6:15 AM',
  '6:30 AM',
  '6:45 AM',
  '7:00 AM',
  '7:15 AM',
  '7:30 AM',
  '7:45 AM',
  '8:00 AM',
  '8:15 AM',
  '8:30 AM',
  '8:45 AM',
  '9:00 AM',
  '9:15 AM',
  '9:30 AM',
  '9:45 AM',
  '10:00 AM',
  '10:15 AM',
  '10:30 AM',
  '10:45 AM',
  '11:00 AM',
  '11:15 AM',
  '11:30 AM',
  '11:45 AM',
  '12:00 PM',
  '12:15 PM',
  '12:30 PM',
  '12:45 PM',
  '1:00 PM',
  '1:15 PM',
  '1:30 PM',
  '1:45 PM',
  '2:00 PM',
  '2:15 PM',
  '2:30 PM',
  '2:45 PM',
  '3:00 PM',
  '3:15 PM',
  '3:30 PM',
  '3:45 PM',
  '4:00 PM',
  '4:15 PM',
  '4:30 PM',
  '4:45 PM',
  '5:00 PM',
  '5:15 PM',
  '5:30 PM',
  '5:45 PM',
  '6:00 PM',
  '6:15 PM',
  '6:30 PM',
  '6:45 PM',
  '7:00 PM',
  '7:15 PM',
  '7:30 PM',
  '7:45 PM',
  '8:00 PM',
  '8:15 PM',
  '8:30 PM',
  '8:45 PM',
  '9:00 PM',
  '9:15 PM',
  '9:30 PM',
  '9:45 PM',
  '10:00 PM',
  '10:15 PM',
  '10:30 PM',
  '10:45 PM',
  '11:00 PM',
  '11:15 PM',
  '11:30 PM',
  '11:45 PM',
]
const NotificationData = [
  {
    img: profile,
    name: 'Oyin Adebayo',
    time: '5m ago',
    action: 'Niyo Number',
  },
  {
    img: profile,
    name: 'Ini Akpan',
    time: '1h ago',
    action: 'Job',
  },
  {
    img: profile,
    name: 'Mary Bolawole',
    time: '2 days ago',
    action: 'Event',
  },
  {
    img: profile,
    name: 'Ini Akpan',
    time: '1hr ago',
    action: 'Team',
  },
]
export {
  jobLevelContent,
  jobTypeContent,
  jobLocationContent,
  profile,
  search,
  notification,
  teamList,
  edit,
  lock,
  deleteIcon,
  arrowIcon,
  teamListEvent,
  teamListAdmin,
  teamListJobs,
  NotificationData,
  customTime,
}
