import { Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import { admin, deleteAdmin } from '../../../api/admin'
import NiyoNotificationModal from '../../../components/modal'
import { deleteIcon, edit, search } from '../../../constants'
import { DeleteContent, DeleteTitle } from '../../../constants/deleteContetnt'
import {
  sortByRoles,
  sortBySearch,
} from '../../../globalState/reducers/adminSlice'
import {
  AdminStyle,
  AdminTop,
  InputDiv,
  TableWrapper,
} from '../../../styles/adminStyles/Admin.Styled'
import {
  CustomButton,
  InputField,
  SelectField,
} from '../../../styles/styledComponent'
import { BadNetwork } from '../../NotFound'
import AdminLoader from '../loaders/adminLoader'

const Admin = () => {
  const { loading, adminData, error, searchedAdmin, sortAdmin } = useSelector(
    (state) => state?.admin,
  )

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const user = localStorage.getItem('userRole')
  const editText = 'You dont have access to update'

  const [selectedAdmin, setSelectedAdmin] = useState([])
  const [checkAll, setCheckAll] = useState(true)
  const [checked, setChecked] = useState({})
  const [searchAdmin, setSearchAdmin] = useState('')
  const [adminRole, setAdminRole] = useState('')

  const handleSearchAdmin = (e) => {
    setAdminRole('')
    dispatch(sortByRoles(''))
    setSearchAdmin(e.target.value)
    dispatch(sortBySearch(e.target.value))
  }

  useEffect(() => {
    async function fetchData() {
      await dispatch(admin())
    }
    fetchData()
  }, [dispatch])

  useEffect(() => {
    if (error === 'null') {
      setTimeout(() => {
        toast.success('Successfully Added Admin', {
          position: toast.POSITION.TOP_CENTER,
        })
      }, 1000)
    }
  }, [error])

  useEffect(() => {
    if (error === 'updateAdmin') {
      // navigate(0)
    }
  }, [error, navigate])

  useEffect(() => {
    if (error === 'deleteAdmin') {
      toast.success('Successfully Deleted Admin', {
        position: toast.POSITION.TOP_CENTER,
      })
      navigate(0)
    }
  }, [error, navigate])

  useEffect(() => {
    const checked =
      adminData?.reduce(
        (result, item) => ({ ...result, [item?.id]: true }),
        {},
      ) || ''
    const selectedAdmin = Object?.keys(checked)
    setChecked(checked)
    setSelectedAdmin(selectedAdmin)
  }, [adminData])

  const toggleCheck = (id) => {
    if (checked[id]) {
      checked[id] = !checked[id]
      const newSelected = selectedAdmin.filter((item) => item !== id)
      setSelectedAdmin(newSelected)
      setChecked(checked)
      setCheckAll(false)
    } else {
      checked[id] = !checked[id]
      const newSelected = [...selectedAdmin, id]
      setSelectedAdmin(newSelected)
      setChecked(checked)
      setCheckAll(!Object.values(checked).some((item) => !item))
    }
  }

  const toggleAll = () => {
    const selectedAdmin = checkAll ? [] : Object.keys(checked)
    setSelectedAdmin(selectedAdmin)
    setCheckAll(!checkAll)
    setChecked(
      Object.keys(checked).reduce(
        (result, key) => ({
          ...result,
          [key]: !checkAll,
        }),
        {},
      ),
    )
  }

  const filteredAdmin = adminData?.filter((data) => {
    return sortAdmin
      ? data.role?.toLowerCase().indexOf(sortAdmin?.toLowerCase()) !== -1
      : data.firstName?.toLowerCase().indexOf(searchedAdmin?.toLowerCase()) !==
          -1
  })

  const latestData =
    filteredAdmin &&
    filteredAdmin
      .map((obj) => {
        return { ...obj, date: new Date(obj?.updatedAt) }
      })
      .sort((a, b) => b?.date - a?.date)

  const handleReset = () => {
    setSearchAdmin('')
    dispatch(sortBySearch(''))
    setAdminRole('')
    dispatch(sortByRoles(''))
  }

  const handleAdminRoles = (e) => {
    setSearchAdmin('')
    dispatch(sortBySearch(''))
    setAdminRole(e.target.value)
    dispatch(sortByRoles(e.target.value))
  }

  useEffect(() => {
    dispatch(sortBySearch(''))
  }, [dispatch])

  if (error === 'BadNetwork') return <BadNetwork />
  return (
    <AdminStyle>
      <AdminTop>
        <h3>Admin</h3>
        <Link to="/admin/addAdmin">
          <div className="btn">
            <CustomButton width="142px" padding="12px 16px">
              Add Admin
            </CustomButton>
          </div>
        </Link>
      </AdminTop>

      <InputDiv>
        <div>
          <div>
            <img src={search} alt="search-icon" />
          </div>
          <InputField
            type="text"
            id="first-name"
            width="70%"
            style={{ color: 'black' }}
            value={searchAdmin}
            background="white"
            onChange={handleSearchAdmin}
            placeholder="Search for Admin"
          />
        </div>
        <SelectField value={adminRole} onChange={handleAdminRoles}>
          <option value="" disabled>
            Select roles
          </option>
          <option value="Admin">Admin</option>
          <option value="Super-admin">Super Admin</option>
          <option value="Manager">Manager</option>
        </SelectField>
        <CustomButton
          hover="#D5D7D7"
          color="#727878"
          border="none"
          background="white"
          width="142px"
          padding="12px 16px"
          onClick={handleReset}
        >
          Reset Action
        </CustomButton>
      </InputDiv>
      {loading ? (
        <AdminLoader />
      ) : (
        <TableWrapper>
          <table className="admin-table">
            <thead>
              <tr>
                <th>
                  <span>
                    <input
                      onChange={() => toggleAll()}
                      type="checkbox"
                      checked={checkAll}
                    />
                  </span>
                </th>
                <th>
                  <span>Admin Name</span>
                </th>
                <th>
                  <span>Email</span>
                </th>
                <th>
                  <span>Roles</span>
                </th>
                <th>
                  <span>Action</span>
                </th>
              </tr>
            </thead>

            {adminData?.length === 0 && (
              <div>There are currently no Admin available</div>
            )}

            {(filteredAdmin?.length === 0 && adminData?.length) && (
              <div>There are currently no Admin available for your search</div>
            )}
              
            {latestData?.map((item, index) => (
              <tbody>
                <tr key={index} className="alternate">
                  <td>
                    <p>
                      <input
                        onChange={() => toggleCheck(item.id)}
                        type="checkbox"
                        checked={checked[item.id]}
                        name={item.id}
                      />
                    </p>
                  </td>
                  <td>
                    <span>
                      {item.firstName} {item.lastName}
                    </span>
                  </td>
                  <td>
                    <span>{item.email}</span>
                  </td>
                  <td>
                    <span>{item.role}</span>
                  </td>
                  <td>
                    <div className="flex">
                      {user === 'super-admin' && (
                        <>
                          <Link to={`${item?.id}`}>
                            <img src={edit} alt="edit" />
                          </Link>
                          <NiyoNotificationModal
                            Content={DeleteContent}
                            Title={DeleteTitle}
                            id={item?.id}
                            nav={deleteAdmin}
                            url={'admin'}
                          >
                            <img src={deleteIcon} alt="deleteIcon" />
                          </NiyoNotificationModal>
                        </>
                      )}
                      {user === 'admin' && (
                        <>
                          <Tooltip placement="topLeft" title={editText}>
                            <img src={edit} alt="edit" className="disabled" />
                          </Tooltip>
                          <NiyoNotificationModal
                            Content={DeleteContent}
                            Title={DeleteTitle}
                            id={item?.id}
                            nav={deleteAdmin}
                            url={'admin'}
                          >
                            <img src={deleteIcon} alt="deleteIcon" />
                          </NiyoNotificationModal>
                        </>
                      )}
                      {user === 'manager' && (
                        <>
                          <Tooltip placement="topLeft" title={editText}>
                            <img src={edit} alt="edit" className="disabled" />
                          </Tooltip>
                          <NiyoNotificationModal
                            Content={DeleteContent}
                            Title={DeleteTitle}
                            id={item?.id}
                            nav={deleteAdmin}
                            url={'admin'}
                          >
                            <img src={deleteIcon} alt="deleteIcon" />
                          </NiyoNotificationModal>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
        </TableWrapper>
      )}
      <ToastContainer />
    </AdminStyle>
  )
}

export default Admin
