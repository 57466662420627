import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Loading from "../../../assets/icons/loading.gif";
import {
  AddTeamForm,
  AddTeamStyle,
  AddTeamTop,
} from "../../../styles/adminStyles/AdminAddTeam.Styled";
import "react-quill/dist/quill.snow.css";

import { department, getJob, updateJob } from "../../../api/job";

import { Link, useNavigate } from "react-router-dom";
import ArrowIcon from "../../../assets/icons/arrow-icon.svg";
import { AddAdminStyle } from "../../../styles/adminStyles/AdminAdd.Styled";
import {
  CustomButton,
  InputField,
  SelectField,
} from "../../../styles/styledComponent";
import JobLoader from "../loaders/jobLoader";

const UpdateJobs = () => {
  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
    ],
  };
  const navigate = useNavigate();
  const elementRef = useRef();
  const { error, loading, data, departmentList } = useSelector(
    (state) => state.job
  );

  const dispatch = useDispatch();

  let { id } = useParams();

  const [details, setDetails] = useState(data && data.description);
  const [detailsChange, setDetailsChange] = useState(false);
  const [title, setTitle] = useState(data?.title);
  const [titleChange, setTitleChange] = useState(false);
  const [jobDepartment, setJobDepartment] = useState(data?.department?.name);
  const [jobDepartmentChange, setJobDepartmentChange] = useState(false);

  useEffect(() => {
    function fetchData() {
      dispatch(getJob(id));
    }
    fetchData();
  }, [dispatch, id]);

  useEffect(() => {
    async function fetchData() {
      await dispatch(department());
    }
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (error === "updateJob") {
      navigate("/admin/job");
    }
  }, [error, navigate]);

  useEffect(() => {
    if (
      error !== null &&
      error !== "null" &&
      error !== "department" &&
      error !== "updateJob" &&
      error !== "getJob" &&
      error !== "deleteJob" &&
      error !== "BadNetwork"
    ) {
      toast.error(error, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }, [error]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (details && data?.description !== details) {
      const description = details;
      dispatch(updateJob({ id, description }));
    }
    if (title && data?.title !== title) {
      dispatch(updateJob({ id, title }));
    }
    if (jobDepartment && data?.department?.name !== jobDepartment) {
      const department = jobDepartment;
      dispatch(updateJob({ id, department }));
    }
  };
  const handleTitle = (e) => {
    setTitleChange(true);
    setTitle(e.target.value);
  };

  const handleDepartment = (e) => {
    setJobDepartmentChange(true);
    setJobDepartment(e.target.value);
  };
  if (loading) return <JobLoader />;
  return (
    <>
      {data && (
        <AddAdminStyle>
          <AddTeamStyle>
            <Link to="/admin/job">
              <AddTeamTop>
                <img src={ArrowIcon} alt="Arrow Icon" /> <h5>Update a job</h5>
              </AddTeamTop>
            </Link>

            <AddTeamForm>
              <form onSubmit={handleSubmit}>
                <div className="inputsField">
                  <div>
                    <label>Job Title </label>
                    <InputField
                      type="text"
                      ref={elementRef}
                      value={titleChange ? title : data?.title}
                      onChange={handleTitle}
                      placeholder="Growth Marketer"
                    />
                  </div>
                  <div>
                    <div>Department</div>
                    <SelectField
                      name=""
                      value={jobDepartmentChange && jobDepartment}
                      onChange={handleDepartment}
                    >
                      <option value="">{data?.department?.name}</option>
                      {departmentList?.map((department) => (
                        <option value={department?.id}>
                          {department?.name}
                        </option>
                      ))}
                    </SelectField>
                  </div>
                  <label>Job details</label>
                  <ReactQuill
                    theme="snow"
                    value={detailsChange ? details : data.description}
                    placeholder={data?.description}
                    onChange={(e) => {
                      setDetailsChange(true);
                      setDetails(e);
                    }}
                    className="editor-input"
                    modules={modules}
                  />

                  {/* <CKEditor
                    editor={ClassicEditor}
                    className="editor-input"
                    data={detailsChange ? details : data.description}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                        setDetails(data);
                    }}
                  /> */}
                  {/* <div dangerouslySetInnerHTML={{ __html: value }}/> */}
                </div>
                <CustomButton
                  padding="12px 16px"
                  margin="0px"
                  marginL="auto"
                  display="block"
                >
                  {loading === "loading" ? (
                    <img src={Loading} alt="loader" width="30%" height="30%" />
                  ) : (
                    "Save Changes"
                  )}
                </CustomButton>
              </form>
            </AddTeamForm>
            <ToastContainer />
          </AddTeamStyle>
        </AddAdminStyle>
      )}
    </>
  );
};

export default UpdateJobs;
