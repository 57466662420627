import client from '../utils/client'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL } from './ config'


export const team = createAsyncThunk(
  'team/fetchTeam',
  async (obj,{ rejectWithValue }) => {
    try {
     
      const { data: {data} } = await client.get(
        `${BASE_URL}/api/v1/team`
      )
      return data
    } catch (error) {
      // return custom error message from API if any
      throw rejectWithValue(error.message)
    }
  }
)


export const createTeam = createAsyncThunk(
  'team/createteam',
  async ( form_data , { rejectWithValue }) => {
    try {
        const config = {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
      const { data:{ data } } = await client.post(
          `${BASE_URL}/api/v1/team`,
          form_data ,
          config,
      )
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)


export const getTeam = createAsyncThunk(
  'team/getTeam',
  async (id,{ rejectWithValue }) => {
    try {
     
      const { data: {data} } = await client.get(
        `${BASE_URL}/api/v1/team/${id}`
      )
      return data
    } catch (error) {
      // return custom error message from API if any
      throw rejectWithValue(error.message)
    }
  }
)


export const updateTeam = createAsyncThunk(
  'team/updateTeam',
  async ( { id, fullName, jobRole, funFacts, socialUrl, form_data } , { rejectWithValue }) => {
    try {
      if (fullName) {
        const { data:{ data } } = await client.patch(
          `${BASE_URL}/api/v1/team/${id}`,
          { fullName }
      )
      return data
      }
      if (jobRole) {
        const { data:{ data } } = await client.patch(
          `${BASE_URL}/api/v1/team/${id}`,
          { jobRole }
      )
      return data
      }
      if (funFacts) {
        const { data:{ data } } = await client.patch(
          `${BASE_URL}/api/v1/team/${id}`,
          { funFacts }
      )
      return data
      }
      if (socialUrl) {
        const { data:{ data } } = await client.patch(
          `${BASE_URL}/api/v1/team/${id}`,
          { socialUrl }
      )
      return data
      }
      if (form_data) {
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
        const { data:{ data } } = await client.patch(
          `${BASE_URL}/api/v1/team/${id}`,
          form_data,
           config
      )
      return data
      }
        
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const deleteTeam = createAsyncThunk(
  'team/deleteTeam',
  async (id,{ rejectWithValue }) => {
    try {
     
      const { data: {data} } = await client.delete(
        `${BASE_URL}/api/v1/team/${id}`
      )
      return data
    } catch (error) {
      // return custom error message from API if any
      throw rejectWithValue(error.message)
    }
  }
)


