import React, { useState } from 'react'
import styled from 'styled-components'
import { ToastContainer } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { updateAdminEmail } from '../api/admin'
import { CustomButton, InputField } from '../styles/styledComponent'

export const EmailTitle = () => {
  return (
    <Title>
      <p>Change Email</p>
    </Title>
  )
}

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  p:first-child {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
  }
`

export const EmailContent = ({ id, oldEmail, hide }) => {
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')

  const handleEmailSubmit = (e) => {
    e.preventDefault()
    dispatch(updateAdminEmail({ id, email }))
    hide()
  }


  return (
    <Wrapper>
      <form>
        <div>
          <label>Old email</label>
          <InputField
            style={{ color: 'black' }}
            type="text"
            value={oldEmail}
            placeholder="Mynewemail.com"
          />
          <label>New email</label>
          <InputField
             style={{ color: 'black' }}
            type="text"
            value={email}
            placeholder="Mynewemail.com"
            onChange={({ target }) => {
              setEmail(target.value)
            }}
          />

          <CustomButton
            onClick={handleEmailSubmit}
            padding="5px 8px"
            Radius="4px"
            className={`${email === '' ? 'disabled' : ''}`}
          >
            Submit
          </CustomButton>
          <ToastContainer />
        </div>
      </form>
    </Wrapper>
  )
}
export const Wrapper = styled.div`
  width: 500px;
  button {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  .disabled {
    pointer-events: none;
  }
`
