import { createSlice } from '@reduxjs/toolkit'
import { team, createTeam, getTeam, updateTeam, deleteTeam } from '../../api/team'

const initialState = {
  loading: false,
  error: null,
  teams: null,
  success: false,
  data: null,
  searchedTeam: ''
}

const teamSlice = createSlice({
  name: 'team',
  initialState,
    reducers: {
        sortBySearch: (state, action) => {
            const payload = action.payload;
            state.searchedTeam = payload
          },
  },
  extraReducers: {
    [team.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [team.fulfilled]: (state, action) => {
      state.loading = false
      state.teams = action.payload
    },
    [team.rejected]: (state, action) => {
      state.loading = false
      state.error = 'BadNetwork'
    },
    [createTeam.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [createTeam.fulfilled]: (state, action) => {
      state.loading = false
      state.data = action.payload
      state.error = 'null'
    },
    [createTeam.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [getTeam.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [getTeam.fulfilled]: (state, action) => {
        state.loading = false
      state.data = action.payload
      state.error = 'getTeam'
    },
    [getTeam.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [updateTeam.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [updateTeam.fulfilled]: (state, action) => {
      state.loading = false
      state.error = 'updateTeam'
    },
    [updateTeam.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [deleteTeam.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [deleteTeam.fulfilled]: (state, action) => {
      state.loading = false
      state.error = 'deleteTeam'
    },
    [deleteTeam.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
  },
})
export default teamSlice.reducer


export const { sortBySearch } = teamSlice.actions
