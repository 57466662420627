import styled from "styled-components";

export const AddAdminStyle = styled.div`
  form {
    .inputs {
      gap: 65px;
      div {
        select {
          width: 100%;
          background-color: white;
        }
      }
    }
    .flex { 
      label{
        margin-bottom: 12px;
      }
      p{
        font-size: 16px;
        margin: 0;
      }
      .selections {
        display: flex;
        margin-top: 15px;
      }
      .check {
        div {
          display: flex;
          align-items: flex-start;
          
          p {
            font-size: 12px;
            padding-top: 0px;
            margin-top: -5px;
            margin-left: 10px;
            margin-right: 40px;
            max-width: 1580px;
            white-space: normal !important;
            word-wrap: break all;
          }
          input {
            width: unset;
            margin: 0;
          }
        }
      }
    }
      .check1 {
        div {
          display: flex;
          align-items: flex-start;
          
          p {
            font-size: 12px;
            padding-top: 0px;
            margin-top: -5px;
            margin-left: 10px;
            width: 100%;
          }
          input {
            width: unset;
            margin: 0;
          }
        }
      }
    }
  }
`;
