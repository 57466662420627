import { createSlice } from '@reduxjs/toolkit'
import {
  job,
  createJob,
  updateJob,
  department,
  getJob,
  deleteJob,
} from '../../api/job'

const initialState = {
  loading: false,
  error: null,
  success: false,
  data: null,
  jobs: null,
  details: '',
  departmentList: null,
  searchedJob: '',
}

const jobSlice = createSlice({
  name: 'job',
  initialState,
  reducers: {
    sortBySearch: (state, action) => {
      const payload = action.payload
      state.searchedJob = payload
    },
  },
  extraReducers: {
    [job.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [job.fulfilled]: (state, action) => {
      state.loading = false
      state.jobs = action.payload
    },
    [job.rejected]: (state) => {
      state.loading = false
      state.error = 'BadNetwork'
    },
    [updateJob.pending]: (state) => {
      state.loading = 'loading'
      state.error = null
    },
    [updateJob.fulfilled]: (state, action) => {
      state.loading = false
      state.data = action.payload
      state.error = 'updateJob'
    },
    [updateJob.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [createJob.pending]: (state) => {
      state.loading = 'loading'
      state.error = null
    },
    [createJob.fulfilled]: (state, action) => {
      state.loading = false
      state.data = action.payload
      state.error = 'null'
    },
    [createJob.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [department.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [department.fulfilled]: (state, action) => {
      state.loading = false
      state.departmentList = action.payload
      state.error = 'department'
    },
    [department.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [getJob.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [getJob.fulfilled]: (state, action) => {
      state.loading = false
      state.data = action.payload
      state.error = 'getJob'
      state.details = action.payload.description
    },
    [getJob.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [deleteJob.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [deleteJob.fulfilled]: (state) => {
      state.loading = false
      state.error = 'deleteJob'
    },
    [deleteJob.rejected]: (state, action) => {
      state.loading = false
      state.error = action.error.message
    },
  },
})
export default jobSlice.reducer

export const { sortBySearch } = jobSlice.actions
