import React, { useState, useEffect } from 'react'
import {
  AddTeamForm,
  AddTeamStyle,
  AddTeamTop,
} from '../../../styles/adminStyles/AdminAddTeam.Styled'
import ArrowIcon from '../../../assets/icons/arrow-icon.svg'
import { useDispatch, useSelector } from 'react-redux'
import { createCareer } from '../../../api/career'
import {
  jobLevelContent,
  jobTypeContent,
  // jobLocationContent,
} from '../../../constants'
import Loading from '../../../assets/icons/loading.gif'
import { ToastContainer, toast } from 'react-toastify'
import AttachIcon from '../../../assets/icons/attach-icon.svg'
import { useNavigate } from 'react-router-dom'
import DropFileIcon from '../../../assets/icons/dropFile-icon.svg'
import { Link } from 'react-router-dom'
import Dropzone from 'react-dropzone'
import {
  CustomButton,
  InputField,
  SelectField,
} from '../../../styles/styledComponent'

const AddCareer = () => {
  const { error, loading } = useSelector((state) => state.career)
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const [selectedFile, setSelectedFile] = useState(null)
  const [dropFile, setDropFile] = useState(null)
  // const [jobDescription, setJobDescription] = useState('')
  const [jobRole, setJobRole] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [jobType, setJobType] = useState('')
  const [jobLink, setJobLink] = useState('')
  const [jobLocation, setJobLocation] = useState('')
  const [jobLevel, setJobLevel] = useState('')

  const handleDataState = (file) => {
    setSelectedFile(file)
  }
  const handleFileUpload = (event) => {
    handleDataState(event.target.files[0])
  }

  const handleFileDrop = (file) => {
    handleDataState(file)
    setDropFile(file)
  }

  useEffect(() => {
    if (error !== null && error !== 'null') {
      toast.error(error, {
        position: toast.POSITION.TOP_CENTER,
      })
    }
  }, [error])
  useEffect(() => {
    if (error === 'null') {
      navigate('/admin/career')
    }
  }, [error, navigate])

  const handleSubmit = async (e) => {
    e.preventDefault()
    const photo = selectedFile || dropFile
    let form_data = new FormData()
    form_data.append('companyLogo', photo, photo?.path)
    // form_data.append('jobDescription', jobDescription)
    form_data.append('jobRole', jobRole)
    form_data.append('companyName', companyName)
    form_data.append('jobType', jobType)
    form_data.append('jobLink', jobLink)
    form_data.append('jobLevel', jobLevel)
    form_data.append('jobLocation', jobLocation)
    dispatch(createCareer(form_data))
    // setJobDescription('')
    // setJobRole('')
    // setCompanyName('')
    // setJobType('')
    // setSelectedFile(null)
    // setDropFile(null)
  }

  // const handleDescription = (e) => {
  //   setJobDescription(e.target.value)
  // }
  const handleRole = (e) => {
    setJobRole(e.target.value)
  }
  const handleCompanyName = (e) => {
    setCompanyName(e.target.value)
  }
  const handleJobType = (e) => {
    setJobType(e.target.value)
  }
  const handleJobLocation = (e) => {
    setJobLocation(e.target.value)
  }
  const handleJobLevel = (e) => {
    setJobLevel(e.target.value)
  }
  const handleJobLInk = (e) => {
    setJobLink(e.target.value)
  }
  return (
    <AddTeamStyle>
      <Link to="/admin/career">
        <AddTeamTop>
          <img src={ArrowIcon} alt="Arrow Icon" /> <h5>AddCareer</h5>
        </AddTeamTop>
      </Link>

      <AddTeamForm>
        <form onSubmit={handleSubmit}>
          <div className="inputs">
            <div>
              <label>Job Role</label>
              <InputField
                value={jobRole}
                onChange={handleRole}
                type="text"
                placeholder="Frontend Engineer"
              />
            </div>
            {/* <div>
              <label>Job Description </label>
              <TextareaField
                value={jobDescription}
                onChange={handleDescription}
                type="text"
                placeholder="A very good job post..."
              />
            </div> */}
            <div>
              <label>companyName</label>
              <InputField
                value={companyName}
                onChange={handleCompanyName}
                placeholder="NiyoGroup"
              ></InputField>
            </div>
            <div>
              <label>Upload Company Logo</label>
              <Dropzone onDrop={(files) => handleFileDrop(files[0])}>
                {({ getRootProps, getInputProps }) => (
                  <div className="btn" {...getRootProps()}>
                    <input {...getInputProps()} onChange={handleFileUpload} />
                    <div>
                      {selectedFile !== null ? (
                        <p>Image Uploaded</p>
                      ) : (
                        <>
                          <img src={AttachIcon} alt="attach icon" />
                          <i>Attach file</i>
                        </>
                      )}
                    </div>
                    <p>or</p>
                    <div>
                      {dropFile !== null ? (
                        <p>Image Uploaded</p>
                      ) : (
                        <>
                          <img src={DropFileIcon} alt="Drop File Icon" />
                          <i>Drop file here</i>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </Dropzone>
            </div>
            <div>
              <SelectField name="" value={jobType} onChange={handleJobType}>
                <option value="" disabled>
                  Select Job Type
                </option>
                {jobTypeContent?.map((item) => {
                  const val = item.replace(/-/g, '').toLowerCase()
                  return <option value={val}>{item}</option>
                })}
              </SelectField>
            </div>
            <div>
              {/* <SelectField
                name=""
                value={jobLocation}
                onChange={handleJobLocation}
              >
                <option value="" disabled>
                  Select Job Location
                </option>
                {jobLocationContent?.map((item) => {
                  const val = item.replace(/-/g, '').toLowerCase()
                  return <option value={val}>{item}</option>
                })}
              </SelectField> */}
              <label>Select Job Location</label>
              <InputField
                value={jobLocation}
                onChange={handleJobLocation}
                placeholder="Select Job Location"
              ></InputField>
            </div>
            <div>
              <SelectField
                name=""
                value={jobLevel}
                onChange={handleJobLevel}
              >
                <option value="" disabled>
                  Select Job Level
                </option>
                {jobLevelContent?.map((item) => {
                  const val = item.replace(/-/g, '').toLowerCase()
                  return <option value={val}>{item}</option>
                })}
              </SelectField>
            </div>
            <div>
              <label>Job Link</label>
              <InputField
                value={jobLink}
                onChange={handleJobLInk}
                type="url"
                required
                placeholder="https://docs.flutter.dev/deployment/android"
              />
            </div>
          </div>
          <CustomButton
            padding="12px 16px"
            className={`${
              jobType === '' ||
              companyName === '' ||
              jobRole === '' ||
              jobLevel === '' ||
              jobLink === '' ||
              jobLocation === '' ||
              selectedFile === null
                ? 'disabled'
                : ''
            }`}
          >
            {loading ? (
              <img src={Loading} alt="loader" width="30%" height="30%" />
            ) : (
              'Save Changes'
            )}
          </CustomButton>
        </form>
      </AddTeamForm>
      <ToastContainer />
    </AddTeamStyle>
  )
}

export default AddCareer
