import client from "../utils/client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "./ config";

export const blog = createAsyncThunk(
  "blog/fetchblog",
  async (obj, { rejectWithValue }) => {
    try {
      const {
        data: { data },
      } = await client.get(`${BASE_URL}/api/v1/blog`);
      return data;
    } catch (error) {
      // return custom error message from API if any
      throw rejectWithValue(error.message);
    }
  }
);

export const getBlog = createAsyncThunk(
  "blog/getBlog",
  async (id, { rejectWithValue }) => {
    try {
      const {
        data: { data },
      } = await client.get(`${BASE_URL}/api/v1/blog/${id}`);
      return data;
    } catch (error) {
      // return custom error message from API if any
      throw rejectWithValue(error.message);
    }
  }
);

export const createBlog = createAsyncThunk(
  "blog/createBlog",
  async (form_data, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const {
        data: { data },
      } = await client.post(`${BASE_URL}/api/v1/blog`, form_data, config);
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateBlog = createAsyncThunk(
  "blog/updateBlog",
  async ( { id, description, title, category, form_data } , { rejectWithValue }) => {
    try {
      if (title) {
        const { data:{ data } } = await client.patch(
          `${BASE_URL}/api/v1/blog/${id}`,
          { title }
      )
      return data
      }
      if (description) {
        const { data:{ data } } = await client.patch(
          `${BASE_URL}/api/v1/blog/${id}`,
          { description }
      )
      return data
      }
      if (category) {
        const { data:{ data } } = await client.patch(
          `${BASE_URL}/api/v1/blog/${id}`,
          { category }
      )
      return data
      }
      if (form_data) {
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
        const { data:{ data } } = await client.patch(
          `${BASE_URL}/api/v1/blog/${id}`,
          form_data ,
          config
      )
      return data
      }
        
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
);

export const deleteBlog = createAsyncThunk(
  "blog/deleteBlog",
  async (id, { rejectWithValue }) => {
    try {
      const {
        data: { data },
      } = await client.delete(`${BASE_URL}/api/v1/blog/${id}`);
      return data;
    } catch (error) {
      // return custom error message from API if any
      throw rejectWithValue(error.message);
    }
  }
);

export const publishBlog = createAsyncThunk(
  "blog/publishBlog",
  async (id, { rejectWithValue }) => {
    try {
      const {
        data: { data },
      } = await client.patch(`${BASE_URL}/api/v1/blog/${id}/publish`);
      return data;
    } catch (error) {
      // return custom error message from API if any
      throw rejectWithValue(error.message);
    }
  }
);
