import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { notification, updateNotification } from '../api/notification'
import dayjs from 'dayjs'
import { CustomButton } from '../styles/styledComponent'

export const NotificationTitle = () => {
  return (
    <Title>
      <p>Notifications</p>
      <p>Mark all as read</p>
    </Title>
  )
}

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  p:first-child {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
  }
  p:last-child {
    text-decoration: underline;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
  }
`

export const NotificationContent = ({notifications, hide}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    async function fetchData() {
      await dispatch(notification())
    }
    fetchData()
  }, [dispatch])

  const handlers = (action, id) => {
    dispatch(updateNotification({ action, id }))
    hide()
  }
  const relativeTime = require('dayjs/plugin/relativeTime')
  dayjs.extend(relativeTime)
  
  const user = localStorage.getItem('userRole')

  return (
    <Wrapper>
      {notifications?.map((item) => {
        const formatDate = dayjs(item?.updatedAt)?.format('YYYY-MMMM-DD')
        return (
          <div key={item}>
            <Content>
              <div>
                <img src={''} alt="profile" />
              </div>
              <div>
                <div className="contentWrapper">{item?.message}</div>
                <div>
                  {`${dayjs(formatDate).fromNow()} --- ${item.title}`}
                </div>

                {user === 'super-admin' && (

                <div>
                  <CustomButton
                    padding="5px 8px"
                    Radius="4px"
                    onClick={()=>handlers("approve", item?.id)}
                  >
                    Accept
                  </CustomButton>
                  <CustomButton
                    background="transparent"
                    color="black"
                    padding="5px 8px"
                    Radius="4px"
                    onClick={()=>handlers("decline", item?.id)}  
                  >
                    Decline
                  </CustomButton>
                </div>
                )}
              </div>
            </Content>
          </div>
        )
      })}
      <div className="view">View all notification</div>
    </Wrapper>
  )
}
export const Wrapper = styled.div`
  .view {
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }
`
export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 20px;
  div:first-child {
    margin-right: 20px;
  }
  div:last-child {
    div:nth-child(2) {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 25px;
      color: #b5baba;
    }
    div:nth-child(3) {
      button:first-child {
        margin-right: 10px;
      }
      display: flex;
    }
  }
  .contentWrapper {
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: #070909;
    span:first-child {
      font-weight: 300;
      font-size: 18px;
      line-height: 25px;
      letter-spacing: -1px;
      // 1px solid #B5BABA
      color: #070909;
    }
  }
`
