import styled from "styled-components";

export const SideNavStyle = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  padding: 34px 0px;
  background-color: var(--black);
  color: #fff;
  a {
    text-decoration:none
  }
`;

export const Logo = styled.div`
  width: 80%;
  margin: 0 auto;
  padding-bottom: 31px;
  margin-bottom: 15px;
  border-bottom: 0.73px solid #636969;
  img {
    width: 80px;
  }
`;

export const SideNavList = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 5px 26px;
  gap: 13px;
  color: #a0a6a6;
  text-transform: none;
  a{
    border: 3px solid red;
  }
  &:hover {
    cursor: pointer;
  }
  img {
    width: 32px;
  }
  p {
    font-size: 16px;
  }
`;

export const SideNavMenu = styled.ul`
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 10px;
  flex: 1;
  color: white;
  a {
    width: 100%;
    text-decoration: none;
  }
  .active {
    background-color: #313535;
    color: white;
    svg {
      color: white;
    }
  }
  svg {
    color: 'white' !important;
  }
`;
