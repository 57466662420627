import React, { useState, useEffect } from "react";
import {
  AddTeamForm,
  AddTeamStyle,
  AddTeamTop,
} from "../../../styles/adminStyles/AdminAddTeam.Styled";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import EventLoader from "../loaders/eventLoader";
import Dropzone from "react-dropzone";
import ArrowIcon from "../../../assets/icons/arrow-icon.svg";
import { useDispatch, useSelector } from "react-redux";

import { getEvent, updateEvents } from "../../../api/events";

import Loading from "../../../assets/icons/loading.gif";
import { ToastContainer, toast } from "react-toastify";
import AttachIcon from "../../../assets/icons/attach-icon.svg";
import DropFileIcon from "../../../assets/icons/dropFile-icon.svg";
import {
  CreateEventStyle,
  DropDownHeader,
  DropDownList,
  DropDownListContainer,
  ListItem,
} from "../../../styles/adminStyles/AdminCreateEvent.Styled";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  CustomButton,
  InputField,
  TextareaField,
} from "../../../styles/styledComponent";
import { customTime } from "../../../constants";

const UpdateEvent = () => {
  const { error, data, loading } = useSelector((state) => state.events);

  const dispatch = useDispatch();

  const [{ content }, setContent] = useState({
    content: data?.title,
    wordCount: 0,
  });
  const [titleChange, setTitleChange] = useState(false);

  const [description, setDescription] = useState(data?.description);
  const [descriptionChange, setDescriptionChange] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [dropFile, setDropFile] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const [link, setLink] = useState(data?.eventLink);
  const [selectedLink, setSelectedLink] = useState(false);

  const [selectedOption, setSelectedOption] = useState(data?.eventTime);
  const [selectedOptionChange, setSelectedOptionChange] = useState(false);

  const [selectedDate, setSelectedDate] = useState(data?.eventDate);

  const navigate = useNavigate();

  const toggling = () => setIsOpen(!isOpen);

  let { id } = useParams();

  useEffect(() => {
    async function fetchData() {
      await dispatch(getEvent(id));
    }
    fetchData();
  }, [dispatch, id]);

  useEffect(() => {
    if (
      error !== null &&
      error !== "updateEvents" &&
      error !== "getEvent" &&
      error !== "deleteEvent" &&
      error !== "BadNetwork"
    ) {
      toast.error(error, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }, [error]);

  useEffect(() => {
    if (error === "updateEvents") {
      navigate("/admin/event");
    }
  }, [error, navigate]);

  const onOptionClicked = (value) => () => {
    setSelectedOptionChange(true);
    setSelectedOption(value);
    setIsOpen(false);
  };

  const handleDataState = (file) => {
    setSelectedFile(file);
  };
  const handleFileUpload = (event) => {
    handleDataState(event.target.files[0]);
  };

  const handleFileDrop = (file) => {
    handleDataState(file);
    setDropFile(file);
  };
  const onChange = (date, dateString) => {
    setSelectedDate(dateString);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const coverPhoto = selectedFile || dropFile;
    let form_data = new FormData();

    if (coverPhoto) {
      form_data.append("coverPhoto", coverPhoto, coverPhoto?.path);
      if (content && data?.title !== content) {
        form_data.append("title", content);
        dispatch(updateEvents({ id, form_data }));
      }
      if (description && data?.description !== description) {
        form_data.append("description", description);
        dispatch(updateEvents({ id, form_data }));
      }
      if (selectedDate && data?.selectedDate !== selectedDate) {
        form_data.append("eventDate", selectedDate);
        dispatch(updateEvents({ id, form_data }));
      }
      if (selectedOption && data?.selectedOption !== selectedOption) {
        form_data.append("eventTime", selectedOption);
        dispatch(updateEvents({ id, form_data }));
      }
      if (link && data?.eventLink !== link) {
        form_data.append("eventLink", link);
        dispatch(updateEvents({ id, form_data }));
      }
      if (coverPhoto) {
        dispatch(updateEvents({ id, form_data }));
      }
      // dispatch(updateEvents({ id, form_data }))
    } else {
      if (content && data?.title !== content) {
        form_data.append("title", content);
        const title = content;
        dispatch(updateEvents({ id, title }));
      }
      if (description && data?.description !== description) {
        form_data.append("description", description);
        dispatch(updateEvents({ id, description }));
      }
      if (selectedDate && data?.selectedDate !== selectedDate) {
        form_data.append("selectedDate", selectedDate);
        const eventDate = selectedDate;
        dispatch(updateEvents({ id, eventDate }));
      }
      if (selectedOption && data?.selectedOption !== selectedOption) {
        form_data.append("eventTime", selectedOption);
        const eventTime = selectedOption;
        dispatch(updateEvents({ id, eventTime }));
      }
      if (link && data?.eventLink !== link) {
        form_data.append("eventLink", link);
        const eventLink = link;
        dispatch(updateEvents({ id, eventLink }));
      }
    }
  };

  const handleDescription = (e) => {
    setDescriptionChange(true);
    const limit = 35;
    setDescription(e.target.value.slice(0, limit));
  };

  const limit = 6;
  const setFormattedContent = React.useCallback(
    (text) => {
      setTitleChange(true);
      let words = text.split(" ").filter(Boolean);

      if (words.length > limit) {
        setContent({
          content: words.slice(0, limit).join(" "),
          wordCount: limit,
        });
      } else {
        setContent({ content: text, wordCount: words.length });
      }
    },
    [limit, setContent]
  );

  // React.useEffect(() => {
  //   setFormattedContent(content)
  // }, [content, setFormattedContent])

  const handleLink = (e) => {
    setSelectedLink(true);
    setLink(e.target.value);
  };
  if (loading) return <EventLoader />;
  return (
    <CreateEventStyle>
      <AddTeamStyle>
        <Link to="/admin/event">
          <AddTeamTop>
            <img src={ArrowIcon} alt="Arrow Icon" /> <h5>Update Events</h5>
          </AddTeamTop>
        </Link>
        <AddTeamForm>
          <form onSubmit={handleSubmit}>
            <div className="inputs">
              <div>
                <label>Event Titile </label>
                <TextareaField
                  type="text"
                  value={titleChange ? content : data?.title}
                  // value={data?.title}
                  onChange={(event) => setFormattedContent(event.target.value)}
                  placeholder="Building your Personal Brand in the workplace & Tackling Impostor Syndrome"
                />
              </div>
              <div>
                <label>Event Description </label>
                <TextareaField
                  type="text"
                  value={descriptionChange ? description : data?.description}
                  onChange={handleDescription}
                  placeholder="Building your Personal Brand in the workplace & Tackling Impostor Syndrome"
                />
              </div>

              <div className="flex update">
                <div>
                  <label>Events Date</label>
                  <DatePicker
                    placeholder={dayjs(data?.eventDate).format("YYYY-MM-DD")}
                    onChange={onChange}
                    id="inputID"
                  />
                </div>
                <div>
                  <label>Events Time</label>
                  <DropDownHeader onClick={toggling} className="update">
                    {selectedOptionChange ? selectedOption : data?.eventTime}
                  </DropDownHeader>
                  {isOpen && (
                    <DropDownListContainer>
                      <DropDownList>
                        {customTime.map((option) => (
                          <ListItem
                            onClick={onOptionClicked(option)}
                            key={Math.random()}
                          >
                            {option}
                          </ListItem>
                        ))}
                      </DropDownList>
                    </DropDownListContainer>
                  )}
                </div>
              </div>

              <div className="uploads">
                <label>Update event image</label>
                <Dropzone onDrop={(files) => handleFileDrop(files[0])}>
                  {({ getRootProps, getInputProps }) => (
                    <div className="btn" {...getRootProps()}>
                      <input {...getInputProps()} onChange={handleFileUpload} />
                      <div>
                        {selectedFile !== null ? (
                          <p>Image Uploaded</p>
                        ) : (
                          <>
                            <img src={AttachIcon} alt="attach icon" />
                            <i>Attach file</i>
                          </>
                        )}
                      </div>
                      <p>or</p>
                      <div>
                        {dropFile !== null ? (
                          <p>Image Uploaded</p>
                        ) : (
                          <>
                            <img src={DropFileIcon} alt="Drop File Icon" />
                            <i>Drop file here</i>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </Dropzone>
              </div>
              <div>
                <label>Event Registration Link </label>
                <InputField
                  value={selectedLink ? link : data?.eventLink}
                  onChange={handleLink}
                  type="url"
                  required
                  placeholder="https://registerevent.com"
                />
              </div>
            </div>
            <CustomButton padding="12px 16px">
              {loading ? (
                <img src={Loading} alt="loader" width="30%" height="30%" />
              ) : (
                "Save Changes"
              )}
            </CustomButton>
          </form>
        </AddTeamForm>
      </AddTeamStyle>
      <ToastContainer />
    </CreateEventStyle>
  );
};

export default UpdateEvent;
