import { Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { deleteBlog, blog, publishBlog } from "../../../api/blog";
import NiyoNotificationModal from "../../../components/modal";
import NiyoPagination from "../../../components/pagination";
import { deleteIcon, edit, search, lock } from "../../../constants";
import { DeleteContent, DeleteTitle, PublishContent, PublishTitle } from "../../../constants/deleteContetnt";
import { sortBySearch } from "../../../globalState/reducers/blogSlice";
import { DashboardTop } from "../../../styles/adminStyles/AdminDashBoard.Styled";
import { EventStyle } from "../../../styles/adminStyles/AdminEvent.Styled";
import { TableWrapper } from "../../../styles/adminStyles/AdminJob.styled";
import { Empty, TeamStyle } from "../../../styles/adminStyles/AdminTeam.Styled";
import { CustomButton, InputField } from "../../../styles/styledComponent";
import { BadNetwork } from "../../NotFound";
import JobLoader from "../loaders/jobLoader";

const Blog = () => {
  const page = 5;

  const { loading, blogs, error, searchedBlog } = useSelector(
    (state) => state?.blog
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = localStorage.getItem("userRole");
  const editText = "You dont have access to update";

  useEffect(() => {
    async function fetchData() {
      await dispatch(blog());
    }
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (error === "deleteBlog") {
      toast.success("Successfully Deleted Blog", {
        position: toast.POSITION.TOP_CENTER,
      });
      // setTimeout(() => {
      navigate(0);
      // }, 1000)
    }
  }, [error, navigate]);

  useEffect(() => {
    if (error === "publishBlog") {
      toast.success("Successfully Published Blog", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }, [error, navigate]);

  useEffect(() => {
    if (error === "null") {
      setTimeout(() => {
        toast.success("Successfully Added Blog To Draft", {
          position: toast.POSITION.TOP_CENTER,
        });
      }, 1000);
    }
  }, [error]);

  useEffect(() => {
    if (error === "Rejected") {
      setTimeout(() => {
        toast.success("Your Delete Request is sent to admin", {
          position: toast.POSITION.TOP_CENTER,
        });
      }, 1000);
    }
  }, [error]);

  useEffect(() => {
    if (error === "updateBlog") {
      setTimeout(() => {
        toast.success("Successfully Updated Blog", {
          position: toast.POSITION.TOP_CENTER,
        });
      }, 1000);
    }
  }, [error, navigate]);

  const filteredJob = blogs?.filter((blog) => {
    return (
      blog.title?.toLowerCase().indexOf(searchedBlog?.toLowerCase()) !== -1
    );
  });

  const [sizes, setSizes] = useState(page);
  const [currents, setCurrents] = useState(1);
  const [searchBlog, setSearchBlog] = useState("");

  const handleSearchBlog = (e) => {
    setSearchBlog(e.target.value);
    dispatch(sortBySearch(e.target.value));
  };

  useEffect(() => {
    setSearchBlog("");
    dispatch(sortBySearch(""));
  }, [dispatch]);

  const latestData =
    filteredJob &&
    filteredJob
      .map((obj) => {
        return { ...obj, date: new Date(obj?.updatedAt) };
      })
      .sort((a, b) => b?.date - a?.date);

  const getData = (current, pageSize) => {
    return latestData?.slice((current - 1) * pageSize, current * pageSize);
  };
  if (error === "BadNetwork") return <BadNetwork />;

  return (
    <EventStyle>
      <TeamStyle>
        <DashboardTop>
          <h5>Blog</h5>
          <div className="team">
            <div>
              <div>
                <img src={search} alt="search-icon" />
              </div>
              <InputField
                type="text"
                id="first-name"
                width="70%"
                style={{ color: "black" }}
                value={searchBlog}
                background="white"
                onChange={handleSearchBlog}
                placeholder="Search for Blog"
              />
            </div>
            <Link to="/admin/addBlog">
              <div className="btn">
                <CustomButton width="142px" padding="12px 16px">
                  Add New Blog
                </CustomButton>
              </div>
            </Link>
          </div>
        </DashboardTop>
        {loading ? (
          <JobLoader />
        ) : (
          <>
            <div className="job-grid">
              <TableWrapper>
                <table className="job-table">
                  <thead>
                    <tr>
                      <th>
                        <span>Blog Title</span>
                      </th>
                      <th>
                        <span>Blog description</span>
                      </th>
                      <th>
                        <span>Action</span>
                      </th>
                    </tr>
                  </thead>

                  {blogs?.length === 0 && (
                    <div>There are currently no blog available</div>
                  )}
                  {latestData?.length === 0 && blogs?.length ? (
                    <Empty>
                      There are currently no blogs available for your search
                    </Empty>
                  ) : (
                    getData(currents, sizes)?.map((item, index) => {
                      const regex = /(<([^>]+)>)/gi;
                      const description = item?.description;
                      return (
                        <tbody>
                          <tr key={index} className="alternate">
                            <td>
                              <span>
                                <img
                                  src={item?.photo?.url}
                                  alt="item"
                                  width={50}
                                />
                              </span>
                              <span>{item?.title}</span>
                            </td>
                            <td>
                              <span>{`${description
                                .replace(regex, "")
                                .substring(0, 85)}........ `}</span>
                            </td>
                            <td>
                              <div className="flex">
                                {user === "super-admin" && (
                                  <>
                                    <Link to={`${item?.id}`}>
                                      <img src={edit} alt="edit" />
                                    </Link>

                                    <NiyoNotificationModal
                                      Content={DeleteContent}
                                      Title={DeleteTitle}
                                      id={item?.id}
                                      nav={deleteBlog}
                                      url={"blog"}
                                    >
                                      <img src={deleteIcon} alt="deleteIcon" />
                                    </NiyoNotificationModal>

                                    <NiyoNotificationModal
                                      Content={PublishContent}
                                      Title={PublishTitle}
                                      id={item?.id}
                                      nav={publishBlog}
                                      url={"blog"}
                                    >
                                      <img src={lock} alt="lockIcon" />
                                    </NiyoNotificationModal>
                                  </>
                                )}
                                {user === "manager" && (
                                  <>
                                    <Link to={`${item?.id}`}>
                                      <img src={edit} alt="edit" />
                                    </Link>

                                    <NiyoNotificationModal
                                      Content={DeleteContent}
                                      Title={DeleteTitle}
                                      id={item?.id}
                                      nav={deleteBlog}
                                      url={"blog"}
                                    >
                                      <img src={deleteIcon} alt="deleteIcon" />
                                    </NiyoNotificationModal>
                                  </>
                                )}
                                {user === "admin" && (
                                  <div className="flex">
                                    <Tooltip
                                      placement="topLeft"
                                      title={editText}
                                    >
                                      <img
                                        src={edit}
                                        alt="edit"
                                        className="disabled"
                                      />
                                    </Tooltip>
                                    <NiyoNotificationModal
                                      Content={DeleteContent}
                                      Title={DeleteTitle}
                                      id={item?.id}
                                      nav={deleteBlog}
                                      url={"blog"}
                                    >
                                      <img src={deleteIcon} alt="deleteIcon" />
                                    </NiyoNotificationModal>
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      );
                    })
                  )}
                </table>
              </TableWrapper>
            </div>
            {latestData?.length > 0 && (
              <NiyoPagination
                perPage={page}
                data={filteredJob}
                setSizes={setSizes}
                setCurrents={setCurrents}
                sizes={sizes}
                currents={currents}
              />
            )}
          </>
        )}
      </TeamStyle>
      <ToastContainer />
    </EventStyle>
  );
};

export default Blog;
