import React from 'react'

export const NiyoIcons = () => {
  return (
    <>
      <svg
        width="28"
        height="28"
        viewBox="0 0 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_3123_10336)">
          <path
            d="M9.58594 4.79167C9.58594 4.28334 9.78787 3.79582 10.1473 3.43638C10.5068 3.07693 10.9943 2.875 11.5026 2.875C12.0109 2.875 12.4984 3.07693 12.8579 3.43638C13.2173 3.79582 13.4193 4.28334 13.4193 4.79167C14.5198 5.31207 15.458 6.12215 16.1333 7.13508C16.8086 8.14801 17.1955 9.32561 17.2526 10.5417V13.4167C17.3247 14.0125 17.5357 14.583 17.8686 15.0824C18.2015 15.5817 18.647 15.9959 19.1693 16.2917H3.83594C4.35817 15.9959 4.80367 15.5817 5.13658 15.0824C5.46948 14.583 5.68048 14.0125 5.7526 13.4167V10.5417C5.80968 9.32561 6.1966 8.14801 6.87189 7.13508C7.54718 6.12215 8.48538 5.31207 9.58594 4.79167"
            stroke="black"
            stroke-width="1.45"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8.625 16.2917V17.2501C8.625 18.0126 8.9279 18.7438 9.46707 19.283C10.0062 19.8222 10.7375 20.1251 11.5 20.1251C12.2625 20.1251 12.9938 19.8222 13.5329 19.283C14.0721 18.7438 14.375 18.0126 14.375 17.2501V16.2917"
            stroke="black"
            stroke-width="1.45"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_3123_10336">
            <rect width="23" height="23" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  )
}

export const NiyoEmptyIcons = () => {
  return (
    <>
      <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.58594 2.79167C6.58594 2.28334 6.78787 1.79582 7.14732 1.43638C7.50676 1.07693 7.99427 0.875 8.5026 0.875C9.01094 0.875 9.49845 1.07693 9.85789 1.43638C10.2173 1.79582 10.4193 2.28334 10.4193 2.79167C11.5198 3.31207 12.458 4.12215 13.1333 5.13508C13.8086 6.14801 14.1955 7.32561 14.2526 8.54167V11.4167C14.3247 12.0125 14.5357 12.583 14.8686 13.0824C15.2015 13.5817 15.647 13.9959 16.1693 14.2917H0.835938C1.35817 13.9959 1.80367 13.5817 2.13658 13.0824C2.46948 12.583 2.68048 12.0125 2.7526 11.4167V8.54167C2.80968 7.32561 3.1966 6.14801 3.87189 5.13508C4.54718 4.12215 5.48538 3.31207 6.58594 2.79167"
          stroke="black"
          stroke-width="1.45"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  )
}

// export default NiyoIcons
