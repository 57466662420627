import React, { useState } from 'react'
import styled from 'styled-components'
import { ToastContainer } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { updateAdminPassword } from '../api/admin'
import { CustomButton, InputField } from '../styles/styledComponent'

export const PasswordTitle = () => {
  return (
    <Title>
      <p>Change Password</p>
    </Title>
  )
}

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  p:first-child {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
  }
`

export const PasswordContent = ({ id, hide }) => {
  const dispatch = useDispatch()
  const [newPassword, setNewPassword] = useState('')

  const handleSubmitPassword = (e) => {
    e.preventDefault()
    dispatch(updateAdminPassword({ id, password: newPassword }))
    hide()
  }

  return (
    <Wrapper>
      <form>
        <div>
          <label>New Password</label>
          <InputField
             style={{ color: 'black' }}
            type="text"
            value={newPassword}
            placeholder="Mynewemail.com"
            onChange={({ target }) => {
              setNewPassword(target.value)
            }}
          />

          <CustomButton
             onClick={handleSubmitPassword}
            padding="5px 8px"
            Radius="4px"
            className={`${
             newPassword === '' ? 'disabled' : ''
            }`}
          >
            Submit
          </CustomButton>
        </div>
      </form>
      <ToastContainer />
    </Wrapper>
  )
}
export const Wrapper = styled.div`
  width: 500px;
  button {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  .disabled {
    pointer-events: none;
  }
`
