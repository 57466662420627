import { createSlice } from '@reduxjs/toolkit'
import {
  admin,
  getAdmin,
  createAdmin,
  deleteAdmin,
  updateAdmin,
  updateAdminEmail,
  updateAdminPassword,
} from '../../api/admin'

const initialState = {
  loading: false,
  error: null,
  success: false,
  adminData: null,
  emailError: null,
  passwordError: null,
  data: null,
  searchedAdmin: '',
  sortAdmin: '',
}

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    sortBySearch: (state, action) => {
      const payload = action.payload
      state.searchedAdmin = payload
    },
    sortByRoles: (state, action) => {
      const payload = action.payload
      state.sortAdmin = payload
    },
  },
  extraReducers: {
    [admin.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [admin.fulfilled]: (state, action) => {
      state.loading = false
      state.adminData = action.payload
    },
    [admin.rejected]: (state) => {
      state.loading = false
      state.error = 'BadNetwork'
    },
    [createAdmin.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [createAdmin.fulfilled]: (state, action) => {
      state.loading = false
      state.data = action.payload
      state.error = 'null'
    },
    [createAdmin.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [getAdmin.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [getAdmin.fulfilled]: (state, action) => {
      state.loading = false
      state.data = action.payload
      state.error = null
    },
    [getAdmin.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [updateAdmin.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [updateAdmin.fulfilled]: (state) => {
      state.loading = false
      state.error = 'updateAdmin'
    },
    [updateAdmin.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [updateAdminEmail.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [updateAdminEmail.fulfilled]: (state) => {
      state.loading = false
      state.emailError = 'updateAdminEmail'
      state.error = 'Successfully Updated Admin Email'
    },
    [updateAdminEmail.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
      state.emailError = 'failedAdminEmail'
    },
    [updateAdminPassword.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [updateAdminPassword.fulfilled]: (state) => {
      state.loading = false
      state.passwordError = 'updateAdminPassword'
      state.error = 'Successfully Updated Admin Password'
    },
    [updateAdminPassword.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
      state.passwordError = 'failedAdminPassword'
    },
    [deleteAdmin.pending]: (state) => {
      state.loading = true
      state.passwordError = true
      state.error = null
    },
    [deleteAdmin.fulfilled]: (state) => {
      state.loading = false
      state.error = 'deleteAdmin'
    },
    [deleteAdmin.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
  },
})
export default adminSlice.reducer

export const { sortBySearch } = adminSlice.actions
export const { sortByRoles } = adminSlice.actions
