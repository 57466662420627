import styled from 'styled-components'

export const AdminStyle = styled.div`
  padding: 44px 37px;
`

export const AdminTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  button {
    font-size: 12px;
    line-height: 16px;
  }
  a {
    text-decoration: none;
  }
`

export const InputDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 32px 0;

  > div {
    display: flex;
    align-items: center;
    background: white;
    padding-left: 20px;
    margin-right: 20px;
    width: 50%;
    img {
      margin-top: 7px;
    }
  }
  input:nth-child(3),
  select {
    padding: 12px 16px;
    border: 1px solid #eaebeb;
    color: #727878;
    img {
      padding-right: 7px;
    }
  }
  input:nth-child(3),
  select {
    width: 12%;
    padding: 19px 16px;
    // border: 3px solid red;
  }
`

export const AdminSelect = styled.div`
  > div:nth-child(1) {
    background-color: black;
    color: white;
  }
`

export const TableWrapper = styled.div`
  overflow-x: auto;
  .admin-table {
    width: 100%;
  }
  .admin-table thead th {
    background-color: black;
    color: white;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
  input {
    cursor: pointer;
  }
  .admin-table tbody td {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    border: 0.5px solid #d5d7d7;
    color: #070909;
    padding: 6px 10px 6px 20px;
    &:nth-child(2) {
      span {
        text-align: center;
        max-width: 363px;
        white-space: normal !important;
        word-wrap: break all;
      }
    }
    .flex {
      display: flex;
    }
    .flex img:first-child {
      margin-right: 12px;
    }
    .flex img:last-child {
      margin-right: 6px;
    }
  }
  .admin-table tbody {
    &:nth-child(odd) {
      background: #eaebeb;
    }
  }
  .admin-table td,
  .admin-table th {
    padding: 18px 10px 18px 20px;

    span {
      display: flex;
      justify-content: flex-start;
      text-transform: capitalize;
    }
  }
  .admin-table th {
  }

  .admin-image {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }

  h3 {
    font-size: 15px;
    font-weight: 800;
    line-height: 20px;
    display: block;
    margin-bottom: 9px;
  }

  h4 {
    font-size: 15px;
  }

  .item {
    display: flex;
    align-items: center;
    @media (max-width: 900px) {
      width: 100%;
      display: block;
    }
  }

  .admin-table > thead > tr > th > span {
    white-space: nowrap;
  }
`
export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  align-items: center;
  overflow-x: scroll;
  input,
  select {
    padding: 14px 18px 14px 19px;
    border: 1px solid #eaebeb;
    color: #727878;
  }

  > div {
    display: flex;
    justify-content: center;
    text-align: center;
    gap: 20px;
    input {
      text-align: center;
    }
  }

  div:nth-child(1) {
    text-align: center;
    justify-content: center;
    width: 100%;
    input {
      text-align: center;
      background: #ffffff;
      border: 0.5px solid #a0a6a6;
      border-radius: 4px;
      width: 16px;
      height: 16px;
    }
  }

  p {
    grid-column: span 2;
    border-left: 1px solid #d5d7d7;
    font-size: 16px;
    margin: 0;
    padding: 14px 20px;
  }
`
