import React, { useState, useEffect } from 'react'
import NiyoPagination from '../../../components/pagination'
import NiyoNotificationModal from '../../../components/modal'
import { DeleteContent, DeleteTitle } from '../../../constants/deleteContetnt'
import { Link } from 'react-router-dom'
import { Tooltip } from 'antd'
import { useNavigate } from 'react-router-dom'
import { deleteEvent, events } from '../../../api/events'
import { useDispatch, useSelector } from 'react-redux'
import { deleteIcon, edit } from '../../../constants'
import { ToastContainer, toast } from 'react-toastify'
import dayjs from 'dayjs'
import { TableWrapper } from '../../../styles/adminStyles/AdminEvent.Styled'
import EventLoader from '../loaders/eventLoader'
import { BadNetwork } from '../../NotFound'
import { Empty } from '../../../styles/adminStyles/AdminTeam.Styled'

const PastEvents = () => {
  const page = 5

  const { loading, event, error, searchedEvent } = useSelector(
    (state) => state?.events,
  )

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const user = localStorage.getItem('userRole')
  const editText = 'You dont have access to update'

  useEffect(() => {
    async function fetchData() {
      const status = 'past'
      await dispatch(events(status))
    }
    fetchData()
  }, [dispatch])

  useEffect(() => {
    if (error === 'updateEvents') {
      navigate(0)
    }
  }, [error, navigate])

  useEffect(() => {
    if (error === 'deleteEvent') {
      toast.success('Successfully Deleted Event member', {
        position: toast.POSITION.TOP_CENTER,
      })
      navigate(0)
    }
  }, [error, navigate])

  const [sizes, setSizes] = useState(page)
  const [currents, setCurrents] = useState(1)

  const filteredEvents = event?.filter((event) => {
    return (
      event.title?.toLowerCase().indexOf(searchedEvent?.toLowerCase()) !== -1
    )
  })

  const latestData =
    filteredEvents &&
    filteredEvents
      .map((obj) => {
        return { ...obj, date: new Date(obj?.updatedAt) }
      })
      .sort((a, b) => b?.date - a?.date)

  const getData = (current, pageSize) => {
    return latestData?.slice((current - 1) * pageSize, current * pageSize)
  }
  if (error === 'BadNetwork') return <BadNetwork />
  return (
    <div>
      {loading ? (
        <EventLoader />
      ) : (
        <>
          <div className="event-grid">
            <TableWrapper>
              <table className="event-table">
                <thead>
                  <tr>
                    <th>
                      <span>Event Titile</span>
                    </th>
                    <th>
                      <span>Event Date</span>
                    </th>
                    <th>
                      <span>Event Time</span>
                    </th>
                    <th>
                      <span>Action</span>
                    </th>
                  </tr>
                </thead>

                {event?.length === 0 && (
                  <div>There are currently no past events available</div>
                  )}
                  
                {(latestData?.length === 0 && event?.length) ? (
                  <Empty>There are currently no past event available for your search</Empty>
                ) : (
                  getData(currents, sizes).map((item, index) => (
                    <tbody>
                      <tr key={index} className="alternate">
                        <td>
                          <div className="flex">
                            <div className="imgWrapper">
                              <img src={item?.coverPhoto?.url} alt="item" />
                            </div>
                            <p>{item?.title}</p>
                          </div>
                        </td>
                        <td>
                          <span>
                            {dayjs(item?.eventDate).format('MMMM DD, YYYY')}
                          </span>
                        </td>
                        <td>
                          <span>{item?.eventTime}</span>
                        </td>

                        <td>
                          <div className="flexs">
                            {user === 'super-admin' && (
                              <>
                                <Link to={`${item?.id}`}>
                                  <img src={edit} alt="edit" />
                                </Link>
                                <NiyoNotificationModal
                                  Content={DeleteContent}
                                  Title={DeleteTitle}
                                  id={item?.id}
                                  nav={deleteEvent}
                                  url={'event'}
                                >
                                  <img src={deleteIcon} alt="deleteIcon" />
                                </NiyoNotificationModal>
                              </>
                            )}
                            {user === 'manager' && (
                              <>
                                <Link to={`${item?.id}`}>
                                  <img src={edit} alt="edit" />
                                </Link>
                                <NiyoNotificationModal
                                  Content={DeleteContent}
                                  Title={DeleteTitle}
                                  id={item?.id}
                                  nav={deleteEvent}
                                  url={'event'}
                                >
                                  <img src={deleteIcon} alt="deleteIcon" />
                                </NiyoNotificationModal>
                              </>
                            )}
                            {user === 'admin' && (
                              <div className="flex">
                                <Tooltip placement="topLeft" title={editText}>
                                  <img
                                    src={edit}
                                    alt="edit"
                                    className="disabled"
                                  />
                                </Tooltip>
                                <NiyoNotificationModal
                                  Content={DeleteContent}
                                  Title={DeleteTitle}
                                  id={item?.id}
                                  nav={deleteEvent}
                                  url={'event'}
                                >
                                  <img src={deleteIcon} alt="deleteIcon" />
                                </NiyoNotificationModal>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  ))
                )}
              </table>
              <ToastContainer />
            </TableWrapper>
          </div>

          {latestData?.length > 0 && (
            <NiyoPagination
              perPage={page}
              data={event}
              setSizes={setSizes}
              setCurrents={setCurrents}
              sizes={sizes}
              currents={currents}
            />
          )}
        </>
      )}
    </div>
  )
}

export default PastEvents
