import styled from "styled-components";

export const TableWrapper = styled.div`
  overflow-x: auto;
  .job-table {
    width: 100%;
  }
  .job-table thead th {
    background-color: black;
      color: white;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }

  .job-table tbody td {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 25px;
    border: 0.5px solid #D5D7D7;
    color: #070909;
    &:nth-child(2) {
      span {
        text-align: center;
          max-width: 363px;
          white-space: normal !important;
          word-wrap: break all;
      }
    }
    .flex {
      display: flex;
      align-items: center;
      > img {
        margin-right: -56px;
        margin-top: -7px;
      }
    }
    .flex img:first-child {
      margin-right: -56px;
      cursor: pointer;
    }
   
  }
  .job-table tbody {
    &:nth-child(odd) {
      background: #EAEBEB;
    }
  }
  .job-table td,
  .job-table th {
    padding: 18px 10px 18px 20px;

    span {
      display: flex;
      justify-content: flex-start;
      text-transform: capitalize;
    }
  }
  .job-table th {
  }

  .job-image {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }

  h3 {
    font-size: 15px;
    font-weight: 800;
    line-height: 20px;
    display: block;
    margin-bottom: 9px;
  }

  h4 {
    font-size: 15px;
  }

  .item {
    display: flex;
    align-items: center;
    @media (max-width: 900px) {
      width: 100%;
      display: block;
    }
  }

  .job-table > thead > tr > th > span {
    white-space: nowrap;
  }
`;