import styled from "styled-components";

export const AdminLoginStyle = styled.div`
  display: flex;
  width: 100%;

  > div:nth-child(1) {
    height: 100vh;
    padding: 45px 42px;
    background-color: var(--black);
    width: 40%;
    color: #fff;
    h3{
      margin-top: 54px;
    }
  }

  > div:nth-child(2) {
    width: 60%;
    padding: 55px 45px 55px 108px;
    h3 {
      margin-top: 40px;
      margin-bottom: 5px;
    }
    p {
      margin: 4px 0px 10px 0px;
    }
  }
`;

export const LoginForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 63%;
  margin-top: 47px;
  gap: 36px;
  input {
    background: var(--light-grey);
    font-size: 16px;
    line-height: 25px;
    margin-top: -32px;
  }
  button {
    font-size: 12px;
    line-height: 16px;
  }
  .disabled{
    pointer-events: none;
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:has(p) {
    justify-content: flex-start;
    margin-top: -30px;
    input {
      border-color: #a0a6a6;
    }
    p{
      margin-top: 10px !important;
    }
  }
`;
