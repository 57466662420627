import client from '../utils/client'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL } from './ config'


export const career = createAsyncThunk(
  'careers/fetchCareer',
  async (obj,{ rejectWithValue }) => {
    try {
     
      const { data: {data} } = await client.get(
        `${BASE_URL}/api/v1/careers`
      )
      return data
    } catch (error) {
      // return custom error message from API if any
      throw rejectWithValue(error.message)
    }
  }
)


export const createCareer = createAsyncThunk(
  'careers/createCareer',
  async ( form_data , { rejectWithValue }) => {
    try {
        const config = {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
      const { data:{ data } } = await client.post(
          `${BASE_URL}/api/v1/careers`,
          form_data ,
          config,
      )
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)


export const getCareer = createAsyncThunk(
  'careers/getCareer',
  async (id,{ rejectWithValue }) => {
    try {
     
      const { data: {data} } = await client.get(
        `${BASE_URL}/api/v1/careers/${id}`
      )
      return data
    } catch (error) {
      // return custom error message from API if any
      throw rejectWithValue(error.message)
    }
  }
)


export const updateCareer = createAsyncThunk(
  'careers/updateCareer',
  async ( { id, jobDescription, jobRole, companyName, jobType, jobLink, jobLocation, jobLevel, form_data } , { rejectWithValue }) => {
    try {
      if (jobDescription) {
        const { data:{ data } } = await client.patch(
          `${BASE_URL}/api/v1/careers/${id}`,
          { jobDescription }
      )
      return data
      }
      if (jobRole) {
        const { data:{ data } } = await client.patch(
          `${BASE_URL}/api/v1/careers/${id}`,
          { jobRole }
      )
      return data
      }
      if (companyName) {
        const { data:{ data } } = await client.patch(
          `${BASE_URL}/api/v1/careers/${id}`,
          { companyName }
      )
      return data
      }
      if (jobType) {
        const { data:{ data } } = await client.patch(
          `${BASE_URL}/api/v1/careers/${id}`,
          { jobType }
      )
      return data
      }
      if (jobLink) {
        const { data:{ data } } = await client.patch(
          `${BASE_URL}/api/v1/careers/${id}`,
          { jobLink }
      )
      return data
      }
        
      if (jobLocation) {
        const { data:{ data } } = await client.patch(
          `${BASE_URL}/api/v1/careers/${id}`,
          { jobLocation }
      )
      return data
      }
        
      if (jobLevel) {
        const { data:{ data } } = await client.patch(
          `${BASE_URL}/api/v1/careers/${id}`,
          { jobLevel }
      )
      return data
      }
      if (jobLink) {
        const { data:{ data } } = await client.patch(
          `${BASE_URL}/api/v1/careers/${id}`,
          { jobLink }
      )
      return data
      }
      if (form_data) {
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
        const { data:{ data } } = await client.patch(
          `${BASE_URL}/api/v1/careers/${id}`,
          form_data,
           config
      )
      return data
      }
        
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const deleteCareer = createAsyncThunk(
  'careers/deleteCareer',
  async (id,{ rejectWithValue }) => {
    try {
     
      const { data: {data} } = await client.delete(
        `${BASE_URL}/api/v1/careers/${id}`
      )
      return data
    } catch (error) {
      // return custom error message from API if any
      throw rejectWithValue(error.message)
    }
  }
)


