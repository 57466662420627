import client from '../utils/client'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL } from './ config'
import setToken from '../utils/setToken'

export const userLogin = createAsyncThunk(
  'api/v1/auth/admin',
  async ({ email, password }, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      const {
        data: { data },
      } = await client.post(
        `${BASE_URL}/api/v1/auth/admin`,
        { email, password },
        config,
      )
      // store user's token in local storage
      localStorage.setItem('userToken', data?.accessToken)
      localStorage.setItem('userRole', data?.adminRole)
      setToken(data?.accessToken)
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)
