import React, { useState, useEffect } from 'react'
import { DashboardTop } from '../../../styles/adminStyles/AdminDashBoard.Styled'
import {
  TeamList,
  TeamStyle,
  TableWrapper,
  Empty,
} from '../../../styles/adminStyles/AdminTeam.Styled'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { Tooltip } from 'antd'
import { deleteIcon, edit, search } from '../../../constants'
import { career, deleteCareer } from '../../../api/career'
import { ToastContainer, toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { CustomButton, InputField } from '../../../styles/styledComponent'
import NiyoPagination from '../../../components/pagination'
import NiyoNotificationModal from '../../../components/modal'
import { DeleteContent, DeleteTitle } from '../../../constants/deleteContetnt'
import TeamLoader from '../loaders/teamLoader'
import { BadNetwork } from '../../NotFound'
import { sortBySearch } from '../../../globalState/reducers/careerSlice'

const Career = () => {
  const page = 6
  const styles = {
    border: {
      borderBottom: '0.5px solid grey',
    },
  }

  const { loading, careers, error, searchedCareer } = useSelector(
    (state) => state?.career,
  )

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const user = localStorage.getItem('userRole')
  const editText = 'You dont have access to update'

  const filteredCareers = careers?.filter((career) => {
    return (
      career.jobRole?.toLowerCase().indexOf(searchedCareer?.toLowerCase()) !==
      -1
    )
  })

  useEffect(() => {
    async function fetchData() {
      await dispatch(career())
    }
    fetchData()
  }, [dispatch])

  useEffect(() => {
    if (error === 'deleteCareer') {
      toast.success('Successfully Deleted Career member', {
        position: toast.POSITION.TOP_CENTER,
      })
      navigate(0)
    }
  }, [error, navigate])

  useEffect(() => {
    if (error === 'null') {
      setTimeout(() => {
        toast.success('Successfully Added Career member', {
          position: toast.POSITION.TOP_CENTER,
        })
      }, 1000)
    }
  }, [error])

  useEffect(() => {
    if (error === 'updateTeam') {
      navigate(0)
    }
  }, [error, navigate])

  const [sizes, setSizes] = useState(page)
  const [currents, setCurrents] = useState(1)
  const [searchCareer, setSearchCareer] = useState('')

  const handleSearchCareer = (e) => {
    setSearchCareer(e.target.value)
    dispatch(sortBySearch(e.target.value))
  }

  useEffect(() => {
    setSearchCareer('')
    dispatch(sortBySearch(''))
  }, [dispatch])

  const latestData =
    filteredCareers &&
    filteredCareers
      .map((obj) => {
        return { ...obj, date: new Date(obj?.updatedAt) }
      })
      .sort((a, b) => b?.date - a?.date)

  const sortedData = latestData?.sort((a, b) => {
    const aIsCWA = a.companyName === 'CMA' || a.companyName === 'CMAS'
    const bIsCWA = b.companyName === 'CMA' || b.companyName === 'CMAS'

    if (aIsCWA && !bIsCWA) {
      return -1
    } else if (!aIsCWA && bIsCWA) {
      return 1
    } else {
      return 0
    }
  })
  const getData = (current, pageSize) => {
    return sortedData?.slice((current - 1) * pageSize, current * pageSize)
  }
  if (error === 'BadNetwork') return <BadNetwork />
  return (
    <TeamStyle>
      <DashboardTop>
        <h5>Careers</h5>
        <div className="team">
          <div>
            <div>
              <img src={search} alt="search-icon" />
            </div>
            <InputField
              type="text"
              id="first-name"
              width="70%"
              style={{ color: 'black' }}
              value={searchCareer}
              background="white"
              onChange={handleSearchCareer}
              placeholder="Search for Job Role"
            />
          </div>
          <Link to="/admin/addCareer">
            <div className="btn">
              <CustomButton width="142px" padding="12px 16px">
                Add New Career
              </CustomButton>
            </div>
          </Link>
        </div>
      </DashboardTop>

      {loading ? (
        <TeamLoader />
      ) : (
        <TeamList>
          <div className="subtitle2">All careers</div>
          <div>
            <TableWrapper>
              <table className="team-table">
                <thead>
                  <tr>
                    <th style={styles.border}>
                      <span>S/NO</span>
                    </th>
                    <th style={styles.border}>
                      <span>Job Role</span>
                    </th>
                    <th style={styles.border}>
                      <span>CompanyName</span>
                    </th>
                    <th style={styles.border}>
                      <span>Job Type</span>
                    </th>
                    <th style={styles.border}>
                      <span>Job Location</span>
                    </th>
                    <th style={styles.border}>
                      <span>Actions</span>
                    </th>
                  </tr>
                </thead>

                {careers?.length === 0 && (
                  <div>There are currently no careers available</div>
                )}
                {(filteredCareers?.length === 0 && careers?.length) ? (
                  <Empty>There are currently no match for your search</Empty>
                ) : (
                  getData(currents, sizes)?.map((item, index) => (
                    <tbody className="cursor__pointer">
                      <tr key={index}>
                        <td style={{ ...styles.border }}>{index + 1}</td>
                        <td style={styles.border}>
                          <div className="flex">
                            <div className="imgWrapper">
                              <img src={item?.companyLogo?.url} alt="item" />
                            </div>
                            <p>{item?.jobRole}</p>
                          </div>
                        </td>
                        <td style={styles.border}>
                          <span>{item?.companyName}</span>
                        </td>
                        <td style={styles.border}>
                          <span>{item?.jobType}</span>
                        </td>
                        <td style={styles.border}>
                          <span>{item?.jobLocation}</span>
                        </td>
                        <td style={styles.border}>
                          <div className="flexs">
                            {user === 'super-admin' && (
                              <>
                                <Link to={`${item?.id}`}>
                                  <img src={edit} alt="edit" />
                                </Link>
                                <NiyoNotificationModal
                                  Content={DeleteContent}
                                  Title={DeleteTitle}
                                  id={item?.id}
                                  nav={deleteCareer}
                                  url={'careers'}
                                >
                                  <img src={deleteIcon} alt="deleteIcon" />
                                </NiyoNotificationModal>
                              </>
                            )}
                            {user === 'manager' && (
                              <>
                                <Link to={`${item?.id}`}>
                                  <img src={edit} alt="edit" />
                                </Link>
                                <NiyoNotificationModal
                                  Content={DeleteContent}
                                  Title={DeleteTitle}
                                  id={item?.id}
                                  nav={deleteCareer}
                                  url={'careers'}
                                >
                                  <img src={deleteIcon} alt="deleteIcon" />
                                </NiyoNotificationModal>
                              </>
                            )}
                            {user === 'admin' && (
                              <>
                                <Tooltip placement="topLeft" title={editText}>
                                  <img
                                    src={edit}
                                    alt="edit"
                                    className="disabled"
                                  />
                                </Tooltip>
                                <NiyoNotificationModal
                                  Content={DeleteContent}
                                  Title={DeleteTitle}
                                  id={item?.id}
                                  nav={deleteCareer}
                                  url={'careers'}
                                >
                                  <img src={deleteIcon} alt="deleteIcon" />
                                </NiyoNotificationModal>
                              </>
                            )}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  ))
                )}
              </table>
              <ToastContainer />
            </TableWrapper>
            {careers?.length > 0 && (
              <NiyoPagination
                perPage={page}
                data={careers}
                setSizes={setSizes}
                setCurrents={setCurrents}
                sizes={sizes}
                currents={currents}
              />
            )}
          </div>
        </TeamList>
      )}
    </TeamStyle>
  )
}

export default Career
