import React from 'react'
import { Skeleton } from 'antd'
import styled from 'styled-components'

const eventLoader = () => {
  return (
    <>
      <CenterLoader>
        <div>
        <Skeleton
          paragraph={{
            rows: 0,
          }}
          style={{ height: '100px', padding: '40px' }}
          active={true}
          size="default"
        />
        <Skeleton
          paragraph={{
            rows: 0,
          }}
          style={{ height: '100px', padding: '40px' }}
          active={true}
          size="default"
        />
        <Skeleton
          paragraph={{
            rows: 0,
          }}
          style={{ padding: '40px' }}
          active={true}
          size="default"
        />
        <Skeleton
          paragraph={{
            rows: 0,
          }}
          style={{ height: '100px', padding: '40px' }}
          active={true}
          size="default"
        />
        <Skeleton
          paragraph={{
            rows: 0,
          }}
          style={{ height: '100px', padding: '40px' }}
          active={true}
          size="default"
        />
        <Skeleton
          paragraph={{
            rows: 0,
          }}
          style={{ padding: '40px' }}
          active={true}
          size="default"
        />
        </div>
        
      </CenterLoader>
      
    </>
  )
}
export default eventLoader

export const CenterLoader = styled.div`
margin-top: 130px;
width: 10px;
    .ant-skeleton-title {
        width: 1000px !important;
        height: 50px !important;
        margin-top:-120px !important;
    }
`