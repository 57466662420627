import styled from 'styled-components'

export const DashBoardStyle = styled.div`
  width: 100%;
  padding: 20px 32px;
`

export const DashboardTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  .notifcation {
    display: flex;
  }
  .notifcation {
    div:first-child {
      background: white;
      cursor: pointer;
      width: 55px;
      height: 55px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin-right: 23px;
    }
    div:last-child {
     
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width: 50px;
        height: 50px;
      }
    }
  }
  .msg {
    position: absolute;
  }
  a {
    text-decoration: none;
  }
`

export const DashboardFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .info {
    background-color: white;
    border: 1px solid #eaebeb;
    border-radius: 10px;
    width: 300px;
    max-height: 143px;
  }
  .content {
    width: 100%;
    padding: 5px 28px 33px;
    h4 {
      width: 100%;
      margin-top: -10px;
      white-space: normal !important;
    }
    > div {
      display: flex;
      justify-content: space-between;
    }
  }
`

export const DashboardForm = styled.div`
  form {
    background-color: white;
    padding: 29px 35px 78px;
    margin: 29px 0 34px;
    border-radius: 10px;
    .inputs {
      display: flex;
      flex-wrap: wrap;
      column-gap: 119px;
      row-gap: 66px;
      div {
        width: 34%;
        label {
          color: #070909;
          display: block;
          font-size: 16px;
          font-weight: 400;
        }
        input {
          font-size: 20px;
          line-height: 25px;
          margin: 10px 0;
          padding: 14px 14px;
          border: 1px solid #d5d7d7;
          border-radius: 5px;
          color: black;
          &::placeholder {
            font-size: 20px;
          }
        }
      }
    }
  }
  button {
    font-size: 12px;
    line-height: 16px;
  }
  .disabled{
    pointer-events: none;
  }
`

export const DashboardInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0 34px;
  width: 90%;
`
