import { createSlice } from '@reduxjs/toolkit'
import { notification, updateNotification } from '../../api/notification'

const initialState = {
  loading: false,
  error: null,
  success: false,
  data: null,
}

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {},
  extraReducers: {
    [notification.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [notification.fulfilled]: (state, action) => {
      state.loading = false
      state.data = action.payload
    },
    [notification.rejected]: (state) => {
      state.loading = false
      state.error = 'BadNetwork'
    },
    [updateNotification.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [updateNotification.fulfilled]: (state) => {
      state.loading = false
    },
    [updateNotification.rejected]: (state) => {
      state.loading = false
      state.error = 'BadNetwork'
    },
  },
})
export default notificationSlice.reducer
