import React, { useState, useEffect } from 'react'
import {
  AddTeamForm,
  AddTeamStyle,
  AddTeamTop,
} from '../../../styles/adminStyles/AdminAddTeam.Styled'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import Loading from '../../../assets/icons/loading.gif'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import { createJob, department } from '../../../api/job'
import { useNavigate } from 'react-router-dom'
import ArrowIcon from '../../../assets/icons/arrow-icon.svg'
import { AddAdminStyle } from '../../../styles/adminStyles/AdminAdd.Styled'
import { Link } from 'react-router-dom'
import { CustomButton, InputField, SelectField } from '../../../styles/styledComponent'

const AddJobs = () => {
  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      // [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link', 'image', 'video'],
    ],
  }
  const navigate = useNavigate()
  const { error,loading, departmentList } = useSelector((state) => state.job)

  const dispatch = useDispatch()

  useEffect(() => {
    async function fetchData() {
       await dispatch(department())
    }
    fetchData();
   
  }, [dispatch])

  useEffect(() => {
    if (error === 'null') {
      navigate('/admin/job')
    }
  }, [error, navigate])

  useEffect(() => {
    if (error !== null && error !== 'null' && error !== 'department') {
      toast.error(error, {
        position: toast.POSITION.TOP_CENTER
      });
    }
  }, [error])

  const [details, setDetails] = useState('')
  const [title, setTitle] = useState('')
  const [jobDepartment, setJobDepartment] = useState('')


  const handleSubmit = async (e) => {
    e.preventDefault()
    const description = details;
    const department = jobDepartment;
    dispatch(createJob({ title, description, department }))
      
  }
  const handleTitle = (e) => {
    setTitle(e.target.value)
  }

  const handleDepartment = (e) => {
    setJobDepartment(e.target.value)
  }
  return (
    <AddAdminStyle>
      <AddTeamStyle>
        <Link to="/admin/job">
          <AddTeamTop>
            <img src={ArrowIcon} alt="Arrow Icon" /> <h5>Post a job</h5>
          </AddTeamTop>
        </Link>

        <AddTeamForm>
          <form onSubmit={handleSubmit}>
            <div className="inputsField">
              <div>
                <label>Job Title </label>
                <InputField type="text" value={title} style={{ color: 'black' }}
                  onChange={handleTitle} placeholder="Growth Marketer" />
              </div>
              <div>
                <div>Department</div>
                <SelectField name="" value={jobDepartment}
                  onChange={handleDepartment}>
                <option value='' disabled>Select Department</option>
                  {
                    departmentList?.map(department => (

                      <option value={department?.id}>{department?.name}</option>
                    ))
                  }
                </SelectField>
              </div>
              <label>Job details</label>
              <ReactQuill
                theme="snow"
                value={details}
                onChange={setDetails}
                className="editor-input"
                modules={modules}
              />

              {/* <div dangerouslySetInnerHTML={{ __html: value }}/> */}
            </div>
            <CustomButton
              padding="12px 16px"
              margin="0px"
              marginL="auto"
              display="block"
              className={`${title === '' || title === '' || jobDepartment === null  || details === null ? 'disabled' : '' }`}>{loading === 'loading' ? <img src={Loading} alt="loader" width="30%" height="30%"/> : 'Save Changes' }
            </CustomButton>
          </form>
        </AddTeamForm>
        <ToastContainer />
      </AddTeamStyle>
    </AddAdminStyle>
  )
}

export default AddJobs
