import React from 'react'
import styled from 'styled-components'

export const NotFound = () => {
  return (
    <div>NotFound</div>
  )
}
export default NotFound;

export const BadNetwork = () => {
  return (
    <Wrapper>
      <div>
      <h1>Permission or Network error</h1>
        <p>Oops! you are currently have issues on this page.</p>
        <p>Pls check your internet service provider or contact admin.</p>
      </div>
    </Wrapper>
  )
}


export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  p{
    text-align: center;
  }
`
