/* -------------------------------------------------------------------------- */
/*                            External Dependencies                           */
/* -------------------------------------------------------------------------- */
import React from 'react';
import Pagination from 'rc-pagination'

/* -------------------------- Pagination PropTypes -------------------------- */

const NiyoPagination = ({
  data,
  perPage,
    setSizes,
    setCurrents,
    sizes,
    currents,
}) => {

    const PerPageChange = (value) => {
      setSizes(value)
      const newPerPage = Math.ceil(data.length / value)
      if (currents > newPerPage) {
        setCurrents(newPerPage)
      }
    }
  
    const PaginationChange = (page, pageSize) => {
      setCurrents(page)
      setSizes(pageSize)
    }
  
    const PrevNextArrow = (current, type, originalElement) => {
      if (type === 'prev') {
        return (
          <div>
            <svg
              width="18"
              height="19"
              viewBox="0 0 18 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M9 14L4.5 9.5L9 5" stroke="#262626" stroke-width="2" />
              <path d="M13.5 14L9 9.5L13.5 5" stroke="#262626" stroke-width="2" />
            </svg>
          </div>
        )
      }
      if (type === 'next') {
        return (
          <div>
            <svg
              width="18"
              height="19"
              viewBox="0 0 18 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M9 14L13.5 9.5L9 5" stroke="#262626" stroke-width="2" />
              <path d="M4.5 14L9 9.5L4.5 5" stroke="#262626" stroke-width="2" />
            </svg>
          </div>
        )
      }
      return originalElement
    }
  return (
    <Pagination
        className="pagination-data"
      showTotal={(total) => {
        return (
          `Showing ${currents} of ${Math.ceil(total/sizes)} entries`
      )
        }
        }
        onChange={PaginationChange}
        total={data.length}
        current={currents}
        pageSize={sizes}
        showSizeChanger={false}
        itemRender={PrevNextArrow}
        onShowSizeChange={PerPageChange}
      />
  )
};

export default NiyoPagination;
