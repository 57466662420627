import React, { useState, useEffect } from "react";
import {
  AddTeamForm,
  AddTeamStyle,
  AddTeamTop,
} from "../../../styles/adminStyles/AdminAddTeam.Styled";
import ArrowIcon from "../../../assets/icons/arrow-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { getTeam, updateTeam } from "../../../api/team";
import { useParams } from "react-router-dom";
import Loading from "../../../assets/icons/loading.gif";
import { ToastContainer, toast } from "react-toastify";
import AttachIcon from "../../../assets/icons/attach-icon.svg";
import { useNavigate } from "react-router-dom";
import DropFileIcon from "../../../assets/icons/dropFile-icon.svg";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import {
  CustomButton,
  InputField,
  TextareaField,
} from "../../../styles/styledComponent";
import JobLoader from "../loaders/jobLoader";

const UpdateTeam = () => {
  const { error, loading, data } = useSelector((state) => state.team);
  const navigate = useNavigate();

  let { id } = useParams();

  const dispatch = useDispatch();

  const [selectedFile, setSelectedFile] = useState(null);
  const [dropFile, setDropFile] = useState(null);

  const [fullName, setFullName] = useState(data?.fullName);
  const [fullNameChange, setFullNameChange] = useState(false);

  const [jobRole, setJobRole] = useState(data?.jobRole);
  const [jobRoleChange, setJobRoleChange] = useState(false);

  const [funFacts, setFunFacts] = useState(data?.funFacts);
  const [funFactsChange, setFunFactsChange] = useState(false);

  const [socialUrl, setSocialUrl] = useState(data?.socialUrl);
  const [socialUrlChange, setSocialUrlChange] = useState(false);

  useEffect(() => {
    async function fetchData() {
      await dispatch(getTeam(id));
    }
    fetchData();
  }, [dispatch, id]);

  const handleDataState = (file) => {
    setSelectedFile(file);
  };
  const handleFileUpload = (event) => {
    handleDataState(event.target.files[0]);
  };

  const handleFileDrop = (file) => {
    handleDataState(file);
    setDropFile(file);
  };

  useEffect(() => {
    if (
      error !== null &&
      error !== "updateTeam" &&
      error !== "getTeam" &&
      error !== "deleteTeam" &&
      error !== "BadNetwork"
    ) {
      toast.error(error, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }, [error]);
  useEffect(() => {
    if (error === "updateTeam") {
      navigate("/admin/team");
    }
  }, [error, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const photo = selectedFile || dropFile;
    let form_data = new FormData();

    if (photo) {
      form_data.append("photo", photo, photo?.path);
      if (fullName && data?.fullName !== fullName) {
        form_data.append("fullName", fullName);
        dispatch(updateTeam({ id, form_data }));
      }
      if (jobRole && data?.jobRole !== jobRole) {
        form_data.append("jobRole", jobRole);
        dispatch(updateTeam({ id, form_data }));
      }
      if (funFacts && data?.funFacts !== funFacts) {
        form_data.append("funFacts", funFacts);
        dispatch(updateTeam({ id, form_data }));
      }
      if (socialUrl && data?.socialUrl !== socialUrl) {
        form_data.append("socialUrl", socialUrl);
        dispatch(updateTeam({ id, form_data }));
      }
      if (photo) {
        dispatch(updateTeam({ id, form_data }));
      }
    } else {
      if (fullName && data?.fullName !== fullName) {
        form_data.append("fullName", fullName);
        dispatch(updateTeam({ id, fullName }));
      }
      if (jobRole && data?.jobRole !== jobRole) {
        form_data.append("jobRole", jobRole);
        dispatch(updateTeam({ id, jobRole }));
      }
      if (funFacts && data?.funFacts !== funFacts) {
        form_data.append("funFacts", funFacts);
        dispatch(updateTeam({ id, funFacts }));
      }
      if (socialUrl && data?.socialUrl !== socialUrl) {
        form_data.append("socialUrl", socialUrl);
        dispatch(updateTeam({ id, socialUrl }));
      }
    }
  };

  const handleName = (e) => {
    setFullNameChange(true);
    setFullName(e.target.value);
  };
  const handleRole = (e) => {
    setJobRoleChange(true);
    setJobRole(e.target.value);
  };
  const handleFact = (e) => {
    setFunFactsChange(true);
    setFunFacts(e.target.value);
  };
  const handleSocial = (e) => {
    setSocialUrlChange(true);
    setSocialUrl(e.target.value);
  };
  if (loading) return <JobLoader />;
  return (
    <AddTeamStyle>
      <Link to="/admin/team">
        <AddTeamTop>
          <img src={ArrowIcon} alt="Arrow Icon" /> <h5>UpdateTeam</h5>
        </AddTeamTop>
      </Link>

      <AddTeamForm>
        <form onSubmit={handleSubmit}>
          <div className="inputs">
            <div>
              <label>Name </label>
              <InputField
                value={fullNameChange ? fullName : data?.fullName}
                onChange={handleName}
                type="text"
                placeholder="Oyinkansola Aebayo"
              />
            </div>
            <div>
              <label>Role</label>
              <InputField
                value={jobRoleChange ? jobRole : data?.jobRole}
                onChange={handleRole}
                type="text"
                placeholder="CEO & Founder"
              />
            </div>
            <div>
              <label>Funfact</label>
              <TextareaField
                value={funFactsChange ? funFacts : data?.funFacts}
                onChange={handleFact}
                placeholder="I love food"
              ></TextareaField>
            </div>
            <div>
              <label>Update Profile image</label>
              <Dropzone onDrop={(files) => handleFileDrop(files[0])}>
                {({ getRootProps, getInputProps }) => (
                  <div className="btn" {...getRootProps()}>
                    <input {...getInputProps()} onChange={handleFileUpload} />
                    <div>
                      {selectedFile !== null ? (
                        <p>Image Uploaded</p>
                      ) : (
                        <>
                          <img src={AttachIcon} alt="attach icon" />
                          <i>Attach file</i>
                        </>
                      )}
                    </div>
                    <p>or</p>
                    <div>
                      {dropFile !== null ? (
                        <p>Image Uploaded</p>
                      ) : (
                        <>
                          <img src={DropFileIcon} alt="Drop File Icon" />
                          <i>Drop file here</i>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </Dropzone>
            </div>
            <div>
              <label>Linkedln Social </label>
              <InputField
                value={socialUrlChange ? socialUrl : data?.socialUrl}
                onChange={handleSocial}
                type="url"
                required
                placeholder="linkedln.com/in/oyinkansola adebayo"
              />
            </div>
          </div>
          <CustomButton padding="12px 16px">
            {loading ? (
              <img src={Loading} alt="loader" width="30%" height="30%" />
            ) : (
              "Save Changes"
            )}
          </CustomButton>
        </form>
      </AddTeamForm>
      <ToastContainer />
    </AddTeamStyle>
  );
};

export default UpdateTeam;
