import { Badge } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { dashboard, updateDashboard } from '../../api/dashboard'
import { notification } from '../../api/notification'
import chart from '../../assets/icons/chart.svg'
import dots from '../../assets/icons/dashboardDot-icon.svg'
import heartIcon from '../../assets/icons/heart-icon.svg'
import Loading from '../../assets/icons/loading.gif'
import { NiyoIcons } from '../../components/icons'
import NiyoNotificationModal from '../../components/modal'
import { profile } from '../../constants'
import {
  NotificationContent,
  NotificationTitle
} from '../../constants/NotificationContent'
import {
  DashboardFlex,
  DashboardForm,
  DashboardInfo,
  DashBoardStyle,
  DashboardTop
} from '../../styles/adminStyles/AdminDashBoard.Styled'
import { commaFormatted } from '../../utils/format'
import DashboardLoader from './loaders/dashboardLoader'
import { CustomButton, InputField } from '../../styles/styledComponent'

import { BadNetwork } from '../NotFound'

const Dashboard = () => {
  const { loading, data, error } = useSelector((state) => state.dashboard)
  const { data: notifications } = useSelector((state) => state.notification)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [partnersCounts, setPartnersCounts] = useState('')
  const [upskillCounts, setUpskillCounts] = useState('')
  const [communityMember, setCommunityMember] = useState('')
  const [onSubmit, setOnsubmit] = useState(false)

  useEffect(() => {
    async function fetchData() {
      await dispatch(dashboard())
      await dispatch(notification())
    }
    fetchData()
  }, [dispatch])

  useEffect(() => {
    if (onSubmit === true) {
      const refreshPage = () => {
        navigate(0)
      }
      refreshPage()
    }
  }, [onSubmit, navigate])

  const dashboardInfo = [
    {
      no: commaFormatted(`${data?.partnersCount}+`),
      text: 'Partners we work with',
      icon: heartIcon,
    },
    {
      no: commaFormatted(`${data?.communityMembers}+`),
      text: 'Community Members',
      icon: dots,
    },
    {
      no: commaFormatted(`${data?.upskillCount}+`),
      text: 'Upskilled through Niyo Group',
      icon: chart,
    },
  ]

  const handleSubmit = async (e) => {
    e.preventDefault()

    const partnersCount = parseInt(partnersCounts)
    const upskillCount = parseInt(upskillCounts)
    const communityMembers = parseInt(communityMember)
    dispatch(updateDashboard({ partnersCount, upskillCount, communityMembers }))
    setTimeout(() => {
      setOnsubmit(true)
    }, 1000)
    setPartnersCounts('')
    setCommunityMember('')
    setUpskillCounts('')
  }
  const handlePartnersCount = (e) => {
    const result = e.target.value.replace(/\D/g, '')
    setPartnersCounts(result)
  }

  const handleCommunityMembers = (e) => {
    const result = e.target.value.replace(/\D/g, '')
    setCommunityMember(result)
  }

  const handleUpskillCount = (e) => {
    const result = e.target.value.replace(/\D/g, '')
    setUpskillCounts(result)
  }

  if (error === 'BadNetwork') return <BadNetwork />
  return (
    <>
      <DashBoardStyle>
        <DashboardTop>
          <h5>Niyo in numbers </h5>
          <div className="notifcation">
            {notifications?.length === 0 ? (
              <NiyoNotificationModal
                Content={NotificationContent}
                Title={NotificationTitle}
                disable={'none'}
              >
                <Badge size="small" count={notifications?.length}>
                  <NiyoIcons />
                </Badge>
              </NiyoNotificationModal>
            ) : (
              <NiyoNotificationModal
                Content={NotificationContent}
                Title={NotificationTitle}
                notifications={notifications}
              >
                <Badge size="small" count={notifications?.length}>
                  <NiyoIcons />
                </Badge>
              </NiyoNotificationModal>
            )}
            <div>
              <img src={profile} alt="profile icon" />
            </div>
          </div>

          <div className="msg"></div>
        </DashboardTop>
        {loading ? (
          <DashboardLoader />
        ) : (
          <DashboardInfo>
            <DashboardFlex>
              {dashboardInfo.map((dashboard, index) => (
                <div className="info" key={index}>
                  <div className="content">
                    <div>
                      <h3>{dashboard.no}</h3>
                      <img src={dashboard.icon} alt="icons" />
                    </div>
                    <h4>{dashboard.text}</h4>
                  </div>
                </div>
              ))}
            </DashboardFlex>

            <DashboardForm>
              <form onSubmit={handleSubmit}>
                <div className="inputs">
                  <div>
                    <div className="subtitle2">Partners we work with</div>
                    <InputField
                      type="text"
                      value={partnersCounts}
                      onChange={handlePartnersCount}
                      className="form-box left"
                      placeholder="15,000k"
                    />
                  </div>
                  <div>
                    <div className="subtitle2">Community Members</div>
                    <InputField
                      type="text"
                      value={communityMember}
                      onChange={handleCommunityMembers}
                      className="form-box left"
                      placeholder="15,000k"
                    />
                  </div>
                  <div>
                    <div className="subtitle2">
                      Upskilled through niyo group
                    </div>
                    <InputField
                      type="text"
                      value={upskillCounts}
                      onChange={handleUpskillCount}
                      className="form-box left"
                      placeholder="100,000k"
                    />
                  </div>
                </div>
                <CustomButton
                  marginTop="66px"
                  padding="12px 16px"
                  className={`${
                    partnersCounts === '' ||
                    upskillCounts === '' ||
                    communityMember === ''
                      ? 'disabled'
                      : ''
                  }`}
                >
                  {loading ? (
                    <img src={Loading} alt="loader" width="30%" height="30%" />
                  ) : (
                    'Save Changes'
                  )}
                </CustomButton>
              </form>
            </DashboardForm>
          </DashboardInfo>
        )}

        <ToastContainer />
      </DashBoardStyle>
    </>
  )
}

export default Dashboard
