import client from '../utils/client'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL } from './ config'
     

export const events = createAsyncThunk(
  'events/fetchevents',
  async (status, { rejectWithValue }) => {
    try {
     
      const { data: {data} } = await client.get(
        `${BASE_URL}/api/v1/events?status=${status}`
      )
      return data
    } catch (error) {
      // return custom error message from API if any
      throw rejectWithValue(error.message)
    }
  }
)


export const createEvents = createAsyncThunk(
  'events/createevents',
  async ( form_data , { rejectWithValue }) => {
    try {
        const config = {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
      const { data:{ data } } = await client.post(
          `${BASE_URL}/api/v1/events`,
          form_data ,
          config,
      )
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.data[0].message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)


export const getEvent = createAsyncThunk(
  'event/getEvent',
  async (id,{ rejectWithValue }) => {
    try {
     
      const { data: {data} } = await client.get(
        `${BASE_URL}/api/v1/events/${id}`
      )
      return data
    } catch (error) {
      // return custom error message from API if any
      throw rejectWithValue(error.message)
    }
  }
)


export const updateEvents = createAsyncThunk(
  'event/updateEvents',
  async ( { id, description, title, eventDate, eventTime, eventLink, form_data } , { rejectWithValue }) => {
    try {
      if (title) {
        const { data:{ data } } = await client.patch(
          `${BASE_URL}/api/v1/events/${id}`,
          { title }
      )
      return data
      }
      if (description) {
        const { data:{ data } } = await client.patch(
          `${BASE_URL}/api/v1/events/${id}`,
          { description }
      )
      return data
      }
      if (eventDate) {
        const { data:{ data } } = await client.patch(
          `${BASE_URL}/api/v1/events/${id}`,
          { eventDate }
      )
      return data
      }
      if (eventTime) {
        const { data:{ data } } = await client.patch(
          `${BASE_URL}/api/v1/events/${id}`,
          { eventTime }
      )
      return data
      }
      if (eventLink) {
        const { data:{ data } } = await client.patch(
          `${BASE_URL}/api/v1/events/${id}`,
          { eventLink }
      )
      return data
      }
      if (form_data) {
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
        const { data:{ data } } = await client.patch(
          `${BASE_URL}/api/v1/events/${id}`,
          form_data ,
          config
      )
      return data
      }
        
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const deleteEvent = createAsyncThunk(
  'event/deleteEvent',
  async (id,{ rejectWithValue }) => {
    try {
     
      const { data: {data} } = await client.delete(
        `${BASE_URL}/api/v1/events/${id}`
      )
      return data
    } catch (error) {
      // return custom error message from API if any
      throw rejectWithValue(error.message)
    }
  }
)