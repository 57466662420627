import { Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import { deleteJob, job } from '../../../api/job'
import NiyoNotificationModal from '../../../components/modal'
import NiyoPagination from '../../../components/pagination'
import { deleteIcon, edit, search } from '../../../constants'
import { DeleteContent, DeleteTitle } from '../../../constants/deleteContetnt'
import { sortBySearch } from '../../../globalState/reducers/jobSlice'
import { DashboardTop } from '../../../styles/adminStyles/AdminDashBoard.Styled'
import { EventStyle } from '../../../styles/adminStyles/AdminEvent.Styled'
import { TableWrapper } from '../../../styles/adminStyles/AdminJob.styled'
import { Empty, TeamStyle } from '../../../styles/adminStyles/AdminTeam.Styled'
import { CustomButton, InputField } from '../../../styles/styledComponent'
import { BadNetwork } from '../../NotFound'
import JobLoader from '../loaders/jobLoader'

const Job = () => {
  const page = 5

  const { loading, jobs, error, searchedJob } = useSelector(
    (state) => state?.job,
  )

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const user = localStorage.getItem('userRole')
  const editText = 'You dont have access to update'

  useEffect(() => {
    async function fetchData() {
      await dispatch(job())
    }
    fetchData()
  }, [dispatch])

  useEffect(() => {
    if (error === 'deleteJob') {
      toast.success('Successfully Deleted Job', {
        position: toast.POSITION.TOP_CENTER,
      })
      // setTimeout(() => {
      navigate(0)
      // }, 1000)
    }
  }, [error, navigate])

  useEffect(() => {
    if (error === 'null') {
      setTimeout(() => {
        toast.success('Successfully Added Job', {
          position: toast.POSITION.TOP_CENTER,
        })
      }, 1000)
    }
  }, [error])

  useEffect(() => {
    if (error === 'Rejected') {
      setTimeout(() => {
        toast.success('Your Delete Request is sent to admin', {
          position: toast.POSITION.TOP_CENTER,
        })
      }, 1000)
    }
  }, [error])

  useEffect(() => {
    if (error === 'updateJob') {
      navigate(0)
    }
  }, [error, navigate])

  const filteredJob = jobs?.filter((job) => {
    return job.title?.toLowerCase().indexOf(searchedJob?.toLowerCase()) !== -1
  })

  const [sizes, setSizes] = useState(page)
  const [currents, setCurrents] = useState(1)
  const [searchJob, setSearchJob] = useState('')

  const handleSearchJob = (e) => {
    setSearchJob(e.target.value)
    dispatch(sortBySearch(e.target.value))
  }

  
  useEffect(() => {
    setSearchJob('')
    dispatch(sortBySearch(''))
  }, [dispatch])

  const latestData =
    filteredJob &&
    filteredJob
      .map((obj) => {
        return { ...obj, date: new Date(obj?.updatedAt) }
      })
      .sort((a, b) => b?.date - a?.date)

  const getData = (current, pageSize) => {
    return latestData?.slice((current - 1) * pageSize, current * pageSize)
  }
  if (error === 'BadNetwork') return <BadNetwork />

  return (
    <EventStyle>
      <TeamStyle>
        <DashboardTop>
          <h5>Job</h5>
          <div className="team">
            <div>
              <div>
                <img src={search} alt="search-icon" />
              </div>
              <InputField
                type="text"
                id="first-name"
                width="70%"
                style={{ color: 'black' }}
                value={searchJob}
                background="white"
                onChange={handleSearchJob}
                placeholder="Search for Job"
              />
            </div>
            <Link to="/admin/addJob">
              <div className="btn">
                <CustomButton width="142px" padding="12px 16px">
                  Add New Job
                </CustomButton>
              </div>
            </Link>
          </div>
        </DashboardTop>
        {loading ? (
          <JobLoader />
        ) : (
          <>
            <div className="job-grid">
              <TableWrapper>
                <table className="job-table">
                  <thead>
                    <tr>
                      <th>
                        <span>Job Title</span>
                      </th>
                      <th>
                        <span>Role purpose</span>
                      </th>
                      <th>
                        <span>Action</span>
                      </th>
                    </tr>
                  </thead>

                  {jobs?.length === 0 && (
                  <div>There are currently no job available</div>
                )}
                  {(latestData?.length === 0 && jobs?.length) ? (
                    <Empty>There are currently no jobs available for your search</Empty>
                  ) : (
                    getData(currents, sizes)?.map((item, index) => {
                      const regex = /(<([^>]+)>)/gi
                      const description = item?.description
                      return (
                        <tbody>
                          <tr key={index} className="alternate">
                            <td>
                              <p>{item?.title}</p>
                            </td>
                            <td>
                              <span>{`${description
                                .replace(regex, '')
                                .substring(0, 85)}........ `}</span>
                            </td>
                            <td>
                              <div className="flex">
                                {user === 'super-admin' && (
                                  <>
                                    <Link to={`${item?.id}`}>
                                      <img src={edit} alt="edit" />
                                    </Link>

                                    <NiyoNotificationModal
                                      Content={DeleteContent}
                                      Title={DeleteTitle}
                                      id={item?.id}
                                      nav={deleteJob}
                                      url={'job'}
                                    >
                                      <img src={deleteIcon} alt="deleteIcon" />
                                    </NiyoNotificationModal>
                                  </>
                                )}
                                {user === 'manager' && (
                                  <>
                                    <Link to={`${item?.id}`}>
                                      <img src={edit} alt="edit" />
                                    </Link>

                                    <NiyoNotificationModal
                                      Content={DeleteContent}
                                      Title={DeleteTitle}
                                      id={item?.id}
                                      nav={deleteJob}
                                      url={'job'}
                                    >
                                      <img src={deleteIcon} alt="deleteIcon" />
                                    </NiyoNotificationModal>
                                  </>
                                )}
                                {user === 'admin' && (
                                  <div className="flex">
                                    <Tooltip
                                      placement="topLeft"
                                      title={editText}
                                    >
                                      <img
                                        src={edit}
                                        alt="edit"
                                        className="disabled"
                                      />
                                    </Tooltip>
                                    <NiyoNotificationModal
                                      Content={DeleteContent}
                                      Title={DeleteTitle}
                                      id={item?.id}
                                      nav={deleteJob}
                                      url={'job'}
                                    >
                                      <img src={deleteIcon} alt="deleteIcon" />
                                    </NiyoNotificationModal>
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      )
                    })
                  )}
                </table>
              </TableWrapper>
            </div>
            {latestData?.length > 0 && (
              <NiyoPagination
                perPage={page}
                data={filteredJob}
                setSizes={setSizes}
                setCurrents={setCurrents}
                sizes={sizes}
                currents={currents}
              />
            )}
          </>
        )}
      </TeamStyle>
      <ToastContainer />
    </EventStyle>
  )
}

export default Job
