import styled from 'styled-components'

export const EventStyle = styled.div`
  .event-top {
    margin: 22px 0 42px;
    display: flex;
    align-items: center;
    gap: 18px;
    font-size: 16px;
    > div:nth-child(1) {
      font-size: 16px;
      padding: 14px 23px;
      background: #eaebeb;
      outline: none;
      border: none;
    }
  }


  .event-grid {
    overflow-x: scroll;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-bottom: 61px;
    .grid {
      display: grid;
      grid-template-columns: repeat(10, 1fr);
      div:nth-child(1) {
        grid-column: 1/5;
      }
      div {
        border-right: 1px solid #d5d7d7;
      }
      div:nth-child(2) {
        grid-column: 5/8;
      }

      div:nth-child(3) {
        grid-column: span 2;
      }
      div:nth-child(4) {
        grid-column: 10/11;
      }
    }
    p {
      margin: 0;
      font-size: 16px;
    }
    .bg {
      background-color: black;
      color: white;
      div:nth-child(1) {
        padding: 14px 74px;
      }
      div {
        padding: 14px 36px;
      }
    }
  }
  .form-event {
    .grid {
      div {
        border-right: 1px solid #d5d7d7;
        padding: 26px 21px;
      }
      div:nth-child(1) {
        display: flex;
        align-items: center;
        gap: 14px;
        padding: 13px 6px;
        img {
          width: 54px;
          height: 54px;
        }
      }

      .action {
        display: flex;
        gap: 10px;
      }
    }
  }
`
export const TableWrapper = styled.div`
  overflow-x: auto;
  .event-table {
    width: 100%;
  }
  .event-table thead th {
    background-color: black;
    color: white;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    &:first-child {
      span {
        padding-left: 50px;
      }
    }
  }

  .event-table tbody td {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 25px;
    border: 0.5px solid #d5d7d7;
    color: #070909;
    &:first-child {
      text-align: center;
      min-width: 103px;
      white-space: normal !important;
      word-wrap: break all;
    }
    .flex {
      display: flex;
      align-items: center;
      .imgWrapper {
        width: 55px;
        height: 55px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          // border: 3px solid green;
        }
      }
    }
  
    .flexs {
      display: flex;
      align-items: center;
      > img {
        margin-right: -56px;
        margin-top: -7px;
      }
    }
    .flexs img:first-child {
      margin-right: -56px;
      cursor: pointer;
    }
    .flexs img:last-child {
      padding-right: 40px;
      cursor: pointer;
    }
    .flex img {
      margin-right: 12px;
    }
  }
  .event-table tbody {
    &:nth-child(odd) {
      background: #eaebeb;
    }
  }
  .event-table td,
  .event-table th {
    padding: 18px 10px 18px 20px;

    span {
      display: flex;
      justify-content: flex-start;
      text-transform: capitalize;
    }
  }
  .event-table th {
  }

  .event-image {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }

  h3 {
    font-size: 15px;
    font-weight: 800;
    line-height: 20px;
    display: block;
    margin-bottom: 9px;
  }

  h4 {
    font-size: 15px;
  }

  .item {
    display: flex;
    align-items: center;
    @media (max-width: 900px) {
      width: 100%;
      display: block;
    }
  }

  .event-table > thead > tr > th > span {
    white-space: nowrap;
  }
`
