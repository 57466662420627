import styled from "styled-components";

export const CustomButton = styled.button`
  padding: ${({ padding }) => (padding ? padding : "16px 42px")};
  background: ${({ background }) => (background ? background : "var(--black)")};
  font-size: 16px;
  margin-right: ${({ margin }) => (margin ? margin : "none")};
  margin-left: ${({ marginL }) => (marginL ? marginL : "none")};
  display: ${({ display }) => (display ? display : "block")};
  width: ${({ width }) => (width ? width : "124px")};
  color: ${({ color }) => (color ? color : "white")};
  line-height: 25px;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "7px")};
  cursor: pointer;
  border: ${({ border }) => (border ? border : "1px solid #191A1A")};
  border-radius: ${({ Radius }) => (Radius ? Radius : "none")};
  &.normal{
    padding: 16px 20.5px;
  }
  &:hover {
    background: ${({ hover }) => (hover ? hover : "#454A4A;")};
  }
`;

export const InputField = styled.input`
    display: block;
    width: ${({ width }) => (width ? width : "100%")};
    background: ${({ background }) => (background ? background : "var(--light-grey)")};
    padding: 19px 22px;
    outline: none;
    color: var(--semi-grey);
    border: none;
    &::placeholder {
      color: #a0a6a6;
      font-size: 16px;
      
    }
`;

export const TextareaField = styled.textarea`
display: block;
width: ${({ width }) => (width ? width : "100%")};
background: var(--light-grey);
padding: 19px 22px;
outline: none;
border: none;
&::placeholder {
  color: #a0a6a6;
  font-size: 16px;
}
`;

export const SelectField = styled.select`
width: 12%;
cursor: pointer;
border: none;
`;
