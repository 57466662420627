import React from "react";
import styled from "styled-components";
import { deleteIcon, lock } from ".";
import { CustomButton } from "../styles/styledComponent";

import { useDispatch } from "react-redux";

export const DeleteTitle = () => {
  return (
    <Title>
      <p>
        <img src={deleteIcon} alt="delete-icon" />
      </p>
    </Title>
  );
};

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const PublishTitle = () => {
  return (
    <Publishtitle>
      <p>
        <img src={lock} alt="publish-icon" />
      </p>
    </Publishtitle>
  );
};

export const Publishtitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PublishContent = ({ hide, id, nav, url }) => {
  const dispatch = useDispatch();

  const deleteContent = () => {
    dispatch(nav(id));
  };
  return (
    <PublishWrapper>
      <p>Publish {url}</p>
      <p>Blogs would become viewable</p>
      <div>
        <CustomButton
          background="#EAEBEB"
          border="1px solid #EAEBEB"
          padding="5px 8px"
          color="black"
          onClick={(e) => {
            e.preventDefault();
            hide();
          }}
          onBlur={() => {}}
        >
          Cancel
        </CustomButton>
        <CustomButton
          background="#DC0707"
          color="white"
          border="1px solid #DC0707"
          padding="5px 8px"
          onClick={(e) => {
            e.preventDefault();
            deleteContent();
          }}
        >
          Publish
        </CustomButton>
      </div>
    </PublishWrapper>
  );
};

export const PublishWrapper = styled.div`
  .ant-popover-content {
    display: none;
  }
  p:first-child {
    font-weight: 500;
    font-size: 15px;
    margin-top: -10px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: #454a4a;
  }
  p:nth-child(2) {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #454a4a;
  }
  div {
    display: flex;
    button {
      font-weight: 300;
      font-size: 12.6px;
      //   line-height: 13px;
    }
    button:first-child {
      margin-right: 20px;
    }
  }
`;

export const DeleteContent = ({ hide, id, nav, url }) => {
  const dispatch = useDispatch();

  const deleteContent = () => {
    dispatch(nav(id));
  };
  return (
    <Wrapper>
      <p>Delete {url}</p>
      <p>This action can’t be undone</p>
      <div>
        <CustomButton
          background="#EAEBEB"
          border="1px solid #EAEBEB"
          padding="5px 8px"
          color="black"
          onClick={(e) => {
            e.preventDefault();
            hide();
          }}
          onBlur={() => {}}
        >
          Cancel
        </CustomButton>
        <CustomButton
          background="#DC0707"
          color="white"
          border="1px solid #DC0707"
          padding="5px 8px"
          onClick={(e) => {
            e.preventDefault();
            deleteContent();
          }}
        >
          Delete
        </CustomButton>
      </div>
    </Wrapper>
  );
};

export const Wrapper = styled.div`
  .ant-popover-content {
    display: none;
  }
  p:first-child {
    font-weight: 500;
    font-size: 15px;
    margin-top: -10px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: #454a4a;
  }
  p:nth-child(2) {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #454a4a;
  }
  div {
    display: flex;
    button {
      font-weight: 300;
      font-size: 12.6px;
      //   line-height: 13px;
    }
    button:first-child {
      margin-right: 20px;
    }
  }
`;
