import client from '../utils/client'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL } from './ config'
     

export const job = createAsyncThunk(
  'job/fetchjob',
  async (obj,{ rejectWithValue }) => {
    try {
     
      const { data: {data} } = await client.get(
        `${BASE_URL}/api/v1/job`
      )
      return data
    } catch (error) {
      // return custom error message from API if any
      throw rejectWithValue(error.message)
    }
  }
)

export const getJob = createAsyncThunk(
  'job/getJob',
  async (id,{ rejectWithValue }) => {
    try {
     
      const { data: {data} } = await client.get(
        `${BASE_URL}/api/v1/job/${id}`
      )
      return data
    } catch (error) {
      // return custom error message from API if any
      throw rejectWithValue(error.message)
    }
  }
)

export const createJob = createAsyncThunk(
  'job/createJob',
  async ( { title, description, department } , { rejectWithValue }) => {
    try {
      const { data:{ data } } = await client.post(
          `${BASE_URL}/api/v1/job`,
          { title, description, department }
      )
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const updateJob = createAsyncThunk(
  'job/updateJob',
  async ( { id, title, description, department } , { rejectWithValue }) => {
    try {
      if (title) {
        const { data:{ data } } = await client.patch(
            `${BASE_URL}/api/v1/job/${id}`,
            { title }
        )
        return data
      }
      if (description) {
        const { data:{ data } } = await client.patch(
            `${BASE_URL}/api/v1/job/${id}`,
            { description }
        )
        return data
      }
      if (department) {
        const { data:{ data } } = await client.patch(
            `${BASE_URL}/api/v1/job/${id}`,
            { department }
        )
        return data
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const deleteJob = createAsyncThunk(
  'job/deleteJob',
  async (id,{ rejectWithValue }) => {
    try {
     
      const { data: {data} } = await client.delete(
        `${BASE_URL}/api/v1/job/${id}`
      )
      return data
    } catch (error) {
      // return custom error message from API if any
      throw rejectWithValue(error.message)
    }
  }
)

export const department = createAsyncThunk(
    'department/fetchdepartment',
    async () => {
      try {
       
        const { data: {data} } = await client.get(
          `${BASE_URL}/api/v1/department`
        )
        return data
      } catch (error) {
        // return custom error message from API if any
        if (error.response && error.response.data.message) {
            console.log("error")
        } else {
          console.log("error")
        }
      }
    }
)