import React,{ useMemo, useEffect} from 'react'
import { useLocation } from 'react-router-dom'
import dashboardIcon from '../assets/icons/dashboard-icon.svg'
import eventIcon from '../assets/icons/event-icon.svg'
import jobIcon from '../assets/icons/job-icon.svg'
import teamIcon from '../assets/icons/team-icon.svg'
import adminIcon from '../assets/icons/admin-icon.svg'
import jwt_decode from 'jwt-decode'
import { logout } from '../globalState/reducers/authSlice'
import { useDispatch } from 'react-redux'
import logoutIcon from '../assets/icons/logout-icon.svg'
import adminActive from '../assets/icons/admin-active.svg'
import jobActive from '../assets/icons/job-active.svg'
import eventActive from '../assets/icons/event-active.svg'
import teamActive from '../assets/icons/team-active.svg'
import dashboard from '../assets/icons/dashboard-non.svg'
import logo from '../assets/icons/logo.svg'

import {
  SideNavStyle,
  Logo,
  SideNavMenu,
  SideNavList,
} from '../styles/adminStyles/AdminSideNav.Styled'
import { Link } from 'react-router-dom'

const AdminSideNav = () => {
  const dispatch = useDispatch()
  const sideNavList = [
    {
      href: '/admin/dashboard',
      text: 'Dashboard',
      icon: dashboard,
      active: dashboardIcon,
    },
    {
      href: '/admin/team',
      text: 'Team',
      icon: teamIcon,
      active: teamActive,
    },
    {
      href: '/admin/event',
      text: 'Events',
      icon: eventIcon,
      active: eventActive,
    },
    {
      href: '/admin/job',
      text: 'Job',
      icon: jobIcon,
      active: jobActive,
    },
    {
      href: '/admin/blog',
      text: 'Blog',
      icon: jobIcon,
      active: jobActive,
    },
    {
      href: '/admin/career',
      text: 'Bootcamp Jobs',
      icon: adminIcon,
      active: adminActive,
    },
    {
      href: '/admin',
      text: 'Admin',
      icon: adminIcon,
      active: adminActive,
    },
  ]
  const location = useLocation()
  const currentPath = location?.pathname

  const expiry = useMemo(() => {
    const token  = localStorage.getItem('userToken')
    ? localStorage.getItem('userToken')
    : null
    const decodedToken = token && jwt_decode(token);
    const currentDate = new Date();

    return decodedToken?.exp * 1000 < currentDate.getTime();
  }, []);

  useEffect(() => {
    if (expiry) {
      dispatch(logout())
    }
  }, [expiry, dispatch]);
  return (
    <SideNavStyle>
      <Logo>
        <img src={logo} alt="logo" />
      </Logo>

      <SideNavMenu>
        {sideNavList.map((list) => (
          <Link to={list.href} key={list.text}>
            <SideNavList
              className={`${currentPath === list.href ? 'active' : ''}`}
            >
              <img
                src={currentPath === list.href ? list.active : list.icon}
                alt="icon"
              />{' '}
              <p>{list.text}</p>
            </SideNavList>
          </Link>
        ))}
      </SideNavMenu>
      <Link to="/">
        <SideNavList onClick={() => dispatch(logout())}>
          <img src={logoutIcon} alt="icon" />
          <p>Log out</p>
        </SideNavList>
      </Link>
    </SideNavStyle>
  )
}

export default AdminSideNav
