import { createSlice } from '@reduxjs/toolkit'
import { dashboard } from '../../api/dashboard'


const initialState = {
  loading: false,
  error: null,
  success: false,
  data: null,
}

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
  },
  extraReducers: {
    [dashboard.pending]: (state) => {
      state.loading = true
      state.error = null
    },
      [dashboard.fulfilled]: (state, action) => {
      state.loading = false
      state.data = action.payload
    },
    [dashboard.rejected]: (state) => {
      state.loading = false
      state.error = 'BadNetwork'
    },
  },
})
export default dashboardSlice.reducer
