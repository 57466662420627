import React, { useState, useEffect } from 'react'
import {
  AddTeamForm,
  AddTeamStyle,
  AddTeamTop,
} from '../../../styles/adminStyles/AdminAddTeam.Styled'
import { DatePicker } from 'antd'
import Dropzone from 'react-dropzone'
import ArrowIcon from '../../../assets/icons/arrow-icon.svg'
import { useDispatch, useSelector } from 'react-redux'
import { createEvents } from '../../../api/events'
import Loading from '../../../assets/icons/loading.gif'
import { ToastContainer, toast } from 'react-toastify'
import AttachIcon from '../../../assets/icons/attach-icon.svg'
import DropFileIcon from '../../../assets/icons/dropFile-icon.svg'
import {
  CreateEventStyle,
  DropDownHeader,
  DropDownList,
  DropDownListContainer,
  ListItem,
} from '../../../styles/adminStyles/AdminCreateEvent.Styled'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import {
  CustomButton,
  InputField,
  TextareaField,
} from '../../../styles/styledComponent'
import { customTime } from '../../../constants'

const CreateEvent = () => {
  const { error, loading } = useSelector((state) => state.events)

  const dispatch = useDispatch()

  const [description, setDescription] = useState('')
  const [selectedFile, setSelectedFile] = useState(null)
  const [dropFile, setDropFile] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const [link, setLink] = useState('')
  const [selectedOption, setSelectedOption] = useState(null)
  const [selectedDate, setSelectedDate] = useState(null)
  const [{ content }, setContent] = useState({
    content: '',
    wordCount: 0,
  })

  const navigate = useNavigate()

  const toggling = () => setIsOpen(!isOpen)

  useEffect(() => {
    if (error !== null && error !== 'null') {
      toast.error(error, {
        position: toast.POSITION.TOP_CENTER,
      })
    }
  }, [error])

  useEffect(() => {
    if (error === 'null') {
      navigate('/admin/event')
    }
  }, [error, navigate])

  const onOptionClicked = (value) => () => {
    setSelectedOption(value)
    setIsOpen(false)
  }

  const handleDataState = (file) => {
    setSelectedFile(file)
  }
  const handleFileUpload = (event) => {
    handleDataState(event.target.files[0])
  }

  const handleFileDrop = (file) => {
    handleDataState(file)
    setDropFile(file)
  }
  const onChange = (date, dateString) => {
    setSelectedDate(dateString)
  }
  const handleLink = (e) => {
    setLink(e.target.value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const coverPhoto = selectedFile || dropFile
    let form_data = new FormData()
    form_data.append('coverPhoto', coverPhoto, coverPhoto?.path)
    form_data.append('title', content)
    form_data.append('description', description)
    form_data.append('eventDate', selectedDate)
    form_data.append('eventTime', selectedOption)
    form_data.append('eventLink', link)
    dispatch(createEvents(form_data))
    setDescription('')
    setSelectedFile(null)
    setDropFile(null)
  }

  // const handleTitle = (e) => {
  //   const limit = 4
  //   setTitle(e.target.value.slice(0, limit))
  // }
  const limit = 7
  const setFormattedContent = React.useCallback(
    (text) => {
      let words = text.split(' ').filter(Boolean)

      if (words.length > limit) {
        setContent({
          content: words.slice(0, limit).join(' '),
          wordCount: limit,
        })
      } else {
        setContent({ content: text, wordCount: words.length })
      }
    },
    [limit, setContent],
  )

  React.useEffect(() => {
    setFormattedContent(content)
  }, [content, setFormattedContent])

  const handleDescription = (e) => {
    const limit = 50
    setDescription(e.target.value.slice(0, limit))
  }
  return (
    <CreateEventStyle>
      <AddTeamStyle>
        <Link to="/admin/event">
          <AddTeamTop>
            <img src={ArrowIcon} alt="Arrow Icon" /> <h5>Create New Events</h5>
          </AddTeamTop>
        </Link>
        <AddTeamForm>
          <form onSubmit={handleSubmit}>
            <div className="inputs">
              <div>
                <label>Event Titile </label>
                <TextareaField
                  type="text"
                  onChange={(event) => setFormattedContent(event.target.value)}
                  value={content}
                  // value={title}
                  // onChange={handleTitle}
                  placeholder="Building your Personal Brand in the workplace & Tackling Impostor Syndrome"
                />
                <p style={{color:"#962306", fontSize:"12px", marginTop:"-5px"}}>5 words limit!</p>
              </div>
              <div>
                <label>Event Description </label>
                <TextareaField
                  type="text"
                  value={description}
                  onChange={handleDescription}
                  placeholder="Building your Personal Brand in the workplace & Tackling Impostor Syndrome"
                />
                <p style={{color:"#962306", fontSize:"12px", marginTop:"-5px"}}>49 character limit!</p>
              </div>

              <div className="flex">
                <div>
                  <label>Events Date</label>
                  <DatePicker onChange={onChange} />
                </div>
                <div>
                  <label>Events Time</label>
                  <DropDownHeader
                    onClick={toggling}
                    className={`${selectedOption ? 'colorB' : ''}`}
                  >
                    {selectedOption ? selectedOption : 'Time'}
                  </DropDownHeader>
                  {isOpen && (
                    <DropDownListContainer>
                      <DropDownList>
                        {customTime.map((option) => (
                          <ListItem
                            onClick={onOptionClicked(option)}
                            key={Math.random()}
                          >
                            {option}
                          </ListItem>
                        ))}
                      </DropDownList>
                    </DropDownListContainer>
                  )}
                </div>
              </div>

              <div className="uploads">
                <label>Upload event image</label>
                <Dropzone onDrop={(files) => handleFileDrop(files[0])}>
                  {({ getRootProps, getInputProps }) => (
                    <div className="btn" {...getRootProps()}>
                      <input {...getInputProps()} onChange={handleFileUpload} />
                      <div>
                        {selectedFile !== null ? (
                          <p>Image Uploaded</p>
                        ) : (
                          <>
                            <img src={AttachIcon} alt="attach icon" />
                            <i>Attach file</i>
                          </>
                        )}
                      </div>
                      <p>or</p>
                      <div>
                        {dropFile !== null ? (
                          <p>Image Uploaded</p>
                        ) : (
                          <>
                            <img src={DropFileIcon} alt="Drop File Icon" />
                            <i>Drop file here</i>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </Dropzone>
              </div>
              <div>
                <label>Event Registration Link </label>
                <InputField
                  value={link}
                  onChange={handleLink}
                  type="url"
                  required
                  placeholder="https://registerevent.com"
                />
              </div>
            </div>
            <CustomButton
              padding="12px 16px"
              className={`${
                content === '' ||
                link === '' ||
                description === '' ||
                selectedOption === null ||
                selectedFile === null
                  ? 'disabled'
                  : ''
              }`}
            >
              {loading ? (
                <img src={Loading} alt="loader" width="30%" height="30%" />
              ) : (
                'Save Changes'
              )}
            </CustomButton>
          </form>
        </AddTeamForm>
      </AddTeamStyle>
      <ToastContainer />
    </CreateEventStyle>
  )
}

export default CreateEvent
