import './App.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import NotFound from './pages/NotFound'
import Dashboard from './pages/Admin/Dashboard'
import AdminLogin from './pages/Admin'
import Job from './pages/Admin/jobs/Job'
import Blog from './pages/Admin/blog/Blog'
import UpdateBlog from './pages/Admin/blog/UpdateBlog'
import UpdateJob from './pages/Admin/jobs/updateJob'
import Event from './pages/Admin/events/Event'
import AdminLayout from './layout/AdminLayout'
import Team from './pages/Admin/teams/Team'
import UpdateTeam from './pages/Admin/teams/updateTeam'
import AddTeam from './pages/Admin/teams/AddTeam'
import Admin from './pages/Admin/admin/Admin'
import Career from './pages/Admin/careers/career'
import AddCareer from './pages/Admin/careers/addCareers'
import UpdateCareer from './pages/Admin/careers/updateCareer'
import CreateEvent from './pages/Admin/events/CreateEvent'
import AddAdmin from './pages/Admin/admin/AddAdmin'
import AddJobs from './pages/Admin/jobs/AddJobs'
import AddBlogs from './pages/Admin/blog/AddBlog'
import RequireAuth from './utils/RequiredAuth'
import UpdateEvent from './pages/Admin/events/updateEvent'
import UpdateAdmin from './pages/Admin/admin/updateAdmin'

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<AdminLogin />}></Route>
          <Route element={<RequireAuth />}>
            <Route
              exact
              path="/admin/dashboard"
              element={
                <AdminLayout>
                  <Dashboard />
                </AdminLayout>
              }
            ></Route>
            <Route
              exact
              path="/admin/team"
              element={
                <AdminLayout>
                  <Team />
                </AdminLayout>
              }
            ></Route>

            <Route
              exact
              path="/admin/addTeam"
              element={
                <AdminLayout>
                  <AddTeam />
                </AdminLayout>
              }
            ></Route>
             <Route
              exact
              path="/admin/team/:id"
              element={
                <AdminLayout>
                  <UpdateTeam />
                </AdminLayout>
              }
            ></Route>
            

            <Route
              exact
              path="/admin/job"
              element={
                <AdminLayout>
                  <Job />
                </AdminLayout>
              }
            ></Route>
            <Route
              exact
              path="/admin/job/:id"
              element={
                <AdminLayout>
                  <UpdateJob />
                </AdminLayout>
              }
            ></Route>
            <Route
              exact
              path="/admin/addJob"
              element={
                <AdminLayout>
                  <AddJobs />
                </AdminLayout>
              }
            ></Route>
            <Route
              exact
              path="/admin/blog"
              element={
                <AdminLayout>
                  <Blog />
                </AdminLayout>
              }
            ></Route>
            <Route
              exact
              path="/admin/blog/:id"
              element={
                <AdminLayout>
                  <UpdateBlog />
                </AdminLayout>
              }
            ></Route>
            <Route
              exact
              path="/admin/addBlog"
              element={
                <AdminLayout>
                  <AddBlogs />
                </AdminLayout>
              }
            ></Route>
            <Route
              exact
              path="/admin/event"
              element={
                <AdminLayout>
                  <Event />
                </AdminLayout>
              }
            ></Route>
            <Route
              exact
              path="/admin/create-event"
              element={
                <AdminLayout>
                  <CreateEvent />
                </AdminLayout>
              }
            ></Route>
            <Route
              exact
              path="/admin/event/:id"
              element={
                <AdminLayout>
                  <UpdateEvent />
                </AdminLayout>
              }
            ></Route>
            <Route
              exact
              path="/admin/career"
              element={
                <AdminLayout>
                  <Career />
                </AdminLayout>
              }
            ></Route>
            <Route
              exact
              path="/admin/career/:id"
              element={
                <AdminLayout>
                  <UpdateCareer />
                </AdminLayout>
              }
            ></Route>
            <Route
              exact
              path="/admin"
              element={
                <AdminLayout>
                  <Admin />
                </AdminLayout>
              }
            ></Route>
            <Route
              exact
              path="/admin/addCareer"
              element={
                <AdminLayout>
                  <AddCareer />
                </AdminLayout>
              }
            ></Route>
            <Route
              exact
              path="/admin/addAdmin"
              element={
                <AdminLayout>
                  <AddAdmin />
                </AdminLayout>
              }
            ></Route>
            <Route
              exact
              path="/admin/:id"
              element={
                <AdminLayout>
                  <UpdateAdmin />
                </AdminLayout>
              }
            ></Route>
          </Route>
          {/* List a generic 404-Not Found route here */}
          <Route exact path="*" element={<NotFound />}></Route>
        </Routes>
        {/* <Footer /> */}
      </Router>
    </>
  )
}

export default App
