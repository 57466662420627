import { createSlice } from "@reduxjs/toolkit";
import {
  blog,
  createBlog,
  updateBlog,
  getBlog,
  deleteBlog,
  publishBlog,
} from "../../api/blog";

const initialState = {
  loading: false,
  error: null,
  success: false,
  data: null,
  blogs: null,
  details: "",
  departmentList: null,
  searchedBlog: "",
};

const blogSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {
    sortBySearch: (state, action) => {
      const payload = action.payload;
      state.searchedBlog = payload;
    },
  },
  extraReducers: {
    [blog.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [blog.fulfilled]: (state, action) => {
      state.loading = false;
      state.blogs = action.payload;
    },
    [blog.rejected]: (state) => {
      state.loading = false;
      state.error = "BadNetwork";
    },
    [updateBlog.pending]: (state) => {
      state.loading = "loading";
      state.error = null;
    },
    [updateBlog.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "updateBlog";
    },
    [updateBlog.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [createBlog.pending]: (state) => {
      state.loading = "loading";
      state.error = null;
    },
    [createBlog.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "null";
    },
    [createBlog.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getBlog.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getBlog.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "getBlog";
      state.details = action.payload.description;
    },
    [getBlog.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [deleteBlog.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteBlog.fulfilled]: (state) => {
      state.loading = false;
      state.error = "deleteBlog";
    },
    [deleteBlog.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [publishBlog.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [publishBlog.fulfilled]: (state) => {
      state.loading = false;
      state.error = "publishBlog";
    },
    [publishBlog.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
  },
});
export default blogSlice.reducer;

export const { sortBySearch } = blogSlice.actions;
