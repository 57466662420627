import { createSlice } from '@reduxjs/toolkit'
import {
  events,
  createEvents,
  getEvent,
  updateEvents,
  deleteEvent,
} from '../../api/events'

const initialState = {
  loading: false,
  error: null,
  event: null,
  success: false,
    data: null,
    searchedEvent: ''
}

const eventsSlice = createSlice({
  name: 'events',
  initialState,
    reducers: {
        sortBySearch: (state, action) => {
            const payload = action.payload;
            state.searchedEvent = payload
          },
  },
  extraReducers: {
    [events.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [events.fulfilled]: (state, action) => {
      state.loading = false
      state.event = action.payload
    },
    [events.rejected]: (state, action) => {
      state.loading = false
      state.error = 'BadNetwork'
    },
    [createEvents.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [createEvents.fulfilled]: (state, action) => {
      state.loading = false
      state.data = action.payload
      state.error = 'null'
    },
    [createEvents.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [getEvent.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [getEvent.fulfilled]: (state, action) => {
      state.loading = false
      state.data = action.payload
      state.error = 'getEvent'
    },
    [getEvent.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [updateEvents.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [updateEvents.fulfilled]: (state) => {
      state.loading = false
      state.error = 'updateEvents'
    },
    [updateEvents.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [deleteEvent.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [deleteEvent.fulfilled]: (state) => {
      state.loading = false
      state.error = 'deleteEvent'
    },
    [deleteEvent.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
  },
})
export default eventsSlice.reducer

export const { sortBySearch } = eventsSlice.actions
