import styled from "styled-components";

export const AdminLayoutStyle = styled.div`
    display: flex;
    align-items: flex-start;
    width: 100%;
    height: 100vh;
`;

export const AdminRight = styled.div`
    background: #F4F5F5;
    width: 80%;
    height: 100%;
    overflow: scroll;
    white-space: nowrap;
`