import React from 'react'
import AdminSideNav from '../components/AdminSideNav'
import { AdminLayoutStyle, AdminRight } from '../styles/adminStyles/AdminLayout.Styled'

const AdminLayout = ({ children }) => {
  return (
    <AdminLayoutStyle>
      <AdminSideNav />
      <AdminRight>
        {children}
      </AdminRight>
    </AdminLayoutStyle>
  )
}

export default AdminLayout
