import { Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import { deleteTeam, team } from '../../../api/team'
import NiyoNotificationModal from '../../../components/modal'
import NiyoPagination from '../../../components/pagination'
import { deleteIcon, edit, search } from '../../../constants'
import { DeleteContent, DeleteTitle } from '../../../constants/deleteContetnt'
import { sortBySearch } from '../../../globalState/reducers/teamSlice'
import { DashboardTop } from '../../../styles/adminStyles/AdminDashBoard.Styled'
import {
  Empty,
  TableWrapper,
  TeamList,
  TeamStyle,
} from '../../../styles/adminStyles/AdminTeam.Styled'
import { CustomButton, InputField } from '../../../styles/styledComponent'
import { BadNetwork } from '../../NotFound'
import TeamLoader from '../loaders/teamLoader'

const Team = () => {
  const page = 5
  const styles = {
    border: {
      borderBottom: '0.5px solid grey',
    },
  }

  const { loading, teams, error, searchedTeam } = useSelector(
    (state) => state?.team,
  )

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const user = localStorage.getItem('userRole')
  const editText = 'You dont have access to update'

  const filteredTeams = teams?.filter((team) => {
    return (
      team.fullName?.toLowerCase().indexOf(searchedTeam?.toLowerCase()) !== -1
    )
  })

  useEffect(() => {
    async function fetchData() {
      await dispatch(team())
    }
    fetchData()
  }, [dispatch])

  useEffect(() => {
    if (error === 'deleteTeam') {
      toast.success('Successfully Deleted Team member', {
        position: toast.POSITION.TOP_CENTER,
      })
      navigate(0)
    }
  }, [error, navigate])

  useEffect(() => {
    if (error === 'null') {
      setTimeout(() => {
        toast.success('Successfully Added Team member', {
          position: toast.POSITION.TOP_CENTER,
        })
      }, 1000)
    }
  }, [error])

  useEffect(() => {
    if (error === 'updateTeam') {
      navigate(0)
    }
  }, [error, navigate])

  const [sizes, setSizes] = useState(page)
  const [currents, setCurrents] = useState(1)
  const [searchTeam, setSearchTeam] = useState('')

  const handleSearchTeam = (e) => {
    setSearchTeam(e.target.value)
    dispatch(sortBySearch(e.target.value))
  }


  useEffect(() => {
    setSearchTeam('')
    dispatch(sortBySearch(''))
  }, [dispatch])

  const latestData =
    filteredTeams &&
    filteredTeams
      .map((obj) => {
        return { ...obj, date: new Date(obj?.updatedAt) }
      })
      .sort((a, b) => b?.date - a?.date)

  const getData = (current, pageSize) => {
    return latestData?.slice((current - 1) * pageSize, current * pageSize)
  }
  if (error === 'BadNetwork') return <BadNetwork />
  return (
    <TeamStyle>
      <DashboardTop>
        <h5>Teams</h5>
        <div className="team">
          <div>
            <div>
              <img src={search} alt="search-icon" />
            </div>
            <InputField
              type="text"
              id="first-name"
              width="70%"
              style={{ color: 'black' }}
              value={searchTeam}
              background="white"
              onChange={handleSearchTeam}
              placeholder="Search for Employee"
            />
          </div>
          <Link to="/admin/addTeam">
            <div className="btn">
              <CustomButton width="142px" padding="12px 16px">
                Add New Team
              </CustomButton>
            </div>
          </Link>
        </div>
      </DashboardTop>

      {loading ? (
        <TeamLoader />
      ) : (
        <TeamList>
          <div className="subtitle2">All team</div>
          <div>
            <TableWrapper>
              <table className="team-table">
                <thead>
                  <tr>
                    <th style={styles.border}>
                      <span>S/NO</span>
                    </th>
                    <th style={styles.border}>
                      <span>Employee</span>
                    </th>
                    <th style={styles.border}>
                      <span>Role</span>
                    </th>
                    <th style={styles.border}>
                      <span>Funfact</span>
                    </th>
                    <th style={styles.border}>
                      <span>Socials</span>
                    </th>
                    <th style={styles.border}>
                      <span>Actions</span>
                    </th>
                  </tr>
                </thead>

                {teams?.length === 0 && (
                  <div>There are currently no team available</div>
                )}

                {(filteredTeams?.length === 0 && teams?.length) ? (
                  <Empty>
                    There are currently no team available for your search
                  </Empty>
                ) : (
                  getData(currents, sizes)?.map((item, index) => (
                    <tbody className="cursor__pointer">
                      <tr key={index}>
                        <td style={{ ...styles.border }}>{index + 1}</td>
                        <td style={styles.border}>
                          <div className="flex">
                            <div className="imgWrapper">
                              <img src={item?.photo?.url} alt="item" />
                            </div>
                            <p>{item?.fullName}</p>
                          </div>
                        </td>
                        <td style={styles.border}>
                          <span>{item?.jobRole}</span>
                        </td>
                        <td style={styles.border}>
                          <span>{`${item?.funFacts.substring(
                            0,
                            20,
                          )}........`}</span>
                        </td>
                        <td style={styles.border}>
                          <span>{`${item?.socialUrl.substring(
                            0,
                            20,
                          )}........`}</span>
                        </td>
                        <td style={styles.border}>
                          <div className="flexs">
                            {user === 'super-admin' && (
                              <>
                                <Link to={`${item?.id}`}>
                                  <img src={edit} alt="edit" />
                                </Link>
                                <NiyoNotificationModal
                                  Content={DeleteContent}
                                  Title={DeleteTitle}
                                  id={item?.id}
                                  nav={deleteTeam}
                                  url={'team'}
                                >
                                  <img src={deleteIcon} alt="deleteIcon" />
                                </NiyoNotificationModal>
                              </>
                            )}
                            {user === 'manager' && (
                              <>
                                <Link to={`${item?.id}`}>
                                  <img src={edit} alt="edit" />
                                </Link>
                                <NiyoNotificationModal
                                  Content={DeleteContent}
                                  Title={DeleteTitle}
                                  id={item?.id}
                                  nav={deleteTeam}
                                  url={'team'}
                                >
                                  <img src={deleteIcon} alt="deleteIcon" />
                                </NiyoNotificationModal>
                              </>
                            )}
                            {user === 'admin' && (
                              <>
                                <Tooltip placement="topLeft" title={editText}>
                                  <img
                                    src={edit}
                                    alt="edit"
                                    className="disabled"
                                  />
                                </Tooltip>
                                <NiyoNotificationModal
                                  Content={DeleteContent}
                                  Title={DeleteTitle}
                                  id={item?.id}
                                  nav={deleteTeam}
                                  url={'team'}
                                >
                                  <img src={deleteIcon} alt="deleteIcon" />
                                </NiyoNotificationModal>
                              </>
                            )}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  ))
                )}
              </table>
              <ToastContainer />
            </TableWrapper>
            {teams?.length > 0 && (
              <NiyoPagination
                perPage={page}
                data={teams}
                setSizes={setSizes}
                setCurrents={setCurrents}
                sizes={sizes}
                currents={currents}
              />
            )}
          </div>
        </TeamList>
      )}
    </TeamStyle>
  )
}

export default Team
