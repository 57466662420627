import client from '../utils/client'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL } from './ config'


export const notification = createAsyncThunk(
  'notification/fetchNotification',
  async (obj ,{ rejectWithValue }) => {
    try {
     
      const { data: {data} } = await client.get(
        `${BASE_URL}/api/v1/notification`
      )
      return data
    } catch (error) {
      // return custom error message from API if any
      throw rejectWithValue(error.message)
    }
  }
)

export const updateNotification = createAsyncThunk(
    'dashboard/updateDashboard',
    async ({ action, id }, { rejectWithValue }) => {
      try {
       
        const { data:{data} } = await client.put(
            `${BASE_URL}/api/v1/notification/${id}/approve`,
            { action }
        )
        return data
      } catch (error) {
        // return custom error message from API if any
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message)
        } else {
          return rejectWithValue(error.message)
        }
      }
    }
  )
