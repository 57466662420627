import styled from 'styled-components'

export const TeamStyle = styled.div`
  padding: 44px 47px;
  .team {
    width: 70%;
    display: flex;
    align-items: center;
    > div {
      display: flex;
      align-items: center;
      background: white;
      padding-left: 20px;
      margin-right: 20px;
      width: 70%;
      img {
        margin-top: 7px;
      }
    }
    input {
      padding: 14px 19px;
      border: none;
      &::placeholder {
        font-size: 12px;
        line-height: 16px;
      }
    }
    .btn {
      width: 30%;
      margin-top: -8px;
      button {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
`

export const TeamList = styled.div`
  margin-top: 50px;
  > p {
    font-weight: 600;
  }
  p {
    font-size: 16px;
  }
  .flex {
    overflow-x: scroll;
    .grid {
      display: grid;
      grid-template-columns: repeat(6, 0.5fr);
      align-items: center;
      gap: 24px;
      border-bottom: 1px solid #d5d7d7;
      p,
      div {
        padding: 5px 0;
      }
      .flex {
        display: flex;
        gap: 5px;
        text-align: center;
        width: 100px;
      }
      p:nth-child(1) {
        padding: 0 30px;
        width: 100px;
        text-align: center;
      }
      p:nth-child(3) {
        padding: 0px 20px;
        width: 180px;
      }
      p:nth-child(4) {
        padding: 0px 20px;
        width: 230px;
      }
      p:nth-child(5) {
        width: 190px;
      }
      p:nth-child(6) {
        text-align: center;
      }
      div:nth-child(6) {
        text-align: center;
        display: flex;
        justify-content: center;
        padding: 0 10px;
      }
    }
  }
`

export const Pagination = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 30px auto;
  > div {
    display: flex;
    gap: 10px;
    > div {
      display: flex;
      gap: 5px;
      span {
        padding: 10px 15px;
        border: 2px solid #a0a6a6;
        border-radius: 5px;
      }
    }
    img:nth-child(1) {
      transform: rotate(180deg);
    }
  }
`

export const Empty = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;
  text-align: center;
`

export const TableWrapper = styled.div`
  overflow-x: auto;
  .team-table {
    width: 100%;
  }

  .team-table thead th {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #636969;
  }
  .team-table tbody td {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;

    color: #070909;
    &:first-child {
      text-align: center;
    }
    &:nth-child(4) {
      width: 170px;
      // white-space: normal !important;
      word-wrap: break word;
      // border: 3px solid red;
    }
    &:nth-child(5) {
      width: 190px;
      // white-space: normal !important;
      word-wrap: break word;
    }
    .flex {
      display: flex;
      align-items:center;
      .imgWrapper {
        width: 55px;
        height: 55px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          display: flex;
        align-items: center;
        justify-content: center;
          // border: 3px solid green;
        }
      }
    }
    .flexs {
      display: flex;
      align-items: center;
      > img {
        margin-right: -56px;
        margin-top: -7px;
      }
    }
    .flexs img:first-child {
      margin-right: -56px;
      cursor: pointer;
    }
    .flex img {
      margin-right: 12px;
    }
  }
  .team-table td,
  .team-table th {
    padding: 23px 10px 23px 10px;

    span {
      display: flex;
      justify-content: flex-start;
      text-transform: capitalize;
    }
  }
  .team-table th {
  }

  .team-image {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }

  h3 {
    font-size: 15px;
    font-weight: 800;
    line-height: 20px;
    display: block;
    margin-bottom: 9px;
  }

  h4 {
    font-size: 15px;
  }

  .item {
    display: flex;
    align-items: center;
    @media (max-width: 900px) {
      width: 100%;
      display: block;
    }
  }

  .team-table > thead > tr > th > span {
    white-space: nowrap;
  }
`

export const DropDownListContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(25, 26, 26, 0.17);
  border-radius: 5px;
  height: 103px;
  // z-index: 200;
  z-index: 0;
  position: absolute;
  top: 200px;
  width: 190px;
  
`
export const DropDownList = styled.div`
  // height: 223px;
  overflow-y: auto;
  text-align: center;
`
