import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { userLogin } from '../../api/auth'
import {
  AdminLoginStyle,
  Flex,
  LoginForm,
} from '../../styles/adminStyles/AdminLogin.Styled'
import Loading from '../../assets/icons/loading.gif'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../../assets/icons/logo.svg'
import backgroundWave from '../../assets/icons/background-wave2.svg'
import { CustomButton, InputField } from '../../styles/styledComponent'
import { useNavigate } from 'react-router-dom'

function AdminLogin() {

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()

  const { loading, userInfo, error } = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  useEffect(() => {
    setTimeout(() => {
      if (userInfo) {
        navigate('/admin/dashboard')
      }
    }, 2000);
  }, [navigate, userInfo])
  
  useEffect(() => {
    if (error !== null && error !== 'null') {
      toast.error(error, {
        position: toast.POSITION.TOP_CENTER
      });
    }
  }, [error])
  useEffect(() => {
    if (error === 'null') {
      toast.success('Log in Succesful.... Redirecting...', {
        position: toast.POSITION.TOP_CENTER
      });
    }
  }, [error])

  const handleSubmit = async (e) => {
    e.preventDefault()
      
    await dispatch(userLogin({ email, password,navigate }))
   
  }
  const handleEmailInput = (e) => setEmail(e.target.value)

  const handlePwdInput = (e) => setPassword(e.target.value)

  const backgroundStyle = {
    backgroundImage: `url(${backgroundWave})`,
    backgroundRepeat: 'repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }

  return (
    <AdminLoginStyle>
      <div style={backgroundStyle}>
        <img src={logo} alt="logo" />
        <h3>
        Economically empowering black women in technology, fashion, hair & beauty globally.
        </h3>
        <p>We are a nation of DisruptHers, We are NIYO.</p>
      </div>

      <div>
        <div>
          <h3>Login to Account</h3>
          <p>Enter your email and password to continue</p>
          <form onSubmit={handleSubmit}>
          <LoginForm>
            <label htmlFor="email">Email Address</label>
            <InputField
                type="email"
                value={email}
                id="first-name"
                onChange={handleEmailInput}
              className="form-box left"
              placeholder="admin@niyo.co"
            />

            <Flex>
              <label htmlFor="password">Password</label>
              <div className="subtitle1">Forget password?</div>
            </Flex>
            <InputField
                type="password"
                value={password}
                onChange={handlePwdInput}
              id="first-name"
              className="form-box left"
              placeholder="*************"
            />

            <Flex>
              <div>
                <input type="checkbox" name="" id="" />
              </div>
              <p className="subtitle">Remember Password</p>
            </Flex>
              <CustomButton width="150px" style={{fontWeight:"600"}} className={`${loading ? 'disabled' : ''}`}>{loading ? <img src={Loading} alt="loader" width="30%" height="30%"/> : 'Sign In' }</CustomButton>
          </LoginForm>
            </form>
        </div>
      </div>
      <ToastContainer />
    </AdminLoginStyle>
  )
}

export default AdminLogin
