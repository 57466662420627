import { createSlice } from '@reduxjs/toolkit'
import { career, createCareer, getCareer, updateCareer, deleteCareer } from '../../api/career'

const initialState = {
  loading: false,
  error: null,
  careers: null,
  success: false,
  data: null,
  searchedCareer: ''
}

const careerSlice = createSlice({
  name: 'career',
  initialState,
    reducers: {
        sortBySearch: (state, action) => {
            const payload = action.payload;
            state.searchedCareer = payload
          },
  },
  extraReducers: {
    [career.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [career.fulfilled]: (state, action) => {
      state.loading = false
      state.careers = action.payload
    },
    [career.rejected]: (state, action) => {
      state.loading = false
      state.error = 'BadNetwork'
    },
    [createCareer.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [createCareer.fulfilled]: (state, action) => {
      state.loading = false
      state.data = action.payload
      state.error = 'null'
    },
    [createCareer.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [getCareer.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [getCareer.fulfilled]: (state, action) => {
        state.loading = false
      state.data = action.payload
      state.error = 'getCareer'
    },
    [getCareer.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [updateCareer.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [updateCareer.fulfilled]: (state, action) => {
      state.loading = false
      state.error = 'updateCareer'
    },
    [updateCareer.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [deleteCareer.pending]: (state, action) => {
      state.loading = true
      state.error = null
    },
    [deleteCareer.fulfilled]: (state, action) => {
      state.loading = false
      state.error = 'deleteCareer'
    },
    [deleteCareer.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
  },
})
export default careerSlice.reducer


export const { sortBySearch } = careerSlice.actions
