import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  AddTeamForm,
  AddTeamStyle,
  AddTeamTop,
} from "../../../styles/adminStyles/AdminAddTeam.Styled";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Loading from "../../../assets/icons/loading.gif";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { createBlog } from "../../../api/blog";
import Dropzone from "react-dropzone";
import client from "../../../utils/client";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../api/ config";
import AttachIcon from "../../../assets/icons/attach-icon.svg";
import DropFileIcon from "../../../assets/icons/dropFile-icon.svg";
import ArrowIcon from "../../../assets/icons/arrow-icon.svg";
import { AddAdminStyle } from "../../../styles/adminStyles/AdminAdd.Styled";
import { Link } from "react-router-dom";
import { CustomButton, InputField } from "../../../styles/styledComponent";
import Quill from "quill";

const AddJobs = () => {
  const quillRef = useRef(null);
  const handleImageUpload = async () => {
    console.log("works");
    try {
      const input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", "image/*");
      input.click();

      input.onchange = async () => {
        const file = input.files[0];
        const formData = new FormData();
        formData.append("photo", file);

        try {
          // Send image file to backend for upload
          const response = await client.post(
            `${BASE_URL}/api/v1/blog/upload-photo`,
            formData
          );

          // Extract the image URL from the response
          const imageUrl = response.data.imageUrl;

          // Insert the image URL into the editor at the current cursor position
          if (quillRef.current) {
            const quill = quillRef.current.getEditor();
            quill.focus();
            const range = quill.getSelection();
            quill.insertEmbed(
              range ? range.index : 0,
              "image",
              imageUrl,
              Quill.sources.USER
            );
            quill.setSelection(range ? range.index + 1 : 1);
          }
        } catch (error) {
          console.error("Error uploading image:", error);
        }
      };
    } catch (error) {
      console.error("Error handling image upload:", error);
    }
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["image", "link"],
          [
            {
              color: [
                "#000000",
                "#e60000",
                "#ff9900",
                "#ffff00",
                "#008a00",
                "#0066cc",
                "#9933ff",
                "#ffffff",
                "#facccc",
                "#ffebcc",
                "#ffffcc",
                "#cce8cc",
                "#cce0f5",
                "#ebd6ff",
                "#bbbbbb",
                "#f06666",
                "#ffc266",
                "#ffff66",
                "#66b966",
                "#66a3e0",
                "#c285ff",
                "#888888",
                "#a10000",
                "#b26b00",
                "#b2b200",
                "#006100",
                "#0047b2",
                "#6b24b2",
                "#444444",
                "#5c0000",
                "#663d00",
                "#666600",
                "#003700",
                "#002966",
                "#3d1466",
              ],
            },
          ],
        ],
        handlers: {
          image: handleImageUpload,
        },
      },
    }),
    []
  );

  const navigate = useNavigate();
  const { error, loading } = useSelector((state) => state.blog);

  const dispatch = useDispatch();

  useEffect(() => {
    if (error === "null") {
      navigate("/admin/blog");
    }
  }, [error, navigate]);
  useEffect(() => {
    if (error !== null && error !== "null" && error !== "publishBlog") {
      error &&
        error?.map((err) => {
          toast.error(err.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  }, [error]);

  const [details, setDetails] = useState("");
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [dropFile, setDropFile] = useState(null);
  const [dropFile2, setDropFile2] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const description = details;
    const coverPhoto = selectedFile || dropFile;
    const coverPhoto2 = selectedFile2 || dropFile2;
    let form_data = new FormData();
    form_data.append("photo", coverPhoto, coverPhoto?.path);
    form_data.append("previewPhoto", coverPhoto2, coverPhoto2?.path);
    form_data.append("title", title);
    form_data.append("description", description);
    form_data.append("category", category);
    dispatch(createBlog(form_data));
  };
  const handleTitle = (e) => {
    setTitle(e.target.value);
  };
  const handleCategory = (e) => {
    setCategory(e.target.value);
  };

  const handleDataState = (file) => {
    setSelectedFile(file);
  };
  const handleFileUpload = (event) => {
    handleDataState(event.target.files[0]);
  };

  const handleFileDrop = (file) => {
    handleDataState(file);
    setDropFile(file);
  };
  const handleFileUpload2 = (event) => {
    setSelectedFile2(event.target.files[0]);
  };

  const handleFileDrop2 = (file) => {
    setSelectedFile2(file);
    setDropFile2(file);
  };

  return (
    <AddAdminStyle>
      <AddTeamStyle>
        <Link to="/admin/blog">
          <AddTeamTop>
            <img src={ArrowIcon} alt="Arrow Icon" /> <h5>Post a blog</h5>
          </AddTeamTop>
        </Link>

        <AddTeamForm>
          <form onSubmit={handleSubmit}>
            <div className="blogInput">
              <div className="uploads">
                <label>Add blog preview image</label>
                <Dropzone onDrop={(files) => handleFileDrop2(files[0])}>
                  {({ getRootProps, getInputProps }) => (
                    <div className="btn" {...getRootProps()}>
                      <input
                        {...getInputProps()}
                        onChange={handleFileUpload2}
                      />
                      <div>
                        {selectedFile2 !== null ? (
                          <p>Image Uploaded</p>
                        ) : (
                          <>
                            <img src={AttachIcon} alt="attach icon" />
                            <i>Attach file</i>
                          </>
                        )}
                      </div>
                      <p>or</p>
                      <div>
                        {dropFile2 !== null ? (
                          <p>Image Uploaded</p>
                        ) : (
                          <>
                            <img src={DropFileIcon} alt="Drop File Icon" />
                            <i>Drop file here</i>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </Dropzone>
              </div>
              <div className="uploads">
                <label>Add blog image</label>
                <Dropzone onDrop={(files) => handleFileDrop(files[0])}>
                  {({ getRootProps, getInputProps }) => (
                    <div className="btn" {...getRootProps()}>
                      <input {...getInputProps()} onChange={handleFileUpload} />
                      <div>
                        {selectedFile !== null ? (
                          <p>Image Uploaded</p>
                        ) : (
                          <>
                            <img src={AttachIcon} alt="attach icon" />
                            <i>Attach file</i>
                          </>
                        )}
                      </div>
                      <p>or</p>
                      <div>
                        {dropFile !== null ? (
                          <p>Image Uploaded</p>
                        ) : (
                          <>
                            <img src={DropFileIcon} alt="Drop File Icon" />
                            <i>Drop file here</i>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </Dropzone>
              </div>

              <div>
                <label>Blog Title </label>
                <InputField
                  type="text"
                  value={title}
                  style={{ color: "black" }}
                  onChange={handleTitle}
                  placeholder="The Economic Impact of Creative Enterprises: Lessons from the Niyo Group"
                />
              </div>
              <div>
                <label>Blog Category </label>
                <InputField
                  type="text"
                  value={category}
                  style={{ color: "black" }}
                  onChange={handleCategory}
                  placeholder="Operations"
                />
              </div>

              <label>Blog details</label>
              <ReactQuill
                ref={quillRef}
                theme="snow"
                value={details}
                onChange={setDetails}
                className="editor-input"
                modules={modules}
              />
            </div>
            <CustomButton
              padding="12px 16px"
              margin="0px"
              marginL="auto"
              display="block"
              className={`${
                title === "" || category === "" || details === null
                  ? "disabled"
                  : ""
              }`}
            >
              {loading === "loading" ? (
                <img src={Loading} alt="loader" width="30%" height="30%" />
              ) : (
                "Save Changes"
              )}
            </CustomButton>
          </form>
        </AddTeamForm>
        <ToastContainer />
      </AddTeamStyle>
    </AddAdminStyle>
  );
};

export default AddJobs;
