import client from '../utils/client'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL } from './ config'


export const dashboard = createAsyncThunk(
  'dashboard/fetchDashboard',
  async (obj ,{ rejectWithValue }) => {
    try {
     
      const { data: {data} } = await client.get(
        `${BASE_URL}/api/v1/dashboard-overview`
      )
      return data
    } catch (error) {
      // return custom error message from API if any
      throw rejectWithValue(error.message)
    }
  }
)


export const updateDashboard = createAsyncThunk(
  'dashboard/updateDashboard',
  async ({ partnersCount, upskillCount, communityMembers }, { rejectWithValue }) => {
    try {
     
      const { data:{data} } = await client.patch(
          `${BASE_URL}/api/v1/dashboard-overview`,
          { partnersCount, upskillCount, communityMembers }
      )
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)