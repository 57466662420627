import React, { useState, useEffect } from 'react'
import {
  AddTeamForm,
  AddTeamStyle,
  AddTeamTop,
} from '../../../styles/adminStyles/AdminAddTeam.Styled'
import ArrowIcon from '../../../assets/icons/arrow-icon.svg'
import { useDispatch, useSelector } from 'react-redux'
import { createTeam } from '../../../api/team'
import Loading from '../../../assets/icons/loading.gif'
import { ToastContainer, toast } from 'react-toastify'
import AttachIcon from '../../../assets/icons/attach-icon.svg'
import { useNavigate } from 'react-router-dom'
import DropFileIcon from '../../../assets/icons/dropFile-icon.svg'
import { Link } from 'react-router-dom'
import Dropzone from 'react-dropzone'
import {
  CustomButton,
  InputField,
  TextareaField,
} from '../../../styles/styledComponent'

const AddTeam = () => {
  const { error, loading } = useSelector((state) => state.team)
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const [selectedFile, setSelectedFile] = useState(null)
  const [dropFile, setDropFile] = useState(null)
  const [fullName, setFullName] = useState('')
  const [jobRole, setJobRole] = useState('')
  const [funFacts, setFunFacts] = useState('')
  const [socialUrl, setSocialUrl] = useState('')

  const handleDataState = (file) => {
    setSelectedFile(file)
  }
  const handleFileUpload = (event) => {
    handleDataState(event.target.files[0])
  }

  const handleFileDrop = (file) => {
    handleDataState(file)
    setDropFile(file)
  }

  useEffect(() => {
    if (error !== null && error !== 'null') {
      toast.error(error, {
        position: toast.POSITION.TOP_CENTER,
      })
    }
  }, [error])
  useEffect(() => {
    if (error === 'null') {
      navigate('/admin/team')
    }
  }, [error, navigate])

  const handleSubmit = async (e) => {
    e.preventDefault()
    const photo = selectedFile || dropFile
    let form_data = new FormData()
    form_data.append('photo', photo, photo?.path)
    form_data.append('fullName', fullName)
    form_data.append('jobRole', jobRole)
    form_data.append('funFacts', funFacts)
    form_data.append('socialUrl', socialUrl)
    dispatch(createTeam(form_data))
    setFullName('')
    setJobRole('')
    setFunFacts('')
    setSocialUrl('')
    setSelectedFile(null)
    setDropFile(null)
  }

  const handleName = (e) => {
    setFullName(e.target.value)
  }
  const handleRole = (e) => {
    setJobRole(e.target.value)
  }
  const handleFact = (e) => {
    setFunFacts(e.target.value)
  }
  const handleSocial = (e) => {
    setSocialUrl(e.target.value)
  }
  return (
    <AddTeamStyle>
      <Link to="/admin/team">
        <AddTeamTop>
          <img src={ArrowIcon} alt="Arrow Icon" /> <h5>AddTeam</h5>
        </AddTeamTop>
      </Link>

      <AddTeamForm>
        <form onSubmit={handleSubmit}>
          <div className="inputs">
            <div>
              <label>Name </label>
              <InputField
                value={fullName}
                onChange={handleName}
                type="text"
                placeholder="Oyinkansola Aebayo"
              />
            </div>
            <div>
              <label>Role</label>
              <InputField
                value={jobRole}
                onChange={handleRole}
                type="text"
                placeholder="CEO & Founder"
              />
            </div>
            <div>
              <label>Funfact</label>
              <TextareaField
                value={funFacts}
                onChange={handleFact}
                placeholder="I love food"
              ></TextareaField>
            </div>
            <div>
              <label>Upload Profile image</label>
              <Dropzone onDrop={(files) => handleFileDrop(files[0])}>
                {({ getRootProps, getInputProps }) => (
                  <div className="btn" {...getRootProps()}>
                    <input {...getInputProps()} onChange={handleFileUpload} />
                    <div>
                      {selectedFile !== null ? (
                        <p>Image Uploaded</p>
                      ) : (
                        <>
                          <img src={AttachIcon} alt="attach icon" />
                          <i>Attach file</i>
                        </>
                      )}
                    </div>
                    <p>or</p>
                    <div>
                      {dropFile !== null ? (
                        <p>Image Uploaded</p>
                      ) : (
                        <>
                          <img src={DropFileIcon} alt="Drop File Icon" />
                          <i>Drop file here</i>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </Dropzone>
            </div>
            <div>
              <label>Linkedln Social </label>
              <InputField
                value={socialUrl}
                onChange={handleSocial}
                type="url"
                required
                placeholder="linkedln.com/in/oyinkansola adebayo"
              />
            </div>
          </div>
          <CustomButton
            padding="12px 16px"
            className={`${
              fullName === '' ||
              socialUrl === '' ||
              funFacts === '' ||
              jobRole === '' ||
              selectedFile === null
                ? 'disabled'
                : ''
            }`}
          >
            {loading ? (
              <img src={Loading} alt="loader" width="30%" height="30%" />
            ) : (
              'Save Changes'
            )}
          </CustomButton>
        </form>
      </AddTeamForm>
      <ToastContainer />
    </AddTeamStyle>
  )
}

export default AddTeam
