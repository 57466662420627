import React, { useState, useEffect } from "react";
import {
  AddTeamForm,
  AddTeamStyle,
  AddTeamTop,
} from "../../../styles/adminStyles/AdminAddTeam.Styled";
import ArrowIcon from "../../../assets/icons/arrow-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { getCareer, updateCareer } from "../../../api/career";
import { useParams } from "react-router-dom";
import {
  jobLevelContent,
  jobTypeContent,
  jobLocationContent,
} from "../../../constants";
import Loading from "../../../assets/icons/loading.gif";
import { ToastContainer, toast } from "react-toastify";
import AttachIcon from "../../../assets/icons/attach-icon.svg";
import { useNavigate } from "react-router-dom";
import DropFileIcon from "../../../assets/icons/dropFile-icon.svg";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import {
  CustomButton,
  InputField,
  SelectField,
  TextareaField,
} from "../../../styles/styledComponent";
import JobLoader from "../loaders/jobLoader";

const UpdateCareer = () => {
  const { error, loading, data } = useSelector((state) => state.career);
  const navigate = useNavigate();

  let { id } = useParams();

  const dispatch = useDispatch();

  const [selectedFile, setSelectedFile] = useState(null);
  const [dropFile, setDropFile] = useState(null);

  const [jobDescription] = useState(data?.jobDescription);

  const [jobRole, setJobRole] = useState(data?.jobRole);
  const [jobRoleChange, setJobRoleChange] = useState(false);

  const [companyName, setCompanyName] = useState(data?.companyName);
  const [companyNameChange, setCompanyNameChange] = useState(false);

  const [jobType, setJobType] = useState(data?.jobType);
  const [jobTypeChange, setJobTypeChange] = useState(false);

  const [jobLink, setJobLink] = useState("");
  const [jobLinkChange, setJobLinkChange] = useState(false);

  const [jobLocation, setJobLocation] = useState("");
  const [jobLocationChange, setJobLocationChange] = useState(false);

  const [jobLevel, setJobLevel] = useState("");
  const [jobLevelChange, setJobLevelChange] = useState(false);

  useEffect(() => {
    async function fetchData() {
      await dispatch(getCareer(id));
    }
    fetchData();
  }, [dispatch, id]);

  const handleDataState = (file) => {
    setSelectedFile(file);
  };
  const handleFileUpload = (event) => {
    handleDataState(event.target.files[0]);
  };

  const handleFileDrop = (file) => {
    handleDataState(file);
    setDropFile(file);
  };

  useEffect(() => {
    if (
      error !== null &&
      error !== "updateCareer" &&
      error !== "getCareer" &&
      error !== "deleteCareer" &&
      error !== "BadNetwork"
    ) {
      toast.error(error, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }, [error]);
  useEffect(() => {
    if (error === "updateCareer") {
      navigate("/admin/career");
    }
  }, [error, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const photo = selectedFile || dropFile;
    let form_data = new FormData();

    if (photo) {
      form_data.append("coverPhoto", photo, photo?.path);
      if (jobDescription && data?.jobDescription !== jobDescription) {
        form_data.append("jobDescription", jobDescription);
        dispatch(updateCareer({ id, form_data }));
      }
      if (jobRole && data?.jobRole !== jobRole) {
        form_data.append("jobRole", jobRole);
        dispatch(updateCareer({ id, form_data }));
      }
      if (companyName && data?.companyName !== companyName) {
        form_data.append("companyName", companyName);
        dispatch(updateCareer({ id, form_data }));
      }
      if (jobType && data?.jobType !== jobType) {
        form_data.append("jobType", jobType);
        dispatch(updateCareer({ id, form_data }));
      }
      if (jobLevel && data?.jobLevel !== jobLevel) {
        form_data.append("jobLevel", jobLevel);
        dispatch(updateCareer({ id, form_data }));
      }
      if (jobLocation && data?.jobLocation !== jobLocation) {
        form_data.append("jobLocation", jobLocation);
        dispatch(updateCareer({ id, form_data }));
      }
      if (jobLevel && data?.jobLevel !== jobLevel) {
        form_data.append("jobLevel", jobLevel);
        dispatch(updateCareer({ id, form_data }));
      }
      if (photo) {
        dispatch(updateCareer({ id, form_data }));
      }
    } else {
      if (jobDescription && data?.jobDescription !== jobDescription) {
        form_data.append("jobDescription", jobDescription);
        dispatch(updateCareer({ id, jobDescription }));
      }
      if (jobRole && data?.jobRole !== jobRole) {
        form_data.append("jobRole", jobRole);
        dispatch(updateCareer({ id, jobRole }));
      }
      if (companyName && data?.companyName !== companyName) {
        form_data.append("companyName", companyName);
        dispatch(updateCareer({ id, companyName }));
      }
      if (jobType && data?.jobType !== jobType) {
        form_data.append("jobType", jobType);
        dispatch(updateCareer({ id, jobType }));
      }
      if (jobLink && data?.jobLink !== jobLink) {
        form_data.append("jobLink", jobLink);
        dispatch(updateCareer({ id, jobLink }));
      }
      if (jobLocation && data?.jobLocation !== jobLocation) {
        form_data.append("jobLocation", jobLocation);
        dispatch(updateCareer({ id, jobLocation }));
      }
      if (jobLevel && data?.jobLevel !== jobLevel) {
        form_data.append("jobLevel", jobLevel);
        dispatch(updateCareer({ id, jobType }));
      }
    }
  };

  const handleRole = (e) => {
    setJobRoleChange(true);
    setJobRole(e.target.value);
  };
  const handleCompanyName = (e) => {
    setCompanyNameChange(true);
    setCompanyName(e.target.value);
  };
  const handleJobType = (e) => {
    setJobTypeChange(true);
    setJobType(e.target.value);
  };
  const handleJobLevel = (e) => {
    setJobLevelChange(true);
    setJobLevel(e.target.value);
  };
  const handleJobLocation = (e) => {
    setJobLocationChange(true);
    setJobLocation(e.target.value);
  };
  const handleJobLink = (e) => {
    setJobLinkChange(true);
    setJobLink(e.target.value);
  };
  if (loading) return <JobLoader />;
  return (
    <AddTeamStyle>
      <Link to="/admin/career">
        <AddTeamTop>
          <img src={ArrowIcon} alt="Arrow Icon" /> <h5>UpdateCareer</h5>
        </AddTeamTop>
      </Link>

      <AddTeamForm>
        <form onSubmit={handleSubmit}>
          <div className="inputs">
            <div>
              <label>Job Role</label>
              <InputField
                value={jobRoleChange ? jobRole : data?.jobRole}
                onChange={handleRole}
                type="text"
                placeholder="CEO & Founder"
              />
            </div>
            <div>
              <label>Company Name</label>
              <TextareaField
                value={companyNameChange ? companyName : data?.companyName}
                onChange={handleCompanyName}
                placeholder="I love food"
              ></TextareaField>
            </div>
            <div>
              <label>Update Company Logo</label>
              <Dropzone onDrop={(files) => handleFileDrop(files[0])}>
                {({ getRootProps, getInputProps }) => (
                  <div className="btn" {...getRootProps()}>
                    <input {...getInputProps()} onChange={handleFileUpload} />
                    <div>
                      {selectedFile !== null ? (
                        <p>Image Uploaded</p>
                      ) : (
                        <>
                          <img src={AttachIcon} alt="attach icon" />
                          <i>Attach file</i>
                        </>
                      )}
                    </div>
                    <p>or</p>
                    <div>
                      {dropFile !== null ? (
                        <p>Image Uploaded</p>
                      ) : (
                        <>
                          <img src={DropFileIcon} alt="Drop File Icon" />
                          <i>Drop file here</i>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </Dropzone>
            </div>
            <div>
              <SelectField
                name=""
                value={jobTypeChange ? jobType : data?.jobType}
                onChange={handleJobType}
              >
                <option value="" disabled>
                  Select Job Type
                </option>
                {jobTypeContent?.map((item) => {
                  const val = item.replace(/-/g, "").toLowerCase();
                  return <option value={val}>{item}</option>;
                })}
              </SelectField>
            </div>
            <div>
              <SelectField
                name=""
                value={jobLevelChange ? jobLevel : data?.jobLevel}
                onChange={handleJobLevel}
              >
                <option value="" disabled>
                  Select Job Level
                </option>
                {jobLevelContent?.map((item) => {
                  const val = item.replace(/-/g, "").toLowerCase();
                  return <option value={val}>{item}</option>;
                })}
              </SelectField>
            </div>
            <div>
              <SelectField
                name=""
                value={jobLocationChange ? jobLocation : data?.jobLocation}
                onChange={handleJobLocation}
              >
                <option value="" disabled>
                  Select Job Location
                </option>
                {jobLocationContent?.map((item) => {
                  const val = item.replace(/-/g, "").toLowerCase();
                  return <option value={val}>{item}</option>;
                })}
              </SelectField>
            </div>
            <div>
              <label>Job Link</label>
              <InputField
                value={jobLinkChange ? jobLink : data?.jobLink}
                onChange={handleJobLink}
                type="url"
                required
                placeholder="linkedln.com/in/oyinkansola adebayo"
              />
            </div>
          </div>
          <CustomButton padding="12px 16px">
            {loading ? (
              <img src={Loading} alt="loader" width="30%" height="30%" />
            ) : (
              "Save Changes"
            )}
          </CustomButton>
        </form>
      </AddTeamForm>
      <ToastContainer />
    </AddTeamStyle>
  );
};

export default UpdateCareer;
